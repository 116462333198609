import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { FormField } from '../ui/FormField';
import { Badge } from '../ui/badge';
import { toTitleCase } from '../../lib/utils';
import type { FieldStatus } from '../../types/lease';

interface KeyInformationProps {
  type?: 'original' | 'amendment';
  commencementDate?: Date | null;
  originalCommencementDate?: Date | null;
  expirationDate?: Date | null;
  rentStartDate?: Date | null;
  rentableArea?: number | null;
  proRata?: number | null;
  buildingArea?: number | null;
  confidence?: {
    commencementDate?: number;
    originalCommencementDate?: number;
    expirationDate?: number;
    rentStartDate?: number;
    rentableArea?: number;
    proRata?: number;
    buildingArea?: number;
  };
  fieldStatus?: Record<string, FieldStatus>;
  onChange: (field: string, value: Date | number | null) => void;
  onFieldStatusChange: (field: string, status: FieldStatus) => void;
}

export function KeyInformation({
  type = 'original',
  commencementDate,
  originalCommencementDate,
  expirationDate,
  rentStartDate,
  rentableArea,
  proRata,
  buildingArea,
  confidence = {},
  fieldStatus = {},
  onChange,
  onFieldStatusChange,
}: KeyInformationProps) {
  // Helper function to safely display date values
  const getDisplayValue = (value: Date | null | undefined): string => {
    if (!value) return '';
    
    try {
      if (value instanceof Date && !isNaN(value.getTime())) {
        return value.toISOString().split('T')[0];
      }
      return '';
    } catch (error) {
      console.error('Date formatting error:', error);
      return '';
    }
  };

  const getConfidenceBadge = (field: string, score: number | undefined) => {
    // Only show confidence for original lease, and only for certain fields in amendments
    if (score === undefined) return null;
    
    // Never show confidence for originalCommencementDate
    if (field === 'originalCommencementDate') return null;
    
    // In amendments, only show confidence for commencement date and expiration date
    if (type === 'amendment' && 
        field !== 'commencementDate' && 
        field !== 'expirationDate') {
      return null;
    }
    
    let variant: 'default' | 'secondary' | 'destructive' = 'default';
    if (score < 0.5) variant = 'destructive';
    else if (score < 0.7) variant = 'secondary';

    return (
      <Badge variant={variant} className="ml-2">
        {Math.round(score * 100)}% confidence
      </Badge>
    );
  };

  const dateFields = [
    { 
      field: 'commencementDate',
      label: 'Commencement Date',
      value: commencementDate,
      confidenceScore: confidence.commencementDate
    },
    {
      field: 'originalCommencementDate',
      label: 'Original Commencement Date',
      value: originalCommencementDate,
      confidenceScore: confidence.originalCommencementDate
    },
    {
      field: 'expirationDate',
      label: 'Expiration Date',
      value: expirationDate,
      confidenceScore: confidence.expirationDate
    },
    {
      field: 'rentStartDate',
      label: 'Rent Start Date',
      value: rentStartDate,
      confidenceScore: confidence.rentStartDate
    }
  ];

  const numericFields = [
    { field: 'rentableArea', label: 'Rentable Area', suffix: 'sq ft', value: rentableArea },
    { field: 'proRata', label: 'Pro Rata Share', suffix: '%', value: proRata },
    { field: 'buildingArea', label: 'Building Area', suffix: 'sq ft', value: buildingArea }
  ];

  return (
    <Card>
      <CardHeader>
        <CardTitle>Key Information</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {/* Date Fields */}
        {dateFields.map(({ field, label, value, confidenceScore }) => (
          <FormField
            key={field}
            id={field}
            label={toTitleCase(label)}
            value={getDisplayValue(value)}
            onChange={(newValue) => {
              const date = newValue ? new Date(newValue) : null;
              onChange(field, date);
            }}
            type="date"
            fieldStatus={fieldStatus[field] || 'not_missing'}
            onFieldStatusChange={(status) => onFieldStatusChange(field, status)}
            suffix={getConfidenceBadge(field, confidenceScore)}
          />
        ))}

        {/* Numeric Fields */}
        {numericFields.map(({ field, label, suffix, value }) => (
          <FormField
            key={field}
            id={field}
            label={toTitleCase(label)}
            value={value?.toString() || ''}
            onChange={(newValue) => onChange(field, newValue ? Number(newValue) : null)}
            type="number"
            placeholder={`Enter ${label.toLowerCase()} (${suffix})`}
            suffix={suffix}
            fieldStatus={fieldStatus[field] || 'not_missing'}
            onFieldStatusChange={(status) => onFieldStatusChange(field, status)}
          />
        ))}
      </CardContent>
    </Card>
  );
}
