import { useState, useEffect } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { FileDown, FileText, Table, FileJson } from 'lucide-react';
import { toast } from 'sonner';
import { generatePDF } from '@/lib/pdf';
import { generateTrainingJSONL, downloadJSONL } from '@/lib/training/export';
import { generateExcel } from '@/lib/excel';
import { supabase } from '@/lib/supabase';
import type { LeaseAbstraction } from '@/types/lease';

interface ExportOptionsProps {
  abstraction: LeaseAbstraction;
}

export function ExportOptions({ abstraction }: ExportOptionsProps) {
  const [exporting, setExporting] = useState(false);

  const exportToPDF = async () => {
    try {
      setExporting(true);
      const fileName = abstraction.premises
        ? `lease-abstract-${abstraction.premises.replace(/[^a-z0-9]/gi, '-').toLowerCase()}.pdf`
        : `lease-abstract-${abstraction.id}.pdf`;
      generatePDF(abstraction, fileName);
      toast.success('PDF exported successfully');
    } catch (error) {
      console.error('PDF export error:', error);
      toast.error('Failed to export PDF');
    } finally {
      setExporting(false);
    }
  };

  const [amendments, setAmendments] = useState<LeaseAbstraction[]>([]);

  useEffect(() => {
    if (abstraction.type === 'original') {
      fetchAmendments();
    }
  }, [abstraction.id]);

  async function fetchAmendments() {
    try {
      const { data, error } = await supabase
        .from('lease_abstractions')
        .select('*')
        .eq('parent_id', abstraction.id)
        .eq('type', 'amendment')
        .order('version', { ascending: true });

      if (error) throw error;

      setAmendments(data.map(doc => ({
        ...doc,
        createdAt: new Date(doc.created_at),
        updatedAt: new Date(doc.updated_at),
        commencementDate: doc.commencementDate ? new Date(doc.commencementDate) : null,
        originalCommencementDate: doc.originalCommencementDate ? new Date(doc.originalCommencementDate) : null,
        expirationDate: doc.expirationDate ? new Date(doc.expirationDate) : null,
        rentStartDate: doc.rentStartDate ? new Date(doc.rentStartDate) : null,
      })));
    } catch (error) {
      console.error('Error fetching amendments:', error);
      toast.error('Failed to fetch amendments');
    }
  }

  const exportToExcel = async () => {
    try {
      setExporting(true);
      
      // Generate Excel file with original and amendments
      const blob = generateExcel(abstraction, amendments);
      
      // Create and download the file
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `lease-abstract-${abstraction.premises || abstraction.id}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast.success('Excel file exported successfully');
    } catch (error) {
      console.error('Excel export error:', error);
      toast.error('Failed to export Excel file');
    } finally {
      setExporting(false);
    }
  };

  const exportToJSONL = () => {
    try {
      setExporting(true);
      const jsonl = generateTrainingJSONL([abstraction]);
      downloadJSONL(jsonl, `abstraction-${abstraction.id}-training.jsonl`);
      toast.success('JSONL file exported successfully');
    } catch (error) {
      console.error('JSONL export error:', error);
      toast.error('Failed to export JSONL file');
    } finally {
      setExporting(false);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" disabled={exporting}>
          <FileDown className="mr-2 h-4 w-4" />
          {exporting ? 'Exporting...' : 'Export'}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={exportToPDF}>
          <FileText className="mr-2 h-4 w-4" />
          Export as PDF
        </DropdownMenuItem>
        <DropdownMenuItem onClick={exportToExcel}>
          <Table className="mr-2 h-4 w-4" />
          Export as Excel
        </DropdownMenuItem>
        <DropdownMenuItem onClick={exportToJSONL}>
          <FileJson className="mr-2 h-4 w-4" />
          Export as JSONL
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}