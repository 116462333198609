import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileUploadZone } from './FileUploadZone';
import { UploadTerminal } from './UploadTerminal';
import { Switch } from '../ui/switch';
import { Label } from '../ui/label';
import { Alert, AlertDescription } from '../ui/alert';
import { AlertCircle, Loader2 } from 'lucide-react';
import { useFileProcessing } from './hooks/useFileProcessing';
import { DocumentTypeConfirmation } from './DocumentTypeConfirmation';
import { supabase } from '../../lib/supabase';
import { ProcessLogger } from '../../lib/process-logger';
import { DocumentToConfirm, DocumentType, FileWithType } from '../../types/document';
import { LogEntry } from './types';
import { CreatedAbstractionsList } from './CreatedAbstractionsList';
import { MultiDocumentService } from '../../lib/services/multi-document-service';
import { extractTextFromDocument } from '../../lib/llm/processors/text-extraction';

export function DocumentUpload() {
  const navigate = useNavigate();
  const [showLogs, setShowLogs] = useState(true);
  const [checkFloorPlans, setCheckFloorPlans] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [documentsToConfirm, setDocumentsToConfirm] = useState<DocumentToConfirm[]>([]);
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);
  const [analysisError, setAnalysisError] = useState<string | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [createdAbstractions, setCreatedAbstractions] = useState<string[]>([]);
  const [showAbstractionList, setShowAbstractionList] = useState(false);
  const [analysisLogs, setAnalysisLogs] = useState<LogEntry[]>([]);
  
  const {
    isProcessing,
    logs: processingLogs,
    error: processingError,
    processFiles
  } = useFileProcessing();

  const logs = [...analysisLogs, ...processingLogs.map(log => ({
    ...log,
    timestamp: new Date(),
    type: log.type || 'info'
  }))];

  const addAnalysisLog = useCallback((message: string, type: 'info' | 'error' | 'success' = 'info') => {
    setAnalysisLogs(prev => [...prev, {
      message,
      type,
      timestamp: new Date()
    }]);
  }, []);

  const handleFileSelect = async (files: File[]) => {
    let processLogger: ProcessLogger | undefined;
    
    try {
      setAnalysisError(null);
      setIsAnalyzing(true);
      setAnalysisLogs([]); // Clear previous analysis logs
      
      // Get authenticated user
      const { data: { user }, error: authError } = await supabase.auth.getUser();
      if (authError) {
        throw new Error(`Authentication error: ${authError.message}`);
      }
      if (!user) {
        throw new Error('No authenticated user found');
      }

      // Create process logger first to ensure we have a valid user context
      processLogger = new ProcessLogger(user.id);
      addAnalysisLog('Starting document analysis...', 'info');
      await processLogger.log('Starting document analysis...', 'info');
      
      // Log file details
      for (const file of files) {
        const fileSize = (file.size / 1024 / 1024).toFixed(2);
        const fileMsg = `Analyzing file: ${file.name} (${fileSize} MB)`;
        addAnalysisLog(fileMsg, 'info');
        await processLogger.log(fileMsg, 'info');
      }

      // Only check for floor plans if the toggle is enabled
      if (checkFloorPlans) {
        addAnalysisLog('Floor plan checking is enabled, validating files...', 'info');
        for (const file of files) {
          const lowerFileName = file.name.toLowerCase();
          if (lowerFileName.includes('floor plan') || 
              lowerFileName.includes('floorplan') || 
              lowerFileName.includes('floor-plan')) {
            throw new Error('Floor plans cannot be processed as lease documents');
          }
        }
      } else {
        addAnalysisLog('Floor plan checking is disabled, skipping validation', 'info');
      }

      // Extract text from all documents
      addAnalysisLog('Extracting text from documents...', 'info');
      const documentsWithText = await Promise.all(
        files.map(async file => {
          const text = await extractTextFromDocument(file);
          addAnalysisLog(`Extracted text from ${file.name}`, 'success');
          return { 
            id: crypto.randomUUID(), 
            content: text,
            name: file.name,
            file,
            skipFloorPlanCheck: !checkFloorPlans // Pass the toggle state
          };
        })
      );

      // Create multi-document service with user ID
      const multiDocService = new MultiDocumentService(
        import.meta.env.VITE_OPENAI_API_KEY || '',
        user.id
      );

      // Use multi-document service to analyze all documents together
      addAnalysisLog('SAGE is analyzing documents with LSC-o1 model...', 'info');
      const result = await multiDocService.processDocuments(documentsWithText.map(doc => ({
        ...doc,
        skipFloorPlanCheck: !checkFloorPlans // Ensure the flag is passed to the service
      })));

      // Convert group analysis to document suggestions
      const documentSuggestions: DocumentToConfirm[] = files.map((file, index) => {
        // Find corresponding document by ID and fallback to name matching
        const docId = documentsWithText[index].id;
        
        // First try to find the document's group
        const group = result.groups.find(g => {
          if (!g || !g.primaryDocument) return false;
          const docs = [g.primaryDocument, ...g.relatedDocuments];
          return docs.some(d => {
            if (!d) return false;
            // First try to match by ID
            if (d.id === docId) return true;
            // Then try to match by normalized filename
            const normalizedFileName = file.name.toLowerCase().replace(/[^a-z0-9]/g, '');
            const normalizedTitle = (d.title || '').toLowerCase().replace(/[^a-z0-9]/g, '');
            return normalizedTitle.includes(normalizedFileName) || 
                   normalizedFileName.includes(normalizedTitle);
          });
        });

        // Then find the specific document within the group
        const doc = group ? [
          group.primaryDocument,
          ...group.relatedDocuments
        ].find(d => {
          if (!d) return false;
          if (d.id === docId) return true;
          const normalizedFileName = file.name.toLowerCase().replace(/[^a-z0-9]/g, '');
          const normalizedTitle = (d.title || '').toLowerCase().replace(/[^a-z0-9]/g, '');
          return normalizedTitle.includes(normalizedFileName) || 
                 normalizedFileName.includes(normalizedTitle);
        }) : null;

        if (!doc || !group) {
          // If no match found, use the classification from the document service
          const docWithText = documentsWithText[index];
          const warningMsg = `Document grouping incomplete for ${file.name}. Using classification results.`;
          addAnalysisLog(warningMsg, 'info');
          console.warn(warningMsg);
          
          // Get the classification from the document service's results
          const classification = result.groups.flatMap(g => [
            g.primaryDocument,
            ...g.relatedDocuments
          ]).find(d => d?.id === docId);

          return {
            name: file.name,
            suggestedType: classification?.type || 'amendment',
            confidence: group?.confidence || 0.5,
            keyTerms: [],
            parties: {
              landlord: '',
              tenant: ''
            },
            premises: ''
          };
        }

        const suggestion = {
          name: file.name,
          suggestedType: doc.type,
          confidence: group.confidence,
          keyTerms: doc.keyTerms || [],
          effectiveDate: doc.effectiveDate,
          expirationDate: doc.expirationDate,
          modificationSummary: doc.modificationSummary,
          sequence: doc.sequence,
          parties: {
            landlord: doc.parties?.landlord || '',
            tenant: doc.parties?.tenant || ''
          },
          premises: doc.premises || ''
        };

        addAnalysisLog(
          `Classified ${file.name} as ${suggestion.suggestedType} with ${Math.round(suggestion.confidence * 100)}% confidence`,
          suggestion.confidence > 0.7 ? 'success' : 'info'
        );

        return suggestion;
      });

      // Only proceed if we have valid suggestions
      if (documentSuggestions.every(doc => doc.confidence > 0)) {
        setPendingFiles(files);
        setDocumentsToConfirm(documentSuggestions);
        setShowConfirmation(true);

        const analysisTime = result.processingTime / 1000;
        const successMsg = `Document analysis completed in ${analysisTime.toFixed(1)} seconds`;
        addAnalysisLog(successMsg, 'success');
        await processLogger.log(successMsg, 'success');
      } else {
        throw new Error('Unable to analyze documents with sufficient confidence. Please try again.');
      }
    } catch (error) {
      console.error('Initial analysis error:', error);
      
      // Provide more specific error messages
      let errorMessage = 'SAGE failed to analyze documents';
      if (error instanceof Error) {
        if (error.message.includes('429') || error.message.includes('Too Many Requests')) {
          errorMessage = 'Rate limit exceeded. Please wait a moment and try again.';
        } else if (error.message.includes('Invalid completion response')) {
          errorMessage = 'Document analysis service temporarily unavailable. Please try again.';
        } else {
          errorMessage = error.message;
        }
      }
      
      setAnalysisError(errorMessage);
      setPendingFiles([]);
      setDocumentsToConfirm([]);
      addAnalysisLog(errorMessage, 'error');
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleConfirmDocumentTypes = async (confirmedTypes: Array<{ name: string; type: DocumentType }>) => {
    try {
      // Close confirmation dialog
      setShowConfirmation(false);

      // Group files by abstraction
      const abstractionGroups = new Map<string, {
        files: FileWithType[];
        tenant: string;
        premises: string;
      }>();

      // Map files to their types and group by abstraction
      pendingFiles.forEach(file => {
        const confirmedType = confirmedTypes.find(t => t.name === file.name)!;
        const doc = documentsToConfirm.find(d => d.name === file.name)!;
        
        // Normalize tenant and premises for consistent grouping
        // Remove all special characters and extra spaces for more robust matching
        const tenant = (doc.parties.tenant || '')
          .trim()
          .toLowerCase()
          .replace(/[^a-z0-9]/g, '')
          .replace(/\s+/g, '');
        const premises = (doc.premises || '')
          .trim()
          .toLowerCase()
          .replace(/[^a-z0-9]/g, '')
          .replace(/\s+/g, '');
        
        const key = `${tenant}-${premises}`;
        console.log(`Document ${file.name} mapped to group key: ${key}`);

        if (!abstractionGroups.has(key)) {
          abstractionGroups.set(key, {
            files: [],
            tenant: doc.parties.tenant || 'Unknown Tenant',
            premises: doc.premises || 'Unknown Location'
          });
        }

        abstractionGroups.get(key)!.files.push({
          file,
          type: confirmedType.type
        });
      });

      // Process each abstraction group
      const abstractionIds: string[] = [];
      const processedGroups = new Set<string>();

      // Log the groups for debugging
      console.log('Abstraction groups:', Array.from(abstractionGroups.entries()));
      
      for (const [key, group] of abstractionGroups) {
        // Skip if we've already processed this tenant-premises combination
        if (processedGroups.has(key)) {
          console.log(`Skipping already processed group: ${key}`);
          continue;
        }
        
        console.log(`Processing group: ${key} with ${group.files.length} files`);
        processedGroups.add(key);

        // Check if we have more than one original lease in this group
        const originalLeaseCount = group.files.filter(f => f.type === 'original_lease').length;
        if (originalLeaseCount > 1) {
          throw new Error(`Multiple original leases found for tenant ${group.tenant} at ${group.premises}`);
        }

        // Process all files for this abstraction together
        const result = await processFiles(
          group.files,
          group.tenant,
          group.premises
        );

        if (result.abstractionId) {
          abstractionIds.push(result.abstractionId);
          addAnalysisLog(`Created abstraction for ${group.tenant} at ${group.premises}`, 'success');
        } else if (result.error) {
          throw new Error(result.error);
        }
      }

      // Handle multiple abstractions or new single abstraction
      const uniqueGroups = new Set(Array.from(abstractionGroups.keys()));
      
      // Log the final state for debugging
      console.log('Final state:', {
        abstractionIds,
        uniqueGroupCount: uniqueGroups.size,
        processedGroupCount: processedGroups.size
      });

      if (uniqueGroups.size > 1) {
        // Only show list if we truly have multiple different tenant-premises combinations
        setCreatedAbstractions(abstractionIds);
        setShowAbstractionList(true);
        addAnalysisLog('Multiple abstractions created for different tenants/locations', 'info');
      } else {
        // If all documents belong to the same tenant/premises, use the first abstraction
        const abstractionId = abstractionIds[0];
        if (abstractionId) {
          addAnalysisLog('All documents belong to the same tenant/premises. Redirecting to abstraction page...', 'success');
          navigate(`/abstraction/${abstractionId}`);
        } else {
          throw new Error('No abstraction ID was returned from processing');
        }
      }
    } catch (error) {
      console.error('Error processing documents:', error);
      setAnalysisError(error instanceof Error ? error.message : 'Failed to process documents');
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
    setPendingFiles([]);
    setDocumentsToConfirm([]);
    setAnalysisError(null);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <Switch
              id="show-logs"
              checked={showLogs}
              onCheckedChange={setShowLogs}
            />
            <Label htmlFor="show-logs">Show Process Log</Label>
          </div>
          <div className="flex items-center space-x-2">
            <Switch
              id="check-floor-plans"
              checked={checkFloorPlans}
              onCheckedChange={setCheckFloorPlans}
            />
            <Label htmlFor="check-floor-plans">Check For Floor Plans</Label>
          </div>
        </div>
        {(isAnalyzing || isProcessing) && (
          <div className="flex items-center text-sm text-muted-foreground">
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            {isAnalyzing ? 'SAGE is analyzing documents...' : 'SAGE is now processing your documents...'}
          </div>
        )}
      </div>

      {(analysisError || processingError) && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{analysisError || processingError}</AlertDescription>
        </Alert>
      )}

      <FileUploadZone 
        onFileSelect={handleFileSelect}
        accept={{
          'application/pdf': ['.pdf'],
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'image/tiff': ['.tif', '.tiff']
        }}
        disabled={isAnalyzing || isProcessing}
      />

      {showLogs && (
        <div className="mt-4">
          <UploadTerminal logs={logs} />
        </div>
      )}

      <CreatedAbstractionsList
        open={showAbstractionList}
        abstractionIds={createdAbstractions}
        onSelect={(abstractionId) => {
          navigate(`/abstraction/${abstractionId}`);
        }}
        onClose={() => {
          setShowAbstractionList(false);
          setCreatedAbstractions([]);
        }}
      />

      <DocumentTypeConfirmation
        open={showConfirmation}
        documents={documentsToConfirm}
        onConfirm={handleConfirmDocumentTypes}
        onCancel={handleCancelConfirmation}
      />
    </div>
  );
}
