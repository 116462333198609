import { Outlet } from 'react-router-dom';
import { Navigation } from './Navigation';
import { useState } from 'react';
import { cn } from '../lib/utils';
import { SystemAlert } from './ui/SystemAlert';

export default function Layout() {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className="min-h-screen bg-background">
      <Navigation isCollapsed={isCollapsed} onCollapse={setIsCollapsed} />
      <main className={cn(
        "min-h-screen transition-all duration-300",
        isCollapsed ? "pl-16" : "pl-64"
      )}>
        <div className="container mx-auto p-8 space-y-6">
          <SystemAlert />
          <Outlet />
        </div>
      </main>
    </div>
  );
}