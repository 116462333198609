import type { LLMResponse } from '../types';
import { withTimeout } from '../../../lib/utils/timeout';
import { ProcessingError } from './errors';
import { withRetry } from './retry';
import { OpenAIRetry } from '../../utils/openai-retry';
import { 
  LEASE_ANALYSIS_PROMPT, 
  AMENDMENT_ANALYSIS_PROMPT, 
  BACKUP_ANALYSIS_PROMPT 
} from './prompts';

const BASE_TIMEOUT = 180000; // 3 minutes base timeout
const TIMEOUT_PER_CHAR = 0.1; // Additional ms per character
const MAX_TIMEOUT = 600000; // Maximum 10 minutes
const MAX_TOKENS = 16000;
const AVERAGE_CHARS_PER_TOKEN = 4;
const MAX_CHARS = MAX_TOKENS * AVERAGE_CHARS_PER_TOKEN;
const MIN_CHARS = 1000;
const MAX_REASON_LENGTH = 1000;
const MAX_CLAUSE_LENGTH = 4000; // Maximum length for any clause text

function formatError(error: unknown): string {
  if (error instanceof Error) {
    let message = error.message;
    if (error.stack) {
      message += `\nStack trace:\n${error.stack}`;
    }
    return message;
  }
  return String(error);
}

function calculateTimeout(textLength: number): number {
  const calculatedTimeout = BASE_TIMEOUT + (textLength * TIMEOUT_PER_CHAR);
  return Math.min(calculatedTimeout, MAX_TIMEOUT);
}

function truncateText(text: string, attempt: number = 1): string {
  // Start with 40% reduction, increase by 15% each attempt
  const reductionFactor = Math.min(0.4 + (attempt - 1) * 0.15, 0.85);
  const maxLength = Math.floor(MAX_CHARS * (1 - reductionFactor));

  if (text.length <= maxLength) return text;
  
  // Try to find a good breakpoint at a paragraph
  const truncateIndex = text.lastIndexOf('\n\n', maxLength);
  if (truncateIndex > maxLength * 0.8) {
    console.log(`Truncating at paragraph break: ${truncateIndex} chars (attempt ${attempt})`);
    return text.slice(0, truncateIndex);
  }
  
  // Try to find a good breakpoint at a sentence
  const sentenceBreak = text.lastIndexOf('. ', maxLength);
  if (sentenceBreak > maxLength * 0.8) {
    console.log(`Truncating at sentence break: ${sentenceBreak} chars (attempt ${attempt})`);
    return text.slice(0, sentenceBreak + 1);
  }
  
  console.log(`Hard truncating at: ${maxLength} chars (attempt ${attempt})`);
  return text.slice(0, maxLength);
}

function validateResponse(data: any): void {
  if (!data) {
    throw ProcessingError.llm('Empty response from LSX-u');
  }

  if (typeof data !== 'object') {
    throw ProcessingError.llm('Invalid response format from LSX-u');
  }

  // Enforce length limits on text fields
  if ('reason' in data && typeof data.reason === 'string') {
    data.reason = data.reason.substring(0, MAX_REASON_LENGTH);
  }

  // Handle clauses and additionalClauses
  if (data.clauses && typeof data.clauses === 'object') {
    Object.keys(data.clauses).forEach(key => {
      if (typeof data.clauses[key] === 'string') {
        data.clauses[key] = data.clauses[key].substring(0, MAX_CLAUSE_LENGTH);
      }
    });
  }

  if (data.additionalClauses && typeof data.additionalClauses === 'object') {
    Object.keys(data.additionalClauses).forEach(key => {
      if (typeof data.additionalClauses[key] === 'string') {
        data.additionalClauses[key] = data.additionalClauses[key].substring(0, MAX_CLAUSE_LENGTH);
      }
    });
  }
}

function sanitizeJsonString(str: string): string {
  // Remove Markdown code block syntax if present
  let sanitized = str.replace(/^```json\s*/, '').replace(/\s*```$/, '');
  
  // Find the last complete object/array closing
  const lastBrace = sanitized.lastIndexOf('}');
  const lastBracket = sanitized.lastIndexOf(']');
  const lastComplete = Math.max(lastBrace, lastBracket);
  
  if (lastComplete > -1) {
    sanitized = sanitized.substring(0, lastComplete + 1);
  }

  // Remove any potential string terminators or invalid characters
  sanitized = sanitized
    .replace(/[\u0000-\u001F\u007F-\u009F]/g, '')
    .replace(/\\"/g, '"')
    .replace(/\\\\/g, '\\')
    .replace(/\\n/g, ' ') // Replace newlines with spaces
    .replace(/\s+/g, ' '); // Collapse multiple spaces

  // Attempt to fix any unclosed quotes
  let quoteCount = (sanitized.match(/"/g) || []).length;
  if (quoteCount % 2 !== 0) {
    sanitized += '"';
  }

  return sanitized;
}

function parseJsonResponse(response: string): any {
  try {
    // Clean up the response string
    const sanitized = sanitizeJsonString(response);
    
    // Parse the JSON
    const data = JSON.parse(sanitized);
    
    // Validate and enforce length limits
    validateResponse(data);
    
    return data;
  } catch (error) {
    console.error('JSON parsing error:', error);
    console.error('Raw response:', response);
    throw ProcessingError.llm('Failed to parse LSX-u response as JSON', error as Error);
  }
}

function calculateConfidence(rawData: any): Record<string, number> {
  const confidence: Record<string, number> = {};
  
  try {
    Object.entries(rawData).forEach(([key, value]) => {
      if (key === 'missingFields') return;
      
      if (value === null || value === undefined) {
        confidence[key] = 0;
      } else if (typeof value === 'object') {
        const subFields = Object.values(value as Record<string, any>);
        const validFields = subFields.filter(v => v !== null && v !== undefined && v !== '');
        confidence[key] = validFields.length / subFields.length;
      } else {
        confidence[key] = value === '' ? 0 : 1;
      }
    });
    
    return confidence;
  } catch (error) {
    console.error('Confidence calculation error:', formatError(error));
    throw ProcessingError.llm('Failed to calculate confidence scores', error as Error);
  }
}

export async function processWithLlama(
  text: string,
  isAmendment: boolean,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void,
  customPrompt?: string,
  isBackup = false
): Promise<LLMResponse> {
  if (!text?.trim()) {
    throw ProcessingError.llm('No text provided for LSX-u processing');
  }

  let currentAttempt = 1;
  let truncatedText = truncateText(text, currentAttempt);
  const truncatedPercent = Math.round((text.length - truncatedText.length) / text.length * 100);
  
  if (truncatedText.length < text.length) {
    addLog(`Warning: Document text was truncated by ${truncatedPercent}% to fit within LSX-u context window`, 'info');
    console.log(`Original text length: ${text.length}, truncated to: ${truncatedText.length} (attempt ${currentAttempt})`);
  }

  if (truncatedText.length < MIN_CHARS) {
    throw ProcessingError.llm(`Document text truncated too much (${truncatedText.length} chars). Minimum required: ${MIN_CHARS} chars`);
  }

  // Calculate timeout based on text length
  const timeout = calculateTimeout(truncatedText.length);
  addLog(`Setting timeout to ${Math.round(timeout/1000)} seconds based on document length`, 'info');

  // Create OpenAIRetry instance for this process
  const llama = new OpenAIRetry('llama-processor');

  return withRetry(
    async () => {
      try {
        addLog(`Using ${customPrompt ? 'custom' : isAmendment ? 'amendment' : 'lease'} analysis prompt with LSX-u...`);

        const completion = await withTimeout(
          llama.createChatCompletion({
            model: 'openai/gpt-4o-2024-11-20',
            messages: [
              {
                role: "system",
                content: `${customPrompt || (isAmendment ? AMENDMENT_ANALYSIS_PROMPT : 
                                         isBackup ? BACKUP_ANALYSIS_PROMPT : 
                                         LEASE_ANALYSIS_PROMPT)}\n\nIMPORTANT: Keep clause summaries concise, under ${MAX_CLAUSE_LENGTH} characters. Your response must be valid JSON.`
              },
              {
                role: "user",
                content: truncatedText
              }
            ],
            maxTokens: 3000,
            response_format: { type: "json_object" },
            presence_penalty: 0
          }, 'llama-processing'),
          timeout,
          'LSX-u processing timeout'
        );

        if (!completion.choices?.[0]?.message?.content) {
          throw ProcessingError.llm('Empty response from LSX-u');
        }

        const rawData = parseJsonResponse(completion.choices[0].message.content);
        validateResponse(rawData);

        const confidence = customPrompt ? {} : calculateConfidence(rawData);

        addLog('LSX-u processing completed successfully', 'success');
        return {
          data: rawData,
          confidence,
          source: 'llama'
        };
      } catch (error) {
        console.error('LSX-u processing error:', formatError(error));

        currentAttempt++;
        truncatedText = truncateText(text, currentAttempt);
        const newTruncatedPercent = Math.round((text.length - truncatedText.length) / text.length * 100);
        
        if (truncatedText.length < MIN_CHARS) {
          throw ProcessingError.llm(`Document text truncated too much (${truncatedText.length} chars). Minimum required: ${MIN_CHARS} chars`);
        }

        addLog(`Reducing content size by ${newTruncatedPercent}% for next attempt`, 'info');

        if (error instanceof ProcessingError) {
          throw error;
        }

        throw ProcessingError.llm(
          'LSX-u processing failed',
          error as Error
        );
      }
    },
    {
      model: 'Llama',
      addLog,
      maxRetries: 3,
      retryDelay: 2000,
      backoffFactor: 2
    }
  );
}
