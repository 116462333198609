import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertTriangle } from 'lucide-react';

export function SystemAlert() {
  return (
    <Alert variant="destructive">
      <AlertTriangle className="h-4 w-4" />
      <AlertTitle>System Update in Progress</AlertTitle>
      <AlertDescription>
        We are currently updating the date and classification model. You may see errors over the next hour or so. Data will be removed upon upload during that time period.
      </AlertDescription>
    </Alert>
  );
} 