import * as XLSX from 'xlsx';
import type { LeaseAbstraction, Clauses, AdditionalClauses, ContactInfo } from '@/types/lease';

interface ExcelStyles {
  font?: { 
    name?: string;
    bold?: boolean;
    sz?: number;
  };
  alignment?: { 
    horizontal?: 'left' | 'center' | 'right';
    vertical?: 'top' | 'center' | 'bottom';
    wrapText?: boolean;
  };
  fill?: { 
    fgColor: { rgb: string } 
  };
  border?: {
    top?: { style: 'thin' | 'medium' };
    bottom?: { style: 'thin' | 'medium' };
    left?: { style: 'thin' | 'medium' };
    right?: { style: 'thin' | 'medium' };
  };
  numFmt?: string;
}

function formatContactInfo(info: ContactInfo): Record<string, string> {
  return {
    'Name': info.name || '',
    'Address': info.address || '',
    'Phone': info.phone || '',
    'Email': info.email || ''
  };
}

function formatClauses(clauses: Clauses): Record<string, string> {
  return {
    'Alterations': clauses.alterations || '',
    'Assignment & Sublease': clauses.assignmentSublease || '',
    'Audit Rights': clauses.auditRights || '',
    'Base Rent Details': clauses.baseRentDetails || '',
    'CAM & OPEX': clauses.camOpex || '',
    'Default': clauses.default || ''
  };
}

function formatAdditionalClauses(clauses: AdditionalClauses): Record<string, string> {
  return {
    'Estoppel & SNDA': clauses.estoppelSnda || '',
    'Financial Reporting': clauses.financialReporting || '',
    'Force Majeure': clauses.forceMajeure || '',
    'Go Dark': clauses.goDark || '',
    'Holdover': clauses.holdover || '',
    'Insurance': clauses.insurance || '',
    'Late Fee': clauses.lateFee || '',
    'Legal Notices': clauses.legalNotices || '',
    'Parking': clauses.parking || '',
    'Real Estate Taxes': clauses.realEstateTaxes || '',
    'Relocation Option': clauses.relocationOption || '',
    'Renewal Option': clauses.renewalOption || '',
    'Repairs & Maintenance': clauses.repairsMaintenance || '',
    'Right of First Offer': clauses.rightOfFirstOffer || '',
    'Right of First Refusal': clauses.rightOfFirstRefusal || '',
    'Security Deposit Conditions': clauses.securityDepositConditions || '',
    'Signage': clauses.signage || '',
    'Surrender': clauses.surrender || '',
    'Termination Option': clauses.terminationOption || '',
    'TI Allowance': clauses.tiAllowance || '',
    'Use': clauses.use || '',
    'Utilities': clauses.utilities || ''
  };
}

function createWorksheet(data: any[][], styles: ExcelStyles[] = []): XLSX.WorkSheet {
  const ws = XLSX.utils.aoa_to_sheet(data);
  
  // Apply styles
  if (!ws['!cols']) ws['!cols'] = [];
  if (!ws['!rows']) ws['!rows'] = [];
  
  // Set column widths (A bit wider for better readability)
  ws['!cols'] = [
    { wch: 35 }, // First column (labels)
    { wch: 50 }  // Second column (values)
  ];
  
  // Apply styles to all cells
  const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
      if (!ws[cellRef]) ws[cellRef] = {};
      
      // Default style for all cells
      ws[cellRef].s = {
        font: { name: 'Arial', sz: 11 },
        alignment: { 
          vertical: 'center',
          wrapText: true
        },
        border: {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' }
        }
      };

      // Special styling for headers and section titles
      if (R === 0 || (data[R] && data[R][0] && !data[R][1])) {
        ws[cellRef].s = {
          font: { name: 'Arial', bold: true, sz: 12 },
          alignment: { horizontal: 'left', vertical: 'center' },
          fill: { fgColor: { rgb: 'E0E0E0' } },
          border: {
            top: { style: 'medium' },
            bottom: { style: 'medium' },
            left: { style: 'medium' },
            right: { style: 'medium' }
          }
        };
      }
    }
  }

  // Set print options
  ws['!print'] = {
    gridLines: true,
    horizontalCentered: true,
    verticalCentered: true,
    paperSize: 9, // A4
    orientation: 'portrait',
    fitToPage: true,
    margins: {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3
    }
  };
  
  return ws;
}

function generateAbstractionSheet(abstraction: LeaseAbstraction): XLSX.WorkSheet {
  // Create sections with spacing for better organization
  const data = [
    // Title Section
    [`LEASE ABSTRACT - ${abstraction.type.toUpperCase()} VERSION ${abstraction.version}`],
    ['Last Updated', abstraction.updatedAt.toLocaleDateString()],
    [],
    
    // Summary Section with box styling
    ['GENERAL INFORMATION'],
    ['Premises', abstraction.premises || ''],
    ['County', abstraction.county || ''],
    ['Tenant Legal Name', abstraction.tenantLegalName || ''],
    ['Status', abstraction.status || ''],
    ['Type', abstraction.type || ''],
    ['Version', abstraction.version.toString()],
    [],
    
    // Key Dates Section
    ['KEY DATES'],
    ['Commencement Date', abstraction.commencementDate?.toLocaleDateString() || ''],
    ['Original Commencement Date', abstraction.originalCommencementDate?.toLocaleDateString() || ''],
    ['Expiration Date', abstraction.expirationDate?.toLocaleDateString() || ''],
    ['Rent Start Date', abstraction.rentStartDate?.toLocaleDateString() || ''],
    [],
    
    // Financial Section
    ['FINANCIAL INFORMATION'],
    ['Rentable Area', abstraction.rentableArea?.toString() || ''],
    ['Pro Rata', abstraction.proRata?.toString() || ''],
    ['Building Area', abstraction.buildingArea?.toString() || ''],
    ['Base Rent', abstraction.baseRent?.toString() || ''],
    ['Annual Rent', abstraction.annualRent?.toString() || ''],
    ['Security Deposit', abstraction.securityDeposit?.toString() || ''],
    [],
    
    // Contact Section
    ['CONTACT INFORMATION'],
    ['Landlord'],
    ['Name', abstraction.landlord.name || ''],
    ['Address', abstraction.landlord.address || ''],
    ['Phone', abstraction.landlord.phone || ''],
    ['Email', abstraction.landlord.email || ''],
    [],
    ['Tenant'],
    ['Name', abstraction.tenant.name || ''],
    ['Address', abstraction.tenant.address || ''],
    ['Phone', abstraction.tenant.phone || ''],
    ['Email', abstraction.tenant.email || ''],
    [],
    
    // Main Clauses Section
    ['MAIN CLAUSES'],
    ...Object.entries(formatClauses(abstraction.clauses)).map(([key, value]) => [key, value]),
    [],
    
    // Additional Clauses Section
    ['ADDITIONAL CLAUSES'],
    ...Object.entries(formatAdditionalClauses(abstraction.additionalClauses)).map(([key, value]) => [key, value])
  ];

  const ws = XLSX.utils.aoa_to_sheet(data);
  
  // Apply styles
  if (!ws['!cols']) ws['!cols'] = [];
  if (!ws['!rows']) ws['!rows'] = [];
  
  // Set column widths
  ws['!cols'] = [
    { wch: 35 }, // First column (labels)
    { wch: 65 }  // Second column (values) - wider for clause content
  ];
  
  // Apply styles to all cells
  const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
      if (!ws[cellRef]) ws[cellRef] = {};
      
      // Default style for all cells
      ws[cellRef].s = {
        font: { name: 'Arial', sz: 11 },
        alignment: { 
          vertical: 'center',
          wrapText: true
        },
        border: {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' }
        }
      };

      // Special styling for main headers (GENERAL INFORMATION, KEY DATES, etc.)
      if (data[R] && data[R][0] && !data[R][1] && data[R][0].toUpperCase() === data[R][0]) {
        ws[cellRef].s = {
          font: { name: 'Arial', bold: true, sz: 12 },
          alignment: { horizontal: 'center', vertical: 'center' },
          fill: { fgColor: { rgb: '4F81BD' } }, // Professional blue
          border: {
            top: { style: 'medium' },
            bottom: { style: 'medium' },
            left: { style: 'medium' },
            right: { style: 'medium' }
          }
        };
      }
      
      // Special styling for the title
      if (R === 0) {
        ws[cellRef].s = {
          font: { name: 'Arial', bold: true, sz: 14 },
          alignment: { horizontal: 'center', vertical: 'center' },
          fill: { fgColor: { rgb: '1F497D' } }, // Darker blue
          border: {
            top: { style: 'medium' },
            bottom: { style: 'medium' },
            left: { style: 'medium' },
            right: { style: 'medium' }
          }
        };
      }
      
      // Subheaders (Landlord, Tenant)
      if (data[R] && data[R][0] && !data[R][1] && data[R][0].match(/^(Landlord|Tenant)$/)) {
        ws[cellRef].s = {
          font: { name: 'Arial', bold: true, sz: 11 },
          alignment: { horizontal: 'left', vertical: 'center' },
          fill: { fgColor: { rgb: 'E6EEF8' } }, // Light blue
          border: {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' }
          }
        };
      }
    }
  }

  // Set print options
  ws['!print'] = {
    gridLines: true,
    horizontalCentered: true,
    verticalCentered: true,
    paperSize: 9, // A4
    orientation: 'portrait',
    fitToPage: true,
    margins: {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3
    }
  };

  return ws;
}

export function generateExcel(abstraction: LeaseAbstraction, amendments: LeaseAbstraction[] = []): Blob {
  const wb = XLSX.utils.book_new();

  // Add original version sheet
  XLSX.utils.book_append_sheet(
    wb,
    generateAbstractionSheet(abstraction),
    'Original Version'
  );

  // Add amendment sheets
  amendments.forEach((amendment) => {
    XLSX.utils.book_append_sheet(
      wb,
      generateAbstractionSheet(amendment),
      `Amendment ${amendment.version}`
    );
  });

  // Set workbook properties for better printing
  wb.Workbook = {
    Views: [{ RTL: false }],
    WBProps: {
      date1904: false,
      filterPrivacy: false
    }
  };

  // Set workbook metadata through write options
  const writeOpts: XLSX.WritingOptions = {
    type: 'array',
    bookType: 'xlsx',
    bookSST: false,
    compression: true,
    Props: {
      Title: `Lease Abstract - ${abstraction.premises || abstraction.id}`,
      Subject: 'Lease Abstraction Report',
      Author: 'Lease Simplify AI',
      CreatedDate: new Date()
    }
  };

  // Write workbook
  const wbout = XLSX.write(wb, writeOpts);
  
  return new Blob([wbout], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
}