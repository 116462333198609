import { extractDates } from '../date-extractor';
import { processWithGPT4 } from './gpt4';
import { processWithLlama } from './llama';
import { ProcessingError } from './errors';
import { parseDate } from './utils/date-utils';
import { mergeResponses } from './utils/merge-utils';
import { processImagesForAreas } from './utils/image-processing';
import { processAmendment } from './utils/amendment-processing';
import { withTimeout } from '../../utils/timeout';
import { 
  transformRawData, 
  getConfidenceScores, 
  createPartialAbstraction 
} from './utils/data-transformation';
import type { 
  ProcessingOptions, 
  ProcessingResult, 
  LLMResponse
} from '../types';

const GLOBAL_TIMEOUT = 300000; // 5 minutes total processing time

/**
 * Processes a document using dual LLM approach (GPT-4 and Llama)
 */
export async function processDocumentWithDualLLM(
  text: string,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void,
  options: ProcessingOptions = {}
): Promise<ProcessingResult> {
  const {
    confidenceThreshold = 0.7,
    isAmendment = false,
    abstractionId,
    file
  } = options;

  return withTimeout(Promise.resolve().then(async () => {
    try {
      if (!text?.trim()) {
        throw ProcessingError.document('No text content provided for processing');
      }

      addLog('Starting dual LLM processing...');

      // Extract dates
      const extractedDates = await extractDates(text, addLog);
      if (!extractedDates) {
        throw ProcessingError.llm('Failed to extract dates from document');
      }
      addLog('Date extraction completed successfully');

      // Process with both models in parallel with individual timeouts
      let [gpt4Response, llamaResponse] = await Promise.all([
        processWithGPT4(text, isAmendment, addLog).catch(error => {
          console.error('SAGE processing error:', error);
          addLog('SAGE processing failed, continuing with LSX only', 'error');
          return null;
        }),
        processWithLlama(text, isAmendment, addLog).catch(error => {
          console.error('Llama processing error:', error);
          addLog('Llama processing failed, continuing with SAGE only', 'error');
          return null;
        })
      ]);

      // Ensure at least one model succeeded
      if (!gpt4Response && !llamaResponse) {
        throw ProcessingError.llm('Both SAGE and LSX processing failed');
      }

      addLog('Starting response merge...', 'info');

      // Create merged response with timeout
      let mergedResponse: LLMResponse;
      try {
        mergedResponse = await withTimeout(
          Promise.resolve().then(() => {
            if (gpt4Response && llamaResponse) {
              return {
                data: mergeResponses(gpt4Response.data, llamaResponse.data),
                confidence: {
                  ...gpt4Response.confidence,
                  ...llamaResponse.confidence
                },
                source: 'gpt4'
              };
            }
            return (gpt4Response || llamaResponse) as LLMResponse;
          }),
          30000, // 30 second timeout for merge operation
          'Response merge operation timed out'
        );
        
        addLog('Response merge completed successfully', 'success');
      } catch (mergeError) {
        console.error('Merge error:', mergeError);
        addLog('Error during response merge, falling back to SAGE response', 'error');
        mergedResponse = gpt4Response || llamaResponse as LLMResponse;
      }

      // Log extracted clauses
      if (mergedResponse.data.clauses) {
        addLog('Extracted clauses:', 'info');
        for (const [clauseType, clause] of Object.entries(mergedResponse.data.clauses)) {
          if (clause) {
            const preview = typeof clause === 'string' 
              ? clause.substring(0, 100) + '...'
              : 'Complex clause structure';
            addLog(`Clause extracted - ${clauseType}: ${preview}`, 'info');
          }
        }
      }

      // Check if we need to process images for area measurements
      const shouldProcessImages = !options.skipFloorPlanCheck && file && (
        !mergedResponse.data.rentableArea ||
        !mergedResponse.data.buildingArea ||
        (mergedResponse.confidence?.rentableArea || 0) < confidenceThreshold ||
        (mergedResponse.confidence?.buildingArea || 0) < confidenceThreshold
      );

      // Process images if needed and floor plan checking is enabled
      if (shouldProcessImages) {
        addLog('Area measurements missing or low confidence, attempting image processing...', 'info');
        try {
          const imageResult = await withTimeout(
            processImagesForAreas(
              file,
              mergedResponse,
              confidenceThreshold,
              addLog
            ),
            60000, // 1 minute timeout for image processing
            'Image processing timed out'
          );
          
          if (imageResult) {
            mergedResponse.data = {
              ...mergedResponse.data,
              ...imageResult.data
            };
            mergedResponse.confidence = {
              ...mergedResponse.confidence,
              ...imageResult.confidence
            };
            addLog('Area measurements updated from floor plans', 'success');
          } else {
            addLog('No area measurements found in floor plans', 'info');
          }
        } catch (error) {
          addLog(`Error processing images: ${error instanceof Error ? error.message : 'Unknown error'}`, 'error');
          // Continue processing even if image processing fails
        }
      }

      addLog('Starting data transformation...', 'info');

      // Transform raw data with timeout
      let processedData;
      try {
        processedData = await withTimeout(
          Promise.resolve().then(() => transformRawData(mergedResponse.data)),
          30000, // 30 second timeout for transformation
          'Data transformation timed out'
        );
        addLog('Data transformation completed', 'success');
      } catch (transformError) {
        console.error('Transform error:', transformError);
        throw ProcessingError.llm('Failed to transform response data', transformError as Error);
      }

      const confidence = {
        ...getConfidenceScores(mergedResponse.data),
        ...extractedDates.confidence
      };

      // Handle amendments vs original lease
      let result: ProcessingResult;

      if (isAmendment && abstractionId) {
        addLog('Processing amendment data...', 'info');
        // Process amendment with timeout
        const amendmentResult = await withTimeout(
          processAmendment(
            processedData,
            extractedDates,
            abstractionId,
            confidenceThreshold,
            addLog
          ),
          60000, // 1 minute timeout for amendment processing
          'Amendment processing timed out'
        );

        // Ensure dates are properly set for amendments
        const commencementDate = amendmentResult.mergedData.commencementDate;
        const originalCommencementDate = amendmentResult.mergedData.originalCommencementDate;
        const expirationDate = amendmentResult.mergedData.expirationDate;
        // Always set rentStartDate to match commencementDate
        const rentStartDate = commencementDate;

        result = {
          data: {
            ...amendmentResult.mergedData,
            commencementDate,
            originalCommencementDate,
            expirationDate,
            rentStartDate,
            effectiveDate: commencementDate || parseDate(extractedDates.commencementDate)
          },
          confidence: {
            ...confidence,
            commencementDate: extractedDates.confidence.commencementDate,
            originalCommencementDate: extractedDates.confidence.originalCommencementDate,
            expirationDate: extractedDates.confidence.expirationDate,
            // Set rentStartDate confidence to match commencementDate confidence
            rentStartDate: extractedDates.confidence.commencementDate
          },
          changes: amendmentResult.changes,
          effectiveDate: commencementDate || parseDate(extractedDates.commencementDate)
        };

        // Update field status for dates in amendments
        result.data.fieldStatus = {
          ...result.data.fieldStatus,
          commencementDate: commencementDate ? 'not_missing' : 'missing',
          originalCommencementDate: originalCommencementDate ? 'not_missing' : 'missing',
          expirationDate: expirationDate ? 'not_missing' : 'missing',
          rentStartDate: rentStartDate ? 'not_missing' : 'missing'
        };

        addLog('Amendment processing completed', 'success');
      } else {
        addLog('Processing original lease data...', 'info');
        // Handle original lease
        
        // STEP 1: Process dates first
        addLog('Processing original lease dates...', 'info');
        const leaseDates = {
          commencementDate: extractedDates.commencementDate ? parseDate(extractedDates.commencementDate) : null,
          expirationDate: extractedDates.expirationDate ? parseDate(extractedDates.expirationDate) : null,
          rentStartDate: null, // Will be set based on commencementDate
          originalCommencementDate: null // Will be set based on commencementDate
        };

        // Validate and log date processing
        if (leaseDates.commencementDate) {
          addLog(`Lease commencement date found: ${leaseDates.commencementDate.toISOString()}`, 'info');
          // For original lease, always set originalCommencementDate to match commencementDate
          leaseDates.originalCommencementDate = leaseDates.commencementDate;
          
          // For original lease, set rentStartDate to match commencementDate unless explicitly different
          if (extractedDates.rentStartDate && extractedDates.confidence.rentStartDate > 0.8) {
            const parsedRentStart = parseDate(extractedDates.rentStartDate);
            if (parsedRentStart) {
              leaseDates.rentStartDate = parsedRentStart;
              addLog(`Explicit rent start date found: ${parsedRentStart.toISOString()}`, 'info');
            } else {
              leaseDates.rentStartDate = leaseDates.commencementDate;
              addLog('Using commencement date as rent start date', 'info');
            }
          } else {
            leaseDates.rentStartDate = leaseDates.commencementDate;
            addLog('Using commencement date as rent start date', 'info');
          }
        } else {
          addLog('No commencement date found in lease', 'error');
        }

        if (leaseDates.expirationDate) {
          addLog(`Lease expiration date found: ${leaseDates.expirationDate.toISOString()}`, 'info');
        } else {
          addLog('No expiration date found in lease', 'error');
        }

        // Set confidence scores for dates
        const dateConfidence = {
          commencementDate: leaseDates.commencementDate ? extractedDates.confidence.commencementDate : 0,
          originalCommencementDate: leaseDates.commencementDate ? 1 : 0, // Always 100% confidence for original lease
          expirationDate: leaseDates.expirationDate ? extractedDates.confidence.expirationDate : 0,
          rentStartDate: leaseDates.rentStartDate === leaseDates.commencementDate ? 
            extractedDates.confidence.commencementDate : // If using commencement date, use its confidence
            (extractedDates.confidence.rentStartDate || 0) // Otherwise use rent start date confidence
        };

        // STEP 2: Create merged data with processed dates
        const mergedData = createPartialAbstraction(
          {
            ...processedData,
            ...leaseDates
          },
          {
            ...confidence,
            ...dateConfidence
          }
        );

        // STEP 3: Ensure dates are properly formatted for database
        if (mergedData.commencementDate) {
          mergedData.commencementDate = new Date(mergedData.commencementDate);
        }
        if (mergedData.originalCommencementDate) {
          mergedData.originalCommencementDate = new Date(mergedData.originalCommencementDate);
        }
        if (mergedData.expirationDate) {
          mergedData.expirationDate = new Date(mergedData.expirationDate);
        }
        if (mergedData.rentStartDate) {
          mergedData.rentStartDate = new Date(mergedData.rentStartDate);
        }

        // STEP 4: Update field status
        mergedData.fieldStatus = {
          ...mergedData.fieldStatus,
          commencementDate: leaseDates.commencementDate ? 'not_missing' : 'missing',
          originalCommencementDate: leaseDates.originalCommencementDate ? 'not_missing' : 'missing',
          expirationDate: leaseDates.expirationDate ? 'not_missing' : 'missing',
          rentStartDate: leaseDates.rentStartDate ? 'not_missing' : 'missing'
        };

        // Update clause status
        if (mergedData.clauses) {
          Object.keys(mergedData.clauses).forEach(clauseKey => {
            const clause = mergedData.clauses[clauseKey];
            mergedData.fieldStatus[`clause_${clauseKey}`] = 
              clause && typeof clause === 'string' && clause.trim() ? 'not_missing' : 'missing';
          });
        }

        // Update additional clause status
        if (mergedData.additionalClauses) {
          Object.keys(mergedData.additionalClauses).forEach(clauseKey => {
            const clause = mergedData.additionalClauses[clauseKey];
            mergedData.fieldStatus[`additional_clause_${clauseKey}`] = 
              clause && typeof clause === 'string' && clause.trim() ? 'not_missing' : 'missing';
          });
        }

        result = {
          data: mergedData,
          confidence: {
            ...confidence,
            ...dateConfidence
          },
          effectiveDate: leaseDates.commencementDate
        };
        addLog('Original lease processing completed', 'success');
      }

      addLog('Document processing completed successfully', 'success');
      return result;
    } catch (error) {
      console.error('Document processing error:', error);
      if (error instanceof ProcessingError) {
        addLog(`Processing failed: ${error.message}`, 'error');
        throw error;
      }
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      addLog(`Processing failed: ${errorMessage}`, 'error');
      throw ProcessingError.llm(errorMessage, error as Error);
    }
  }), GLOBAL_TIMEOUT, 'Document processing timed out');
}
