import { ProcessLogger } from '../process-logger';
import { chatCompletion } from '../llm/openrouter';
import type { VisionMessage } from '../llm/openrouter';

const MAX_RETRIES = 3;
const INITIAL_RETRY_DELAY = 2000; // 2 seconds
const MAX_RETRY_DELAY = 10000; // 10 seconds

export class OpenAIRetry {
  private readonly maxRetries: number = 3;
  private readonly baseDelay: number = 1000;
  private readonly logger: ProcessLogger;
  private readonly userId: string;

  constructor(userId: string, logger?: ProcessLogger) {
    this.userId = userId;
    this.logger = logger || new ProcessLogger(userId);
  }

  /**
   * Calculate delay with exponential backoff and jitter
   */
  private getRetryDelay(attempt: number): number {
    const backoff = Math.min(
      INITIAL_RETRY_DELAY * Math.pow(2, attempt),
      MAX_RETRY_DELAY
    );
    const jitter = Math.random() * 1000; // Add up to 1 second of jitter
    return backoff + jitter;
  }

  /**
   * Execute OpenRouter API call with retry
   */
  async withRetry<T>(
    operation: () => Promise<T>,
    context: string
  ): Promise<T> {
    let lastError: Error | null = null;
    
    for (let attempt = 0; attempt < this.maxRetries; attempt++) {
      try {
        // Log attempt start
        await this.logger.log(`API Call Attempt ${attempt + 1}/${this.maxRetries}`, 'info');
        
        const result = await operation();
        
        // Detailed raw result logging
        await this.logger.log('=== Raw API Response Details ===', 'info');
        await this.logger.log(`Raw Response Type: ${typeof result}`, 'info');
        await this.logger.log(`Raw Response: ${JSON.stringify(result)}`, 'info');
        await this.logger.log(`Raw Response Keys: ${result ? Object.keys(result).join(', ') : 'none'}`, 'info');
        await this.logger.log(`Full Response Details: ${JSON.stringify({
          type: typeof result,
          isNull: result === null,
          constructor: result?.constructor?.name,
          keys: result ? Object.keys(result) : [],
          response: result
        }, null, 2)}`, 'info');
        
        // Validate response structure
        if (await this.isValidResponse(result as any)) {
          return result;
        }
        
        // If validation fails, log detailed structure
        const response = result as any;
        await this.logger.log('=== Failed Validation Details ===', 'error');
        await this.logger.log(`Response Structure: ${JSON.stringify({
          hasChoices: 'choices' in response,
          choicesIsArray: Array.isArray(response.choices),
          firstChoice: response.choices?.[0],
          hasMessage: response.choices?.[0]?.message,
          hasContent: response.choices?.[0]?.message?.content,
          rawContent: response.choices?.[0]?.message?.content,
          contentType: typeof response.choices?.[0]?.message?.content
        }, null, 2)}`, 'error');
        
        throw new Error('Invalid response structure from API');
      } catch (error) {
        lastError = error instanceof Error ? error : new Error(String(error));
        
        // Enhanced error logging
        await this.logger.log('=== Error Details ===', 'error');
        await this.logger.log(`Error Type: ${error?.constructor?.name}`, 'error');
        await this.logger.log(`Error Message: ${error instanceof Error ? error.message : String(error)}`, 'error');
        if (error instanceof Error && error.stack) {
          await this.logger.log(`Error Stack: ${error.stack}`, 'error');
        }
        
        // Check if it's a rate limit error
        if (this.isRateLimitError(lastError)) {
          await this.logger.log(
            `Rate limit exceeded for ${context}, retrying after delay (attempt ${attempt + 1}/${this.maxRetries})`,
            'info'
          );
        } else {
          await this.logger.log(
            `API error for ${context}, retrying (attempt ${attempt + 1}/${this.maxRetries}): ${lastError.message}`,
            'error'
          );
        }

        // If this is the last attempt, don't delay
        if (attempt === this.maxRetries - 1) {
          break;
        }

        // Add delay between retries
        const delay = this.getRetryDelay(attempt);
        await new Promise(resolve => setTimeout(resolve, delay));
        continue;
      }
    }

    // If we've exhausted retries, throw the last error with context
    const finalError = lastError || new Error(`Failed after ${this.maxRetries} attempts`);
    await this.logger.log(
      `All retry attempts failed for ${context}: ${finalError.message}`,
      'error'
    );
    throw finalError;
  }

  /**
   * Check if error is a rate limit error
   */
  private isRateLimitError(error: Error): boolean {
    return (
      error.message.includes('429') ||
      error.message.toLowerCase().includes('rate limit') ||
      error.message.toLowerCase().includes('too many requests')
    );
  }

  /**
   * Validate OpenRouter API response
   */
  async isValidResponse(response: any): Promise<boolean> {
    if (!response?.choices?.[0]?.message?.content) {
      await this.logger.log('Invalid response structure', 'error');
      return false;
    }

    try {
      if (typeof response.choices[0].message.content === 'string') {
        JSON.parse(response.choices[0].message.content);
      }
      return true;
    } catch (error) {
      await this.logger.log(`Response parsing failed: ${error}`, 'error');
      return false;
    }
  }

  /**
   * Create chat completion with retry logic
   */
  async createChatCompletion(
    params: {
      messages: readonly VisionMessage[];
      model?: string;
      maxTokens?: number;
      temperature?: number;
      presence_penalty?: number;
      frequency_penalty?: number;
      stream?: boolean;
      response_format?: { type: string };
      isVision?: boolean;
    },
    context: string
  ) {
    return this.withRetry(
      () => chatCompletion([...params.messages], {
        model: params.model,
        maxTokens: params.maxTokens,
        response_format: params.response_format,
        isVision: params.isVision
      }),
      context
    );
  }
}
