import { supabase } from './supabase';
import { v4 as uuidv4 } from 'uuid';
import type { ProcessLogTable, NewProcessLog } from '../types/database';

// Define valid log types and document types as const arrays
const LOG_TYPES = ['info', 'error', 'success'] as const;
const DOCUMENT_TYPES = ['lease', 'amendment'] as const;

export type LogType = (typeof LOG_TYPES)[number];
export type DocumentType = (typeof DOCUMENT_TYPES)[number];

// Helper function to format error details
function formatError(error: unknown): string {
  if (error instanceof Error) {
    let message = error.message;
    if (error.stack) {
      message += `\nStack trace:\n${error.stack}`;
    }
    return message;
  }
  if (typeof error === 'object' && error !== null) {
    try {
      return JSON.stringify(error, null, 2);
    } catch {
      return String(error);
    }
  }
  return String(error);
}

/**
 * Service for handling process logging to the database and terminal
 */
export class ProcessLogger {
  private processGroupId: string;
  private userId: string;
  private abstractionId?: string | null;
  private pendingLogs: Promise<void>[] = [];
  private retryCount: number = 0;
  private maxRetries: number = 3;
  private retryDelay: number = 1000; // 1 second

  constructor(userId: string, abstractionId?: string | null) {
    this.processGroupId = uuidv4();
    this.userId = userId;
    this.abstractionId = abstractionId || null;
  }

  /**
   * Logs a message to both the database and terminal
   */
  async log(
    message: string,
    type: LogType = 'info',
    documentName?: string | null,
    documentType?: DocumentType | null
  ): Promise<void> {
    // Log to terminal immediately with timestamp
    const timestamp = new Date().toLocaleTimeString();
    const prefix = documentName ? `[${timestamp}] [${documentName}] ` : `[${timestamp}] `;
    
    switch (type) {
      case 'error':
        console.error(prefix + message);
        break;
      case 'success':
        console.log(prefix + message);
        break;
      default:
        console.log(prefix + message);
    }

    // Prepare log entry
    const logEntry: NewProcessLog = {
      user_id: this.userId,
      message: message.trim(),
      type,
      process_group_id: this.processGroupId,
      document_name: documentName?.trim() || null,
      document_type: documentType || null,
      abstraction_id: this.abstractionId
    };

    // Queue database operation with retries
    const logPromise = this.saveLogWithRetry(logEntry).catch(error => {
      const errorDetails = formatError(error);
      // Only log database errors if we've exhausted all retries
      if (this.retryCount >= this.maxRetries) {
        console.error(`[${timestamp}] Error saving log to database after ${this.maxRetries} retries:`, errorDetails);
        console.error('Failed log entry:', logEntry);
      }
    });

    this.pendingLogs.push(logPromise);
  }

  /**
   * Attempts to save a log entry with retries
   */
  private async saveLogWithRetry(logEntry: NewProcessLog, attempt: number = 1): Promise<void> {
    try {
      // Get current session
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      
      // Handle session error
      if (sessionError) {
        console.error('Session error:', sessionError);
        if (attempt < this.maxRetries) {
          this.retryCount++;
          await new Promise(resolve => setTimeout(resolve, this.retryDelay * attempt));
          return this.saveLogWithRetry(logEntry, attempt + 1);
        }
        throw sessionError;
      }

      // If no session, wait briefly and retry
      if (!session) {
        if (attempt < this.maxRetries) {
          this.retryCount++;
          await new Promise(resolve => setTimeout(resolve, this.retryDelay * attempt));
          return this.saveLogWithRetry(logEntry, attempt + 1);
        }
        console.warn('No active session after retries, skipping database log');
        return;
      }

      // If user ID mismatch, try to refresh session
      if (session.user.id !== this.userId) {
        const { data: { session: refreshedSession }, error: refreshError } = 
          await supabase.auth.refreshSession();
        
        if (refreshError || !refreshedSession) {
          if (attempt < this.maxRetries) {
            this.retryCount++;
            await new Promise(resolve => setTimeout(resolve, this.retryDelay * attempt));
            return this.saveLogWithRetry(logEntry, attempt + 1);
          }
          console.error('User ID mismatch and session refresh failed, skipping database log');
          return;
        }
        
        // Update userId if it was incorrect
        if (refreshedSession.user.id !== this.userId) {
          this.userId = refreshedSession.user.id;
          logEntry.user_id = refreshedSession.user.id;
        }
      }

      const { error } = await supabase
        .from('process_logs')
        .insert([logEntry]);

      if (error) {
        if (attempt < this.maxRetries) {
          this.retryCount++;
          await new Promise(resolve => setTimeout(resolve, this.retryDelay * attempt));
          return this.saveLogWithRetry(logEntry, attempt + 1);
        }
        throw error;
      }
    } catch (error) {
      if (attempt < this.maxRetries) {
        this.retryCount++;
        await new Promise(resolve => setTimeout(resolve, this.retryDelay * attempt));
        return this.saveLogWithRetry(logEntry, attempt + 1);
      }
      throw error;
    }
  }

  /**
   * Waits for all pending log operations to complete
   */
  async flush(): Promise<void> {
    await Promise.all(this.pendingLogs);
    this.pendingLogs = [];
  }

  /**
   * Gets all logs for a specific process group
   */
  async getProcessGroupLogs(): Promise<ProcessLogTable[]> {
    try {
      const { data, error } = await supabase
        .from('process_logs')
        .select('*')
        .eq('process_group_id', this.processGroupId)
        .order('created_at', { ascending: true });

      if (error) {
        throw error;
      }

      return ((data || []) as Record<string, any>[]).map(log => ({
        id: log.id,
        user_id: log.user_id,
        message: log.message,
        type: log.type,
        process_group_id: log.process_group_id,
        created_at: log.created_at,
        abstraction_id: log.abstraction_id,
        document_name: log.document_name,
        document_type: log.document_type
      }));
    } catch (error) {
      const errorDetails = formatError(error);
      console.error('Error in getProcessGroupLogs:', errorDetails);
      return [];
    }
  }

  /**
   * Gets all logs for a specific user
   */
  async getUserLogs(): Promise<ProcessLogTable[]> {
    try {
      const { data, error } = await supabase
        .from('process_logs')
        .select('*')
        .eq('user_id', this.userId)
        .order('created_at', { ascending: false });

      if (error) {
        throw error;
      }

      return ((data || []) as Record<string, any>[]).map(log => ({
        id: log.id,
        user_id: log.user_id,
        message: log.message,
        type: log.type,
        process_group_id: log.process_group_id,
        created_at: log.created_at,
        abstraction_id: log.abstraction_id,
        document_name: log.document_name,
        document_type: log.document_type
      }));
    } catch (error) {
      const errorDetails = formatError(error);
      console.error('Error in getUserLogs:', errorDetails);
      return [];
    }
  }

  /**
   * Gets all logs for a specific abstraction
   */
  async getAbstractionLogs(): Promise<ProcessLogTable[]> {
    try {
      if (!this.abstractionId) {
        throw new Error('No abstraction ID set');
      }

      const { data, error } = await supabase
        .from('process_logs')
        .select('*')
        .eq('abstraction_id', this.abstractionId)
        .order('created_at', { ascending: false });

      if (error) {
        throw error;
      }

      return ((data || []) as Record<string, any>[]).map(log => ({
        id: log.id,
        user_id: log.user_id,
        message: log.message,
        type: log.type,
        process_group_id: log.process_group_id,
        created_at: log.created_at,
        abstraction_id: log.abstraction_id,
        document_name: log.document_name,
        document_type: log.document_type
      }));
    } catch (error) {
      const errorDetails = formatError(error);
      console.error('Error in getAbstractionLogs:', errorDetails);
      return [];
    }
  }
}
