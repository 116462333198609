import { createAbstraction } from '../../../lib/db';
import { createAbstractionVersion } from '../../../lib/db/versions';
import { processDocumentWithDualLLM } from '../../../lib/llm/processors/process-dual-llm';
import { trackDocument } from '../../../lib/db/documents/tracking';
import { extractTextFromDocument } from '../../../lib/llm/processors/text-extraction';
import { ProcessingError } from '../../../lib/llm/processors/errors';
import { ProcessLogger } from '../../../lib/process-logger';
import type { LoggerFunction } from '../types';
import type { LeaseAbstraction } from '../../../types/lease';

export async function processMainLease(
  file: File,
  filePath: string,
  userId: string,
  addLog: LoggerFunction,
  processLogger: ProcessLogger
): Promise<LeaseAbstraction> {
  try {
    // Validate inputs
    if (!file) {
      throw ProcessingError.validation([{
        field: 'file',
        message: 'File is required'
      }]);
    }
    if (!filePath) {
      throw ProcessingError.validation([{
        field: 'filePath',
        message: 'File path is required'
      }]);
    }
    if (!userId) {
      throw ProcessingError.validation([{
        field: 'userId',
        message: 'User ID is required'
      }]);
    }

    // Extract text from document
    const processMsg = 'Extracting text from document...';
    addLog(processMsg);
    await processLogger.log(processMsg);

    let text;
    try {
      text = await extractTextFromDocument(file);
      const successMsg = 'Text extraction completed successfully';
      addLog(successMsg);
      await processLogger.log(successMsg, 'success');
    } catch (extractError) {
      if (extractError instanceof ProcessingError) {
        throw extractError;
      }
      throw ProcessingError.document(
        `Text extraction failed: ${extractError instanceof Error ? extractError.message : 'Unknown error'}`,
        extractError as Error
      );
    }

    // Process with LLM
    const llmMsg = 'Starting dual LLM processing...';
    addLog(llmMsg);
    await processLogger.log(llmMsg);

    let mainLeaseData;
    try {
      mainLeaseData = await processDocumentWithDualLLM(text, addLog, {
        isAmendment: false,
        confidenceThreshold: 0.7,
        userId,
        file // Pass the file for image processing
      });
      
      if (!mainLeaseData) {
        throw ProcessingError.llm('No data returned from LLM processing');
      }
      if (!mainLeaseData.data) {
        throw ProcessingError.llm('No lease data extracted from document');
      }
      const successMsg = 'LLM processing completed successfully';
      addLog(successMsg);
      await processLogger.log(successMsg, 'success');
    } catch (llmError) {
      if (llmError instanceof ProcessingError) {
        throw llmError;
      }
      throw ProcessingError.llm(
        `LLM processing failed: ${llmError instanceof Error ? llmError.message : 'Unknown error'}`,
        llmError as Error
      );
    }

    // Create abstraction
    const createMsg = 'Creating abstraction...';
    addLog(createMsg);
    await processLogger.log(createMsg);

    const { data: abstraction, error: createError } = await createAbstraction({
      ...mainLeaseData.data,
      userId,
      documentPath: filePath,
      confidence: mainLeaseData.confidence || {}
    });

    if (createError) {
      throw ProcessingError.database(`Failed to create abstraction: ${createError}`);
    }

    if (!abstraction) {
      throw ProcessingError.database('No abstraction data returned after creation');
    }

    // Track the document
    const trackMsg = 'Tracking document...';
    addLog(trackMsg);
    await processLogger.log(trackMsg);

    const { error: trackError } = await trackDocument(file, userId, abstraction.id);

    if (trackError) {
      throw ProcessingError.database(`Failed to track document: ${trackError}`);
    }

    const successMsg = 'Lease processing completed successfully';
    addLog(successMsg, 'success');
    await processLogger.log(successMsg, 'success');
    return abstraction;
  } catch (error) {
    const errorMessage = error instanceof ProcessingError 
      ? error.message 
      : error instanceof Error 
        ? error.message 
        : 'Unknown error';
    
    addLog(`Failed to process lease: ${errorMessage}`, 'error');
    await processLogger.log(`Failed to process lease: ${errorMessage}`, 'error');
    
    if (error instanceof ProcessingError) {
      throw error;
    }
    throw ProcessingError.document(
      'Failed to process lease',
      error as Error
    );
  }
}

export async function processAmendment(
  file: File,
  filePath: string,
  abstractionId: string,
  userId: string,
  addLog: LoggerFunction,
  processLogger: ProcessLogger
): Promise<void> {
  try {
    // Validate inputs
    if (!file) {
      throw ProcessingError.validation([{
        field: 'file',
        message: 'File is required'
      }]);
    }
    if (!filePath) {
      throw ProcessingError.validation([{
        field: 'filePath',
        message: 'File path is required'
      }]);
    }
    if (!abstractionId) {
      throw ProcessingError.validation([{
        field: 'abstractionId',
        message: 'Abstraction ID is required'
      }]);
    }
    if (!userId) {
      throw ProcessingError.validation([{
        field: 'userId',
        message: 'User ID is required'
      }]);
    }

    // Extract text from amendment
    const processMsg = 'Extracting text from amendment...';
    addLog(processMsg);
    await processLogger.log(processMsg);

    let text;
    try {
      text = await extractTextFromDocument(file);
      const successMsg = 'Text extraction completed successfully';
      addLog(successMsg);
      await processLogger.log(successMsg, 'success');
    } catch (extractError) {
      if (extractError instanceof ProcessingError) {
        throw extractError;
      }
      throw ProcessingError.document(
        `Text extraction failed: ${extractError instanceof Error ? extractError.message : 'Unknown error'}`,
        extractError as Error
      );
    }

    // Process with LLM
    const llmMsg = 'Processing amendment content...';
    addLog(llmMsg);
    await processLogger.log(llmMsg);

    let amendmentData;
    try {
      amendmentData = await processDocumentWithDualLLM(text, addLog, {
        isAmendment: true,
        confidenceThreshold: 0.7,
        abstractionId,
        file
      });
      
      if (!amendmentData) {
        throw ProcessingError.llm('No data returned from amendment processing');
      }
      if (!amendmentData.data) {
        throw ProcessingError.llm('No amendment data extracted from document');
      }
      const successMsg = 'Amendment processing completed successfully';
      addLog(successMsg);
      await processLogger.log(successMsg, 'success');
    } catch (llmError) {
      if (llmError instanceof ProcessingError) {
        throw llmError;
      }
      throw ProcessingError.llm(
        `Amendment processing failed: ${llmError instanceof Error ? llmError.message : 'Unknown error'}`,
        llmError as Error
      );
    }

    // Create new version
    const versionMsg = 'Creating amendment version...';
    addLog(versionMsg);
    await processLogger.log(versionMsg);

    const { error: versionError } = await createAbstractionVersion(abstractionId, {
      type: 'amendment',
      effectiveDate: amendmentData.effectiveDate,
      documentPath: filePath,
      changes: JSON.stringify(amendmentData.changes || {}),
      data: amendmentData
    });

    if (versionError) {
      throw ProcessingError.database(`Failed to create amendment version: ${versionError}`);
    }

    // Track the document
    const trackMsg = 'Tracking amendment document...';
    addLog(trackMsg);
    await processLogger.log(trackMsg);

    const { error: trackError } = await trackDocument(file, userId, abstractionId);

    if (trackError) {
      throw ProcessingError.database(`Failed to track amendment: ${trackError}`);
    }

    const successMsg = 'Amendment processing completed successfully';
    addLog(successMsg, 'success');
    await processLogger.log(successMsg, 'success');
  } catch (error) {
    const errorMessage = error instanceof ProcessingError 
      ? error.message 
      : error instanceof Error 
        ? error.message 
        : 'Unknown error';
    
    addLog(`Failed to process amendment: ${errorMessage}`, 'error');
    await processLogger.log(`Failed to process amendment: ${errorMessage}`, 'error');
    
    if (error instanceof ProcessingError) {
      throw error;
    }
    throw ProcessingError.document(
      'Failed to process amendment',
      error as Error
    );
  }
}
