import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog';
import { Button } from '../ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Label } from '../ui/label';
import { ScrollArea } from '../ui/scroll-area';
import { DocumentToConfirm, DocumentType } from '../../types/document';
import { Card, CardContent, CardHeader } from '../ui/card';

interface Props {
  open: boolean;
  documents: DocumentToConfirm[];
  onConfirm: (confirmedTypes: Array<{ name: string; type: DocumentType }>) => void;
  onCancel: () => void;
}

export function DocumentTypeConfirmation({ open, documents, onConfirm }: Props) {
  const handleConfirm = () => {
    onConfirm(documents.map(doc => ({
      name: doc.name,
      type: doc.suggestedType
    })));
  };

  return (
    <Dialog open={open} modal={true}>
      <DialogContent className="max-w-full h-screen flex flex-col overflow-hidden p-0">
        <DialogHeader className="p-6 border-b">
          <DialogTitle className="text-xl">Confirm Document Types</DialogTitle>
        </DialogHeader>

        <ScrollArea className="flex-1 p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {documents.map((doc, index) => (
              <Card key={doc.name} className="flex flex-col">
                <CardHeader className="flex-none pb-2">
                  <div className="flex items-start justify-between gap-4">
                    <Label 
                      className="text-base truncate flex-1 pt-2" 
                      title={doc.name}
                    >
                      {doc.name}
                    </Label>
                    <Select
                      defaultValue={doc.suggestedType}
                      onValueChange={(value: DocumentType) => {
                        documents[index].suggestedType = value;
                      }}
                    >
                      <SelectTrigger className="w-[180px]">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent 
                        position="popper" 
                        className="w-[180px]"
                        align="end"
                        side="top"
                      >
                        <SelectItem value="original_lease">Original Lease</SelectItem>
                        <SelectItem value="amendment">Amendment</SelectItem>
                        <SelectItem value="option">Option</SelectItem>
                        <SelectItem value="extension">Extension</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </CardHeader>

                <CardContent className="flex-1 space-y-3 text-sm text-muted-foreground">
                  {doc.parties && (
                    <>
                      {doc.parties.landlord && <div>Landlord: {doc.parties.landlord}</div>}
                      {doc.parties.tenant && <div>Tenant: {doc.parties.tenant}</div>}
                    </>
                  )}

                  {doc.premises && (
                    <div>Premises: {doc.premises}</div>
                  )}

                  {doc.effectiveDate && (
                    <div>
                      Effective: {new Date(doc.effectiveDate).toLocaleDateString()}
                      {doc.expirationDate && ` - ${new Date(doc.expirationDate).toLocaleDateString()}`}
                    </div>
                  )}

                  {doc.modificationSummary && (
                    <div>Changes: {doc.modificationSummary}</div>
                  )}

                  {doc.sequence && (
                    <div>Sequence: #{doc.sequence}</div>
                  )}

                  <div>
                    Confidence: {Math.round(doc.confidence * 100)}%
                  </div>

                  {doc.keyTerms && doc.keyTerms.length > 0 && (
                    <div>Key Terms: {doc.keyTerms.join(', ')}</div>
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
        </ScrollArea>

        <DialogFooter className="p-6 border-t">
          <Button 
            size="lg"
            onClick={handleConfirm}
            className="w-full md:w-auto"
          >
            Confirm and Process Documents
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
