import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Button } from '../ui/button';
import { FileText } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useEffect, useState } from 'react';

interface Props {
  open: boolean;
  abstractionIds: string[];
  onSelect: (abstractionId: string) => void;
  onClose: () => void;
}

interface AbstractionDetails {
  id: string;
  tenant: string;
  premises: string;
  documentCount: number;
}

export function CreatedAbstractionsList({ open, abstractionIds, onSelect, onClose }: Props) {
  const [abstractions, setAbstractions] = useState<AbstractionDetails[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadAbstractions = async () => {
      setLoading(true);
      try {
        // Load details for each abstraction
        const details = await Promise.all(
          abstractionIds.map(async (id) => {
            const { data: documents, error } = await supabase
              .from('documents')
              .select('tenant, premises')
              .eq('abstraction_id', id);

            if (error) throw error;
            if (!documents?.length) return null;

            return {
              id,
              tenant: documents[0].tenant,
              premises: documents[0].premises,
              documentCount: documents.length
            };
          })
        );

        setAbstractions(details.filter((d): d is AbstractionDetails => d !== null));
      } catch (error) {
        console.error('Error loading abstraction details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (open && abstractionIds.length > 0) {
      loadAbstractions();
    }
  }, [open, abstractionIds]);

  return (
    <Dialog open={open} onOpenChange={open => !open && onClose()}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Created Abstractions</DialogTitle>
        </DialogHeader>

        <div className="space-y-6">
          {loading ? (
            <div className="text-center text-muted-foreground">Loading abstraction details...</div>
          ) : (
            abstractions.map(abstraction => (
              <div
                key={abstraction.id}
                className="p-4 border rounded-lg hover:border-primary cursor-pointer transition-colors"
                onClick={() => onSelect(abstraction.id)}
              >
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-lg font-semibold">{abstraction.tenant}</h3>
                  <div className="flex items-center text-sm text-muted-foreground">
                    <FileText className="w-4 h-4 mr-1" />
                    {abstraction.documentCount} document{abstraction.documentCount !== 1 ? 's' : ''}
                  </div>
                </div>

                <div className="text-sm text-muted-foreground">
                  <div className="font-medium">Premises</div>
                  <div>{abstraction.premises}</div>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="flex justify-end">
          <Button variant="outline" onClick={onClose}>Close</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
