import { ProcessLogger } from '../process-logger';
import { feedbackService } from './feedback-service';
import { DocumentClassificationService } from './document-classification-service';
import { TextExtractionService } from './text-extraction-service';
import { DocumentType } from '../../types/document';
import { supabase } from '../../lib/supabase';
import { TextChunker } from '../utils/text-chunker';
import { OpenAIRetry } from '../utils/openai-retry';

export interface DocumentDetails {
  id: string;
  type: DocumentType;
  title: string;
  effectiveDate?: string;
  expirationDate?: string;
  modificationSummary?: string;
  sequence?: number;
  keyTerms?: string[];
  parties?: {
    landlord?: string;
    tenant?: string;
  };
  premises?: string;
  confidence: number;
}

export interface DocumentGroup {
  id: string;
  name: string;
  primaryDocument?: DocumentDetails;
  relatedDocuments: (DocumentDetails & { relationship: string })[];
  confidence: number;
}

export interface AbstractionBox {
  id: string;
  groups: DocumentGroup[];
  unassignedDocuments: string[];
  processingTime: number;
}

interface ProcessedDocument {
  id: string;
  content: string;
  name: string;
  file: File;
  skipFloorPlanCheck?: boolean;
  premises?: string;
  tenant?: string;
  modifiesChecksum?: string;
  premisesDescription?: string;
  financials?: any;
  parties?: any;
  extractedDates?: {
    commencementDate?: string;
    originalCommencementDate?: string;
    expirationDate?: string;
    rentStartDate?: string;
    effectiveDate?: string;
  };
  modificationSummary?: string;
}

export class MultiDocumentService {
  private processLogger: ProcessLogger;
  private textChunker: TextChunker;
  private userId: string;
  private classificationService: DocumentClassificationService;
  private extractionService: TextExtractionService;
  private openaiRetry: OpenAIRetry;

  constructor(apiKey: string, userId: string) {
    this.userId = userId;
    this.processLogger = new ProcessLogger(userId);
    this.textChunker = new TextChunker(userId);
    this.openaiRetry = new OpenAIRetry(userId);
    this.classificationService = new DocumentClassificationService(apiKey, userId);
    this.extractionService = new TextExtractionService(apiKey, userId);
    feedbackService.setLogger(new ProcessLogger(userId));
  }

  /**
   * Get confidence score for document classification
   */
  private async getDocumentConfidence(text: string, type: DocumentType): Promise<number> {
    await this.processLogger.log(
      `Starting confidence scoring for ${type} document (${text.length} characters)...`,
      'info'
    );

    const prompt = `You are a document classifier. Given a document text, determine how confident you are that it is a ${type}.
Return ONLY a number between 0.0 and 1.0 representing your confidence level. Do not include any other text, explanations, or formatting.
Examples:
- If very confident: 0.95
- If somewhat confident: 0.7
- If not confident: 0.2
Respond with ONLY the number.`;

    const messages = [
      { role: 'system' as const, content: prompt },
      { role: 'user' as const, content: text.substring(0, 8000) }
    ];

    try {
      await this.processLogger.log('Preparing API request...', 'info');
      
      // Log the request configuration
      const requestConfig = {
        model: 'openai/gpt-4o-2024-11-20',
        messages,
        maxTokens: 10,
        temperature: 0,
        response_format: { type: "text" }
      };
      
      await this.processLogger.log(
        `Request configuration: ${JSON.stringify(requestConfig, null, 2)}`,
        'info'
      );
      
      const completion = await this.openaiRetry.createChatCompletion(
        requestConfig,
        'confidence-scoring'
      );

      if (!completion?.choices?.[0]?.message?.content) {
        await this.processLogger.log(
          `Invalid response structure: ${JSON.stringify(completion, null, 2)}`,
          'error'
        );
        return 0.5;
      }

      const rawScore = completion.choices[0].message.content.trim();
      await this.processLogger.log(
        `Raw score from LSC-o1 model (after trim): "${rawScore}"`,
        'info'
      );

      const score = parseFloat(rawScore);

      if (isNaN(score) || score < 0 || score > 1) {
        await this.processLogger.log(
          `Invalid confidence score: ${rawScore}`,
          'error'
        );
        return 0.5;
      }

      const finalScore = Math.min(Math.max(score, 0), 1);
      await this.processLogger.log(
        `Final confidence score: ${Math.round(finalScore * 100)}%`,
        finalScore > 0.7 ? 'success' : 'info'
      );

      return finalScore;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      await this.processLogger.log(
        `Error during confidence scoring: ${errorMessage}`,
        'error'
      );
      if (error instanceof Error && error.stack) {
        await this.processLogger.log(
          `Error stack trace: ${error.stack}`,
          'error'
        );
      }
      await this.processLogger.log(
        'Using default confidence score of 50%',
        'info'
      );
      return 0.5;
    }
  }

  /**
   * Get enhanced document grouping prompt
   */
  private async getEnhancedPrompt(): Promise<string> {
    try {
      const enhancement = await feedbackService.getEnhancedPrompt('document_grouping');
      
      const prompt = `
        ${enhancement.basePrompt}
        
        LSM-o1 Document Organization Guidelines:
        ${enhancement.warnings.join('\n')}
        
        Training Examples:
        ${enhancement.examples.map(e => 
          `Document Set: ${e.original}\nGrouping Result: ${e.corrected}`
        ).join('\n\n')}
        
        Analyze the provided documents and:
        1. Identify primary lease documents
        2. Group related amendments and supplements
        3. Establish document relationships
        4. Create logical document groups
        5. Handle unassociated documents
        
        Consider:
        - Document references
        - Dates and chronology
        - Property identifiers
        - Party names
        - Cross-references
        
        Return a JSON object with the following structure:
        {
          "groups": [
            {
              "id": "string",
              "name": "string",
              "primaryDocument": {
                "id": "string",
                "type": "original_lease|amendment|option|extension",
                "title": "string",
                "effectiveDate": "YYYY-MM-DD",
                "expirationDate": "YYYY-MM-DD",
                "modificationSummary": "string",
                "sequence": number,
                "keyTerms": ["string"],
                "parties": {
                  "landlord": "string",
                  "tenant": "string"
                },
                "premises": "string"
              },
              "relatedDocuments": [
                {
                  "id": "string",
                  "type": "original_lease|amendment|option|extension",
                  "title": "string",
                  "effectiveDate": "YYYY-MM-DD",
                  "expirationDate": "YYYY-MM-DD",
                  "modificationSummary": "string",
                  "sequence": number,
                  "keyTerms": ["string"],
                  "parties": {
                    "landlord": "string",
                    "tenant": "string"
                  },
                  "premises": "string",
                  "relationship": "string"
                }
              ]
            }
          ]
        }

        IMPORTANT: 
        - All string values must be properly escaped JSON strings
        - Dates must be in YYYY-MM-DD format
        - All IDs must match the provided document IDs
        - The response must be a valid JSON object with a 'groups' array
      `;

      await this.processLogger.log(
        `LSM-o1 initialized with ${enhancement.examples.length} training examples`,
        'info'
      );

      return prompt.trim();
    } catch (error) {
      await this.processLogger.log(
        `Error initializing LSM-o1, using base configuration: ${error}`,
        'error'
      );
      return `Analyze and group related lease documents, identifying primary leases and their amendments.`;
    }
  }

  /**
   * Create document groups using LSM-o1
   */
  private async createGroups(documents: { id: string; text: string; type: DocumentType }[]): Promise<DocumentGroup[]> {
    try {
      const systemPrompt = await this.getEnhancedPrompt();

      await this.processLogger.log(
        `LSM-o1 analyzing ${documents.length} documents for grouping`,
        'info'
      );

      // Extract key sections and create summaries
      const documentSummaries = documents.map(doc => {
        const sections = this.textChunker.extractKeySections(doc.text);
        const classificationText = this.textChunker.extractClassificationText(doc.text);
        return {
          id: doc.id,
          type: doc.type,
          summary: classificationText,
          sections
        };
      });

      // Process all documents together instead of in chunks
      const completion = await this.openaiRetry.createChatCompletion({
        model: 'openai/gpt-4o-2024-11-20',
        messages: [
          {
            role: 'system',
            content: systemPrompt + '\n\nIMPORTANT: Analyze ALL documents together to maintain context and relationships. Return ONLY a JSON object with the exact structure specified.'
          },
          {
            role: 'user',
            content: JSON.stringify({
              documents: documentSummaries,
              context: 'These documents may be related. Please analyze them together to maintain context and identify relationships between original leases and amendments.'
            })
          }
        ],
        maxTokens: 4000,
        temperature: 0.1,
        presence_penalty: 0,
        frequency_penalty: 0,
        stream: false,
        response_format: { type: "json_object" }
      }, 'document-grouping');

      if (!completion || !('choices' in completion)) {
        throw new Error('Invalid completion response');
      }

      let result = completion.choices[0]?.message?.content || '';
      
      // Try to parse the result, with fallback to empty groups array
      let rawGroups;
      try {
        // Clean up the response to ensure it's valid JSON
        result = result.replace(/^```json\s*|\s*```$/g, '').trim();
        result = result.trim();
        
        // Ensure we have a valid JSON object with a groups array
        const parsed = JSON.parse(result);
        if (!parsed || typeof parsed !== 'object' || !Array.isArray(parsed.groups)) {
          throw new Error('Response missing groups array');
        }
        
        rawGroups = parsed.groups;
      } catch (error) {
        await this.processLogger.log(
          `Failed to parse LSM-o1 response: ${error}. Using empty groups array.`,
          'error'
        );
        rawGroups = [];
      }

      // Validate and transform the response to match our types
      const groups: DocumentGroup[] = rawGroups.map((group: any) => {
        // Find the document with the most complete data to use as reference
        const allDocs = [group.primaryDocument, ...(group.relatedDocuments || [])].filter(Boolean);
        const referenceDoc = allDocs.reduce((best, current) => {
          const bestScore = (best?.parties?.tenant ? 1 : 0) + (best?.premises ? 1 : 0) + (best?.parties?.landlord ? 1 : 0);
          const currentScore = (current?.parties?.tenant ? 1 : 0) + (current?.premises ? 1 : 0) + (current?.parties?.landlord ? 1 : 0);
          return currentScore > bestScore ? current : best;
        }, group.primaryDocument || {});

        // Extract consistent data from reference document
        const tenant = referenceDoc?.parties?.tenant || '';
        const premises = referenceDoc?.premises || '';
        const landlord = referenceDoc?.parties?.landlord || '';

        // Helper function to normalize document data
        const normalizeDocument = (doc: any) => {
          if (!doc) return undefined;
          
          // Preserve original dates and specific fields
          const originalDates = {
            effectiveDate: doc.effectiveDate,
            expirationDate: doc.expirationDate,
            commencementDate: doc.commencementDate,
            rentStartDate: doc.rentStartDate
          };

          const originalFields = {
            sequence: doc.sequence,
            modificationSummary: doc.modificationSummary,
            keyTerms: doc.keyTerms || [],
            changes: doc.changes
          };

          return {
            ...doc,
            ...originalDates,
            ...originalFields,
            parties: {
              landlord: landlord,
              tenant: tenant
            },
            premises: premises,
            // Boost confidence if we have good tenant/premises match
            confidence: doc.confidence || (tenant && premises ? 0.9 : 0.5)
          };
        };

        // Create the group with normalized data
        return {
          id: group.id || crypto.randomUUID(),
          name: group.name || `${tenant} - ${premises}`.trim() || 'Document Group',
          primaryDocument: normalizeDocument(group.primaryDocument),
          relatedDocuments: (group.relatedDocuments || []).map(doc => ({
            ...normalizeDocument(doc),
            relationship: doc.relationship || 'related'
          })),
          confidence: completion.choices[0].finish_reason === 'stop' ? 0.9 : 0.5
        };
      });

      // Post-process groups to ensure data consistency
      const processedGroups = groups.map(group => {
        // If any document in the group has high confidence data, propagate it
        const allDocs = [group.primaryDocument, ...group.relatedDocuments].filter(Boolean);
        const highConfidenceDocs = allDocs.filter(doc => doc.confidence >= 0.9);
        
        if (highConfidenceDocs.length > 0) {
          const bestDoc = highConfidenceDocs[0];
          return {
            ...group,
            primaryDocument: group.primaryDocument ? {
              ...group.primaryDocument,
              parties: bestDoc.parties,
              premises: bestDoc.premises,
              confidence: Math.max(group.primaryDocument.confidence, 0.9)
            } : undefined,
            relatedDocuments: group.relatedDocuments.map(doc => ({
              ...doc,
              parties: bestDoc.parties,
              premises: bestDoc.premises,
              confidence: Math.max(doc.confidence, 0.85)
            }))
          };
        }
        return group;
      });

      await this.processLogger.log(
        `LSM-o1 created ${processedGroups.length} document groups`,
        'success'
      );

      return processedGroups;
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown LSM-o1 error';
      await this.processLogger.log(
        `LSM-o1 grouping error: ${message}`,
        'error'
      );
      throw error;
    }
  }

  /**
   * Process multiple documents and create an Abstraction Box
   */
  async processDocuments(documents: ProcessedDocument[]): Promise<AbstractionBox> {
    const startTime = Date.now();
    const results: AbstractionBox = {
      id: `box-${Date.now()}`,
      groups: [],
      unassignedDocuments: [],
      processingTime: 0
    };

    try {
      await this.processLogger.log(
        `Beginning analysis of ${documents.length} documents...`,
        'info'
      );

      // Process documents sequentially
      const classifiedDocs = [];
      for (const doc of documents) {
        await this.processLogger.log(
          `Analyzing document ${doc.id}...`,
          'info'
        );

        try {
          // Create document record
          await this.processLogger.log('Creating document record...', 'info');
          const { data: document, error: documentError } = await supabase
            .from('documents')
            .insert({
              id: doc.id,
              name: doc.name || `Document ${doc.id}`,
              file_path: `temp/${doc.id}`,
              user_id: this.userId,
              premises: doc.premises || null,
              tenant: doc.tenant || null
            })
            .select()
            .single();

          if (documentError) {
            throw new Error(`Failed to create document record: ${documentError.message}`);
          }
          await this.processLogger.log('Document record created successfully', 'success');

          // Extract first 2000 characters for quick content check
          const initialContent = doc.content.slice(0, 2000).toLowerCase();
          
          // Force classification based on clear indicators
          let forcedType: DocumentType | null = null;
          
          // Check for clear amendment indicators
          if (
            /^\s*(?:first|second|third|fourth|fifth|\d+(?:st|nd|rd|th))?\s*amendment\s+to\s+lease/i.test(initialContent) ||
            /^\s*amendment\s+no\.\s*\d+/i.test(initialContent) ||
            /this\s+amendment\s+to\s+lease.*dated/i.test(initialContent)
          ) {
            forcedType = 'amendment';
            await this.processLogger.log(
              `Forced classification as amendment based on clear document title/content`,
              'info'
            );
          }
          
          // Check for clear original lease indicators
          else if (
            /^\s*(?:standard\s+)?(?:office|industrial|commercial|retail)?\s*lease\s+agreement/i.test(initialContent) ||
            /^\s*lease\s+agreement/i.test(initialContent) ||
            /this\s+lease\s+agreement.*made.*between/i.test(initialContent)
          ) {
            forcedType = 'original_lease';
            await this.processLogger.log(
              `Forced classification as original lease based on clear document title/content`,
              'info'
            );
          }

          // Classify the document
          await this.processLogger.log('Starting document classification...', 'info');
          const classification = forcedType ? {
            type: forcedType,
            confidence: 0.98,
            processingTime: 0
          } : await this.classificationService.classifyDocument(
            document.id, 
            doc.content,
            {
              filename: doc.name,
              textLength: doc.content.length
            },
            doc.file,
            doc.skipFloorPlanCheck // Pass the skipFloorPlanCheck flag
          );

          await this.processLogger.log(
            `Initial classification complete: ${classification.type}`,
            'success'
          );
          
          // Get AI confidence score
          await this.processLogger.log('Getting confidence score...', 'info');
          const confidence = forcedType ? 0.98 : await this.getDocumentConfidence(doc.content, classification.type);
          
          await this.processLogger.log(
            `Document type: ${classification.type} (Confidence: ${Math.round(confidence * 100)}%)`,
            confidence > 0.7 ? 'success' : 'info'
          );

          // Map the type to the enum value
          const dbType = classification.type === 'original_lease' ? 'original_lease' : 'amendment';
          
          // Create document classification record
          await this.processLogger.log('Storing classification in database...', 'info');
          
          // First check if classification exists
          const { data: existingClassification, error: checkError } = await supabase
            .from('document_classifications')
            .select('*')
            .eq('document_id', document.id)
            .maybeSingle();

          if (checkError) {
            throw new Error(`Failed to check existing classification: ${checkError.message}`);
          }

          // Find parent document for amendments
          let parentDocumentId = null;
          if (dbType === 'amendment' && doc.modifiesChecksum) {
            const { data: parentDoc } = await supabase
              .from('documents')
              .select('id')
              .eq('checksum', doc.modifiesChecksum)
              .single();
            parentDocumentId = parentDoc?.id;
          }

          // Update document with tenant and premises info immediately
          const { error: docUpdateError } = await supabase
            .from('documents')
            .update({
              tenant: doc.tenant || null,
              premises: doc.premises || null
            })
            .eq('id', document.id);

          if (docUpdateError) {
            throw new Error(`Failed to update document with tenant and premises: ${docUpdateError.message}`);
          }

          let classificationError;
          if (existingClassification) {
            // Update existing classification
            const { error: updateError } = await supabase
              .from('document_classifications')
              .update({
                type: dbType,
                confidence_score: confidence,
                parent_document_id: parentDocumentId,
                extracted_dates: {
                  commencementDate: doc.extractedDates?.commencementDate || null,
                  originalCommencementDate: doc.extractedDates?.originalCommencementDate || null,
                  expirationDate: doc.extractedDates?.expirationDate || null,
                  rentStartDate: doc.extractedDates?.rentStartDate || null
                },
                extracted_parties: doc.parties || null,
                extracted_premises: {
                  address: doc.premises || null,
                  description: doc.premisesDescription || null
                },
                extracted_financials: doc.financials || null,
                updated_at: new Date().toISOString()
              })
              .eq('document_id', document.id);
            classificationError = updateError;
          } else {
            // Insert new classification
            const { error: insertError } = await supabase
              .from('document_classifications')
              .insert([{
                document_id: document.id,
                type: dbType,
                confidence_score: confidence,
                parent_document_id: parentDocumentId,
                extracted_dates: {
                  commencementDate: doc.extractedDates?.commencementDate || null,
                  originalCommencementDate: doc.extractedDates?.originalCommencementDate || null,
                  expirationDate: doc.extractedDates?.expirationDate || null,
                  rentStartDate: doc.extractedDates?.rentStartDate || null
                },
                extracted_parties: doc.parties || null,
                extracted_premises: {
                  address: doc.premises || null,
                  description: doc.premisesDescription || null
                },
                extracted_financials: doc.financials || null,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString()
              }]);
            classificationError = insertError;
          }

          if (classificationError) {
            throw new Error(`Failed to create document classification: ${classificationError.message}`);
          }

          // Create document relationship if this is an amendment
          if (dbType === 'amendment' && parentDocumentId) {
            await this.processLogger.log('Creating document relationship...', 'info');
            const { error: relationshipError } = await supabase
              .from('document_relationships')
              .insert([{
                parent_document_id: parentDocumentId,
                child_document_id: document.id,
                relationship_type: 'amendment',
                effective_date: doc.extractedDates?.effectiveDate || null,
                modification_summary: doc.modificationSummary || null,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString()
              }]);

            if (relationshipError) {
              throw new Error(`Failed to create document relationship: ${relationshipError.message}`);
            }
          }

          classifiedDocs.push({
            id: doc.id,
            text: doc.content,
            type: classification.type,
            confidence,
            forcedType: forcedType !== null
          });

        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : 'Unknown error';
          await this.processLogger.log(
            `Error analyzing document ${doc.id}: ${errorMessage}`,
            'error'
          );
          throw error;
        }
      }

      // Group documents by tenant and premises
      const documentGroups = new Map<string, Array<typeof classifiedDocs[0]>>();
      
      for (const doc of classifiedDocs) {
        // Extract tenant and premises from document content
        const content = doc.text.slice(0, 5000).toLowerCase();
        const tenantMatch = content.match(/tenant[:\s]+([^,\n]+)/i);
        const premisesMatch = content.match(/premises[:\s]+([^,\n]+)/i);
        
        const tenant = tenantMatch?.[1]?.trim().toLowerCase() || '';
        const premises = premisesMatch?.[1]?.trim().toLowerCase() || '';
        
        const key = `${tenant}-${premises}`;
        
        if (!documentGroups.has(key)) {
          documentGroups.set(key, []);
        }
        documentGroups.get(key)!.push(doc);
      }

      // Process each group
      for (const [key, docs] of documentGroups) {
        // Sort documents by confidence
        docs.sort((a, b) => b.confidence - a.confidence);
        
        // Find the original lease (prefer forced types)
        const originalLease = docs.find(d => 
          d.type === 'original_lease' && d.forcedType
        ) || docs.find(d => 
          d.type === 'original_lease'
        );
        
        // If no clear original lease, use the one with highest confidence
        const mainDoc = originalLease || docs[0];
        
        // Create a group with the main document
        const group: DocumentGroup = {
          id: crypto.randomUUID(),
          name: key,
          primaryDocument: mainDoc,
          relatedDocuments: docs
            .filter(d => d.id !== mainDoc.id)
            .map(d => ({
              ...d,
              relationship: 'amendment'
            })),
          confidence: mainDoc.confidence || 0.5
        };
        
        results.groups.push(group);
      }

      results.processingTime = Date.now() - startTime;

      await this.processLogger.log(
        `Analysis complete: ${results.groups.length} groups, ${results.unassignedDocuments.length} unassigned`,
        'success'
      );

      return results;
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error';
      await this.processLogger.log(
        `Analysis error: ${message}`,
        'error'
      );
      throw error;
    }
  }

  /**
   * Get relationship suggestions based on historical data
   */
  async getRelationshipSuggestions(): Promise<string[]> {
    try {
      const enhancement = await feedbackService.getEnhancedPrompt('document_relationships');
      
      // Extract relationship types from historical examples
      const relationships = enhancement.examples
        .flatMap(e => {
          try {
            const groups = JSON.parse(e.corrected);
            return groups.flatMap((g: DocumentGroup) => 
              g.relatedDocuments.map(d => d.relationship)
            );
          } catch {
            return [];
          }
        })
        .filter((rel, index, self) => self.indexOf(rel) === index);

      await this.processLogger.log(
        `LSM-o1 generated ${relationships.length} relationship suggestions`,
        'info'
      );

      return relationships;
    } catch (error) {
      await this.processLogger.log(
        `Error generating relationship suggestions: ${error}`,
        'error'
      );
      return [];
    }
  }
}

// Do not create singleton instance - service should be instantiated with user ID
