import { ProcessLogger } from '../process-logger';
import { feedbackService } from './feedback-service';
import { OpenAIRetry } from '../utils/openai-retry';

export interface ExtractionResult {
  text: string;
  confidence: number;
  processingTime: number;
  structuredData?: {
    sections: {
      title: string;
      content: string;
      pageNumber: number;
    }[];
    tables: {
      title?: string;
      data: string[][];
      pageNumber: number;
    }[];
  };
}

export class TextExtractionService {
  private processLogger: ProcessLogger;
  private openaiRetry: OpenAIRetry;
  private userId: string;

  constructor(_apiKey: string, userId: string) {
    this.userId = userId;
    this.processLogger = new ProcessLogger(userId);
    this.openaiRetry = new OpenAIRetry(userId);
  }

  /**
   * Get enhanced extraction prompt
   */
  private async getEnhancedPrompt(): Promise<string> {
    try {
      const enhancement = await feedbackService.getEnhancedPrompt('text_extraction');
      
      const prompt = `
        ${enhancement.basePrompt}
        
        LSP-o1 Extraction Guidelines:
        ${enhancement.warnings.join('\n')}
        
        Training Examples:
        ${enhancement.examples.map(e => 
          `Raw Text: ${e.original}\nStructured Output: ${e.corrected}`
        ).join('\n\n')}
        
        Extract and structure the following document text.
        Identify sections, tables, and key information.
        Preserve formatting and hierarchical relationships.
        Return JSON with sections and tables arrays.
      `;

      await this.processLogger.log(
        `LSP-o1 initialized with ${enhancement.examples.length} training examples`,
        'info'
      );

      return prompt.trim();
    } catch (error) {
      await this.processLogger.log(
        `Error initializing LSP-o1, using base configuration: ${error}`,
        'error'
      );
      return `Extract and structure the document text, identifying sections and tables.`;
    }
  }

  /**
   * Extract text from PDF content using LSP-o1
   */
  async extractText(pdfContent: string): Promise<ExtractionResult> {
    try {
      const startTime = Date.now();
      const systemPrompt = await this.getEnhancedPrompt();

      await this.processLogger.log(
        'LSP-o1 beginning text extraction',
        'info'
      );

      const response = await this.openaiRetry.createChatCompletion({
        model: 'openai/gpt-4o-2024-11-20',
        messages: [
          {
            role: 'system',
            content: systemPrompt
          },
          {
            role: 'user',
            content: pdfContent
          }
        ],
        maxTokens: 2000,
        temperature: 0.1,
        presence_penalty: 0,
        frequency_penalty: 0,
        response_format: { type: "json_object" }
      }, 'text-extraction');

      if (!('choices' in response)) {
        throw new Error('Invalid response format from OpenRouter');
      }

      const result = response.choices[0]?.message?.content || '';
      const processingTime = Date.now() - startTime;
      const confidence = response.choices[0]?.finish_reason === 'stop' ? 0.9 : 0.5;

      let structuredData;
      try {
        structuredData = JSON.parse(result);
      } catch {
        structuredData = {
          sections: [{
            title: 'Main Content',
            content: result,
            pageNumber: 1
          }],
          tables: []
        };
      }

      // Record extraction feedback
      await feedbackService.recordFeedback({
        documentId: 'temp-id', // This should be passed in
        fieldName: 'text_extraction',
        originalValue: pdfContent,
        correctedValue: JSON.stringify(structuredData),
        confidenceScore: confidence,
        feedbackType: 'confirmation',
        promptUsed: systemPrompt,
        modelVersion: 'LSP-o1',
        processingTime
      });

      await this.processLogger.log(
        `LSP-o1 successfully extracted ${structuredData.sections.length} sections and ${structuredData.tables.length} tables`,
        'success'
      );

      return {
        text: result,
        confidence,
        processingTime,
        structuredData
      };
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown LSP-o1 error';
      await this.processLogger.log(
        `LSP-o1 extraction error: ${message}`,
        'error'
      );
      throw error;
    }
  }

  /**
   * Extract text from multiple PDFs using LSM-o1
   */
  async extractBatch(documents: { id: string; content: string }[]): Promise<Record<string, ExtractionResult>> {
    const results: Record<string, ExtractionResult> = {};
    
    await this.processLogger.log(
      `LSM-o1 beginning batch extraction of ${documents.length} documents`,
      'info'
    );

    for (const doc of documents) {
      try {
        results[doc.id] = await this.extractText(doc.content);
      } catch (error) {
        await this.processLogger.log(
          `LSM-o1 failed to extract document ${doc.id}: ${error}`,
          'error'
        );
        results[doc.id] = {
          text: '',
          confidence: 0,
          processingTime: 0,
          error: error instanceof Error ? error.message : 'Unknown LSM-o1 error'
        } as ExtractionResult;
      }
    }

    return results;
  }
}

// Do not create singleton instance - service should be instantiated with user ID
