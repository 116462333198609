import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { FileText, Plus, History, Search, Building2, AlertTriangle } from 'lucide-react';
import { format } from 'date-fns';
import { toast } from 'sonner';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/contexts/AuthContext';
import { VersionHistory } from '@/components/abstraction/VersionHistory';
import type { LeaseAbstraction } from '@/types/lease';

export default function Abstractions() {
  const [abstractions, setAbstractions] = useState<LeaseAbstraction[]>([]);
  const [versions, setVersions] = useState<Record<string, LeaseAbstraction[]>>({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchAbstractions();
    }
  }, [user]);

  async function fetchAbstractions() {
    try {
      // Get original abstractions and their amendments
      const { data, error } = await supabase
        .from('lease_abstractions')
        .select('*')
        .filter('type', 'eq', 'original')
        .order('updated_at', { ascending: false });

      if (error) throw error;

      const formattedData = data.map((doc: any) => ({
        ...doc,
        createdAt: new Date(doc.created_at),
        updatedAt: new Date(doc.updated_at),
      }));

      setAbstractions(formattedData);
    } catch (error) {
      console.error('Failed to load abstractions:', error);
      toast.error('Failed to load abstractions');
    } finally {
      setLoading(false);
    }
  }

  async function fetchVersions(abstractionId: string) {
    if (versions[abstractionId]) return;

    try {
      const { data, error } = await supabase
        .from('lease_abstractions')
        .select('*')
        .filter('parent_id', 'eq', abstractionId)
        .order('version', { ascending: false });

      if (error) throw error;

      const formattedVersions = data.map((version: any) => ({
        ...version,
        createdAt: new Date(version.created_at),
        updatedAt: new Date(version.updated_at),
      }));

      setVersions(prev => ({
        ...prev,
        [abstractionId]: formattedVersions,
      }));
    } catch (error) {
      console.error('Failed to load version history:', error);
      toast.error('Failed to load version history');
    }
  }

  const filteredAbstractions = abstractions.filter(abstraction => {
    const searchLower = searchTerm.toLowerCase();
    return (
      abstraction.premises?.toLowerCase().includes(searchLower) ||
      abstraction.tenantLegalName?.toLowerCase().includes(searchLower) ||
      abstraction.county?.toLowerCase().includes(searchLower)
    );
  });

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'draft':
        return 'bg-yellow-500/10 text-yellow-500';
      case 'completed':
        return 'bg-green-500/10 text-green-500';
      case 'archived':
        return 'bg-gray-500/10 text-gray-500';
      default:
        return 'bg-gray-500/10 text-gray-500';
    }
  };

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Lease Abstractions</h1>
          <p className="text-muted-foreground">
            View and manage your lease abstractions
          </p>
        </div>
        <Button onClick={() => navigate('/upload')}>
          <Plus className="mr-2 h-4 w-4" />
          New Abstraction
        </Button>
      </div>

      <div className="flex items-center space-x-2">
        <div className="relative flex-1">
          <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search abstractions..."
            value={searchTerm}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
            className="pl-8"
          />
        </div>
      </div>

      {loading ? (
        <Card>
          <CardContent className="flex items-center justify-center py-12">
            <div className="flex items-center space-x-2">
              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-primary"></div>
              <span className="text-muted-foreground">Loading abstractions...</span>
            </div>
          </CardContent>
        </Card>
      ) : filteredAbstractions.length === 0 ? (
        <Card>
          <CardContent className="flex flex-col items-center justify-center py-12">
            <Building2 className="h-12 w-12 text-muted-foreground mb-4" />
            <h3 className="text-lg font-semibold mb-2">No abstractions found</h3>
            <p className="text-muted-foreground text-center mb-4">
              {searchTerm ? 'Try adjusting your search terms' : 'Upload a lease document to create your first abstraction'}
            </p>
            {!searchTerm && (
              <Button onClick={() => navigate('/upload')}>
                <Plus className="mr-2 h-4 w-4" />
                Upload Document
              </Button>
            )}
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <CardTitle>All Abstractions</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Premises</TableHead>
                  <TableHead>Tenant</TableHead>
                  <TableHead>County</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Last Updated</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredAbstractions.map((abstraction) => (
                  <TableRow 
                    key={abstraction.id}
                    className="cursor-pointer hover:bg-muted/50"
                    onClick={() => navigate(`/abstraction/${abstraction.id}`)}
                  >
                    <TableCell className="font-medium">
                      {abstraction.premises || 'Untitled'}
                    </TableCell>
                    <TableCell>{abstraction.tenantLegalName || '-'}</TableCell>
                    <TableCell>{abstraction.county || '-'}</TableCell>
                    <TableCell>
                      <Badge variant="secondary" className={getStatusColor(abstraction.status)}>
                        {abstraction.status}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      {format(abstraction.updatedAt, 'MMM d, yyyy')}
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center gap-2">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/abstraction/${abstraction.id}`);
                          }}
                        >
                          <FileText className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            fetchVersions(abstraction.id);
                          }}
                        >
                          <History className="h-4 w-4" />
                        </Button>
                        {versions[abstraction.id] && (
                          <VersionHistory
                            versions={versions[abstraction.id]}
                            currentVersionId={null}
                            onVersionSelect={(versionId) => {
                              navigate(`/abstraction/${abstraction.id}?version=${versionId}`);
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
