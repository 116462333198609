import { parseDate } from '../dates';
import { withTimeout } from '../utils/timeout';
import { ProcessingError } from './processors/errors';
import { chatCompletion } from './openrouter';

export interface ExtractedDates {
  commencementDate: string | null;
  originalCommencementDate: string | null;
  expirationDate: string | null;
  rentStartDate: string | null;
  confidence: {
    commencementDate: number;
    originalCommencementDate: number;
    expirationDate: number;
    rentStartDate: number;
  };
}

const DATE_EXTRACTION_PROMPT = `
You are a specialized date extraction system. Your task is to find and extract specific dates from lease documents.
Focus ONLY on finding these dates:
- Commencement Date (when the lease term begins)
- Original Commencement Date (only for amendments, if different from commencement date)
- Expiration Date (when the lease term ends)
- Rent Start Date (when rent payments begin, if different from commencement date)

Guidelines:
1. Look for explicit date mentions and contextual clues
2. Pay special attention to:
   - "Term Commencement Date", "Lease Commencement", "Effective Date"
   - "Term", "Lease Term", "Term of Lease" sections
   - "Base Rent Commencement Date", "Rent Commencement"
   - "Expiration Date", "Term Expiration", "Termination Date"
3. For each date found, assign confidence scores (0-1) based on:
   - 1.0: Explicit date with clear label (e.g. "Commencement Date: January 1, 2024")
   - 0.8: Date with contextual label (e.g. "The lease term begins January 1, 2024")
   - 0.6: Date with indirect reference (e.g. "Tenant shall take possession on January 1, 2024")
   - 0.4: Inferred date from context
   - 0.0: No date found
4. Return dates in YYYY-MM-DD format
5. Return null if a date is not found or unclear
6. Look for both written dates ("January 1st, 2024") and numeric formats
7. For original leases:
   - Do not set Original Commencement Date (it should match Commencement Date)
   - Set Rent Start Date only if explicitly different from Commencement Date

Return the results in this exact JSON format:
{
  "commencementDate": "YYYY-MM-DD" | null,
  "originalCommencementDate": "YYYY-MM-DD" | null,
  "expirationDate": "YYYY-MM-DD" | null,
  "rentStartDate": "YYYY-MM-DD" | null,
  "confidence": {
    "commencementDate": number,
    "originalCommencementDate": number,
    "expirationDate": number,
    "rentStartDate": number
  }
}`;

function validateAndFormatDate(dateStr: string | null): string | null {
  if (!dateStr) return null;
  
  const parsedDate = parseDate(dateStr);
  if (!parsedDate) return null;
  
  return parsedDate.toISOString().split('T')[0]; // Return YYYY-MM-DD format
}

function processExtractedDates(rawDates: any): ExtractedDates {
  return {
    commencementDate: validateAndFormatDate(rawDates.commencementDate),
    originalCommencementDate: validateAndFormatDate(rawDates.originalCommencementDate),
    expirationDate: validateAndFormatDate(rawDates.expirationDate),
    rentStartDate: validateAndFormatDate(rawDates.rentStartDate),
    confidence: {
      commencementDate: Number(rawDates.confidence?.commencementDate) || 0,
      originalCommencementDate: Number(rawDates.confidence?.originalCommencementDate) || 0,
      expirationDate: Number(rawDates.confidence?.expirationDate) || 0,
      rentStartDate: Number(rawDates.confidence?.rentStartDate) || 0
    }
  };
}

async function extractDatesWithRetry(
  text: string,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void
): Promise<ExtractedDates> {
  const maxRetries = 4;
  let lastError: Error | null = null;
  let progressivelyReducedText = text;
  let fallbackMode = false;

  const preprocessText = (text: string): string[] => {
    const paragraphs = text.split(/\n{2,}/).map(p => p.trim()).filter(Boolean);
    
    const datePatterns = [
      /\b(date|commence|expire|term)\b/i,
      /\b(begin|end|period|duration|effective)\b/i,
      /\b(start|terminat|execut)\b/i,
      /\d{1,2}[-/]\d{1,2}[-/]\d{2,4}/,
      /\b(january|february|march|april|may|june|july|august|september|october|november|december)\b/i
    ];

    return paragraphs.filter(p => 
      datePatterns.some(pattern => pattern.test(p)) ||
      (p.length < 200 && /\b(day|year|month|dated|as of)\b/i.test(p))
    );
  };

  const chunkText = (text: string, attempt: number): string => {
    const relevantParagraphs = preprocessText(text);
    
    const scoredParagraphs = relevantParagraphs.map(p => ({
      text: p,
      score: (
        (p.match(/\d{1,2}[-/]\d{1,2}[-/]\d{2,4}/g)?.length || 0) * 3 +
        (p.match(/\b(january|february|march|april|may|june|july|august|september|october|november|december)\b/gi)?.length || 0) * 2 +
        (p.match(/\b(date|commence|expire|term|begin|end|effective)\b/gi)?.length || 0)
      )
    }));

    scoredParagraphs.sort((a, b) => b.score - a.score);
    
    const maxParagraphs = Math.max(3, 10 - (attempt * 2));
    const selectedParagraphs = scoredParagraphs.slice(0, maxParagraphs);

    return selectedParagraphs
      .map(p => p.text)
      .join('\n\nCONTEXT BREAK\n\n')
      .slice(0, 4000);
  };

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      if (attempt > 2 && !fallbackMode) {
        fallbackMode = true;
        progressivelyReducedText = text.match(/[^.]*\b\d{1,2}[-/]\d{1,2}[-/]\d{2,4}\b[^.]*/g)?.join('. ') || '';
        addLog('Switching to fallback mode - searching for explicit dates only', 'info');
      }

      const processedText = chunkText(progressivelyReducedText, attempt);
      addLog(`Processing attempt ${attempt} with ${processedText.length} characters (${fallbackMode ? 'fallback mode' : 'normal mode'})`, 'info');

      const response = await chatCompletion([
        { role: "system", content: DATE_EXTRACTION_PROMPT },
        { role: "user", content: processedText }
      ], {
        model: 'openai/gpt-4o-2024-11-20',
        maxTokens: 4000
      });

      const content = response?.choices?.[0]?.message?.content;
      if (!content) {
        throw new Error('Empty response from OpenRouter');
      }

      let parsedResponse;
      try {
        // Handle both string and object responses
        parsedResponse = typeof content === 'string' ? JSON.parse(content) : content;
        
        // Validate the response structure
        if (!parsedResponse || typeof parsedResponse !== 'object') {
          throw new Error('Invalid response format: not an object');
        }

        if (!parsedResponse || typeof parsedResponse !== 'object') {
          throw new Error('Invalid response format: not an object');
        }

        const expectedProps = ['commencementDate', 'originalCommencementDate', 'expirationDate', 'rentStartDate', 'confidence'];
        const missingProps = expectedProps.filter(prop => !(prop in parsedResponse));
        if (missingProps.length > 0) {
          throw new Error(`Invalid response format: missing properties ${missingProps.join(', ')}`);
        }

        return parsedResponse as ExtractedDates;
      } catch (parseError) {
        throw new Error(`Failed to parse response: ${parseError.message}`);
      }
    } catch (error) {
      lastError = error instanceof Error ? error : new Error('Unknown error');
      
      if (attempt < maxRetries) {
        const backoff = Math.min(1000 * Math.pow(2, attempt) + Math.random() * 2000, 30000);
        addLog(`Attempt ${attempt} failed (${lastError.message}), retrying in ${Math.round(backoff/1000)}s...`, 'info');
        await new Promise(resolve => setTimeout(resolve, backoff));
        
        progressivelyReducedText = progressivelyReducedText.slice(0, Math.floor(progressivelyReducedText.length * 0.8));
      } else {
        addLog(`All retry attempts failed: ${lastError.message}`, 'error');
      }
    }
  }
  throw lastError;
}

export async function extractDates(
  text: string,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void
): Promise<ExtractedDates> {
  try {
    addLog('Starting specialized date extraction...');

    const result = await withTimeout(
      extractDatesWithRetry(text, addLog),
      90000,
      'Date extraction timeout'
    );

    if (!result || typeof result !== 'object') {
      throw new Error('Invalid result format from date extraction');
    }

    const dates = result as ExtractedDates;

    Object.entries(dates).forEach(([key, value]) => {
      if (key !== 'confidence' && value) {
        addLog(`Found ${key}: ${value} (Confidence: ${dates.confidence[key as keyof typeof dates.confidence].toFixed(2)})`, 'success');
      }
    });

    return dates;
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    addLog('Error during date extraction: ' + errorMessage, 'error');
    throw ProcessingError.retryable('Date extraction failed: ' + errorMessage);
  }
}
