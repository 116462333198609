import { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { FormField } from '@/components/ui/FormField';
import { Button } from '@/components/ui/button';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { toTitleCase } from '@/lib/utils';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import type { FieldStatus } from '@/types/lease';

interface ClausesSectionProps {
  title: string;
  clauses: Record<string, string>;
  fieldStatus?: Record<string, FieldStatus>;
  onChange: (field: string, value: string) => void;
  onFieldStatusChange: (field: string, status: FieldStatus) => void;
}

export function ClausesSection({
  title,
  clauses = {},
  fieldStatus = {},
  onChange,
  onFieldStatusChange,
}: ClausesSectionProps) {
  const [openItems, setOpenItems] = useState<string[]>([]);
  const allClauseKeys = Object.keys(clauses);

  const toggleAll = () => {
    if (openItems.length === allClauseKeys.length) {
      // If all are open, close all
      setOpenItems([]);
    } else {
      // Otherwise, open all
      setOpenItems(allClauseKeys);
    }
  };

  const handleItemToggle = (value: string) => {
    setOpenItems(current => 
      current.includes(value)
        ? current.filter(item => item !== value)
        : [...current, value]
    );
  };

  const getFieldPath = (key: string) => {
    const section = title === 'Additional Clauses' ? 'additionalClauses' : 'clauses';
    return `${section}.${key}`;
  };

  const getInitialFieldStatus = (value: string) => {
    if (!value || value.trim() === '') return 'missing';
    
    const normalizedValue = value.toLowerCase().trim();
    
    // Check for common patterns indicating missing or not found clauses
    const missingPatterns = [
      'n/a',
      'not ',
      'no ',
      'none',
      'missing',
      'not found',
      'not mentioned',
      'not included',
      'not specified',
      'not addressed',
      'not explicitly'
    ];

    return missingPatterns.some(pattern => normalizedValue.includes(pattern)) 
      ? 'missing' 
      : 'not_missing';
  };

  const isAllOpen = openItems.length === allClauseKeys.length;

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>{title}</CardTitle>
        <Button
          variant="ghost"
          size="sm"
          onClick={toggleAll}
          className="flex items-center gap-2"
        >
          {isAllOpen ? (
            <>
              <ChevronUp className="h-4 w-4" />
              Collapse All
            </>
          ) : (
            <>
              <ChevronDown className="h-4 w-4" />
              Expand All
            </>
          )}
        </Button>
      </CardHeader>
      <CardContent>
        <Accordion
          type="multiple"
          value={openItems}
          onValueChange={setOpenItems}
          className="w-full"
        >
          {Object.entries(clauses || {}).map(([key, value]) => {
            const fieldPath = getFieldPath(key);
            const status = fieldStatus[fieldPath] || getInitialFieldStatus(value);
            
            return (
              <AccordionItem key={key} value={key}>
                <AccordionTrigger onClick={() => handleItemToggle(key)}>
                  {toTitleCase(key)}
                </AccordionTrigger>
                <AccordionContent>
                  <div className="space-y-2 pt-4">
                    <FormField
                      id={key}
                      label={`${toTitleCase(key)} Details`}
                      value={value}
                      onChange={(newValue) => onChange(key, newValue)}
                      type="textarea"
                      placeholder={`Enter ${key.toLowerCase()} details`}
                      fieldStatus={status}
                      onFieldStatusChange={(newStatus) => onFieldStatusChange(fieldPath, newStatus)}
                    />
                  </div>
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </CardContent>
    </Card>
  );
}
