import { ProcessingError } from '../errors';
import { parseDate } from './date-utils';
import type { RawLeaseData } from '../../types';

interface FinancialField {
  value: number | null;
  confidence: number;
  source: string;
}

const MAX_MERGE_DEPTH = 10; // Prevent infinite recursion

/**
 * Sanitizes clause content by removing repeated section references
 * @param content The clause content to sanitize
 * @returns Sanitized clause content
 */
function sanitizeClauseContent(content: string): string {
  if (!content || typeof content !== 'string') return '';
  return content
    .replace(/\(Section \d+:.*?\)\s*(?=\(Section|\s|$)/g, '')
    .trim();
}

/**
 * Deep merges two objects, handling nested objects properly
 * @param target The target object to merge into (original lease data)
 * @param source The source object to merge from (amendment data)
 * @param path Current path for nested objects
 * @param isAmendment Whether this is an amendment merge (affects null handling)
 * @param depth Current recursion depth
 * @returns The merged object
 */
export function deepMergeObjects(
  target: any,
  source: any,
  path: string = '',
  isAmendment: boolean = false,
  depth: number = 0
): any {
  // Prevent infinite recursion
  if (depth > MAX_MERGE_DEPTH) {
    console.warn(`Maximum merge depth exceeded at path: ${path}`);
    return target;
  }

  // Handle null/undefined cases
  if (!target) return source;
  if (!source) return target;
  if (typeof source !== 'object' || typeof target !== 'object') return source;

  const merged = { ...target };

  try {
    Object.entries(source).forEach(([key, sourceValue]) => {
      const currentPath = path ? `${path}.${key}` : key;

      // Special handling for amendments
      if (isAmendment) {
        // Skip null/undefined values in amendments unless they're explicit nullifications
        if (sourceValue === null || sourceValue === undefined) {
          // Only nullify if the amendment explicitly sets to null
          if (source.hasOwnProperty(key) && sourceValue === null) {
            merged[key] = null;
          }
          return;
        }
      } else {
        // For non-amendments, preserve null values as they might be intentional
        if (sourceValue === null || sourceValue === undefined) {
          merged[key] = sourceValue;
          return;
        }
      }

      // Handle nested objects
      if (
        sourceValue &&
        typeof sourceValue === 'object' &&
        !Array.isArray(sourceValue)
      ) {
        // Special handling for clauses
        if (key === 'clauses' || key === 'additionalClauses') {
          const mergedClauses = deepMergeObjects(
            merged[key] || {}, 
            sourceValue, 
            currentPath, 
            isAmendment,
            depth + 1
          );
          // Sanitize all clause content
          Object.entries(mergedClauses).forEach(([clauseKey, clauseValue]) => {
            if (typeof clauseValue === 'string') {
              mergedClauses[clauseKey] = sanitizeClauseContent(clauseValue);
            }
          });
          merged[key] = mergedClauses;
        }
        // Special handling for landlord and tenant
        else if (key === 'landlord' || key === 'tenant') {
          merged[key] = deepMergeObjects(
            merged[key] || {}, 
            sourceValue, 
            currentPath, 
            isAmendment,
            depth + 1
          );
        }
        // Handle financial objects (baseRent, annualRent, securityDeposit)
        else if (
          typeof (sourceValue as FinancialField).value !== 'undefined' && 
          typeof (sourceValue as FinancialField).confidence === 'number'
        ) {
          const financialSource = sourceValue as FinancialField;
          const financialTarget = merged[key] as FinancialField | undefined;

          // For amendments, only update if confidence is higher or original is missing
          if (!isAmendment || 
              !financialTarget || 
              financialSource.confidence > (financialTarget.confidence || 0)) {
            merged[key] = sourceValue;
          }
        }
        // Other nested objects
        else {
          merged[key] = deepMergeObjects(
            merged[key] || {}, 
            sourceValue, 
            currentPath, 
            isAmendment,
            depth + 1
          );
        }
      } else {
        // For non-objects in amendments, only update if value is different
        if (!isAmendment || merged[key] !== sourceValue) {
          merged[key] = sourceValue;
        }
      }
    });

    return merged;
  } catch (error) {
    console.error('Error in deepMergeObjects:', error);
    throw ProcessingError.document(
      `Failed to merge objects at path: ${path}`,
      error as Error
    );
  }
}

/**
 * Merges responses from multiple LLM models with timeout
 * @param gpt4Response The GPT-4 model response
 * @param llamaResponse The Llama model response
 * @returns The merged response
 */
export function mergeResponses(
  gpt4Response: RawLeaseData, 
  llamaResponse: RawLeaseData
): RawLeaseData {
  if (!gpt4Response && !llamaResponse) {
    throw ProcessingError.llm('Both SAGE and LSX responses are null or undefined');
  }

  if (!gpt4Response) return llamaResponse;
  if (!llamaResponse) return gpt4Response;

  try {
    // Merge financial fields
    const financialFields = ['baseRent', 'annualRent', 'securityDeposit'] as const;
    const mergedFinancials: Partial<RawLeaseData> = {};

    financialFields.forEach(field => {
      const gpt4Field = gpt4Response[field] as FinancialField | undefined;
      const llamaField = llamaResponse[field] as FinancialField | undefined;

      if (gpt4Field && llamaField) {
        // Ensure confidence values exist and are numbers
        const gpt4Confidence = typeof gpt4Field.confidence === 'number' ? gpt4Field.confidence : 0;
        const llamaConfidence = typeof llamaField.confidence === 'number' ? llamaField.confidence : 0;

        mergedFinancials[field] = {
          value: gpt4Confidence >= llamaConfidence ? gpt4Field.value : llamaField.value,
          confidence: Math.max(gpt4Confidence, llamaConfidence),
          source: gpt4Confidence >= llamaConfidence ? gpt4Field.source : llamaField.source
        };
      } else {
        mergedFinancials[field] = gpt4Field || llamaField;
      }
    });

    // Merge date fields
    const dateFields = ['commencementDate', 'originalCommencementDate', 'expirationDate', 'rentStartDate'] as const;
    const mergedDates: Partial<RawLeaseData> = {};

    dateFields.forEach(field => {
      const gpt4Date = gpt4Response[field];
      const llamaDate = llamaResponse[field];

      if (gpt4Date && llamaDate) {
        // Parse both dates and use the valid one, or the first valid one if both are valid
        const gpt4Parsed = parseDate(gpt4Date);
        const llamaParsed = parseDate(llamaDate);

        if (gpt4Parsed && llamaParsed) {
          mergedDates[field] = gpt4Date; // Prefer SAGE if both are valid
        } else {
          mergedDates[field] = gpt4Parsed ? gpt4Date : llamaDate;
        }
      } else {
        mergedDates[field] = gpt4Date || llamaDate;
      }
    });

    // Merge everything else using deep merge
    const baseData = {
      ...gpt4Response,
      ...mergedDates,
      ...mergedFinancials
    };

    // Ensure we're not losing any fields from either response
    return deepMergeObjects(baseData, llamaResponse) as RawLeaseData;
  } catch (error) {
    console.error('Error merging responses:', error);
    // Return GPT-4 response as fallback if merge fails
    console.warn('Falling back to SAGE response due to merge error');
    return gpt4Response;
  }
}
