import { supabase } from '../../supabase';
import { convertToCamelCase } from '../utils/case-conversion';
import { ProcessingError } from '../../llm/processors/errors';
import type { LeaseAbstractionWithVersions } from '../../llm/types';

interface LoadVersionsResult {
  data: LeaseAbstractionWithVersions | null;
  error: string | null;
}

export async function getAbstractionWithVersions(id: string): Promise<LoadVersionsResult> {
  try {
    if (!id) {
      throw ProcessingError.validation([{
        field: 'id',
        message: 'Abstraction ID is required'
      }]);
    }

    // Get current session to ensure we're authenticated
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    if (sessionError || !session) {
      throw ProcessingError.validation([{
        field: 'auth',
        message: 'You must be logged in to access versions'
      }]);
    }

    // First, get the target version to determine if it's an original or amendment
    const { data: targetVersions, error: targetError } = await supabase
      .from('lease_abstractions')
      .select('*')
      .eq('id', id);

    if (targetError) {
      console.error('Target version fetch error:', targetError);
      // Check if it's a permissions error
      if (targetError.code === 'PGRST301') {
        throw ProcessingError.database('You do not have permission to access this abstraction');
      }
      throw ProcessingError.database('Failed to fetch target version', targetError);
    }

    if (!targetVersions || targetVersions.length === 0) {
      throw ProcessingError.database(`Version not found: ${id}`);
    }

    const targetVersion = targetVersions[0];

    // Determine the original lease ID
    const originalId = targetVersion.type === 'original' ? id : targetVersion.parent_id;
    if (!originalId) {
      throw ProcessingError.database(`Parent ID not found for amendment: ${id}`);
    }

    // Get the original lease
    const { data: originalLease, error: originalError } = await supabase
      .from('lease_abstractions')
      .select('*')
      .eq('id', originalId)
      .single();

    if (originalError) {
      console.error('Original lease fetch error:', originalError);
      throw ProcessingError.database('Failed to fetch original lease', originalError);
    }

    if (!originalLease) {
      throw ProcessingError.database(`Original lease not found for ID: ${originalId}`);
    }

    // Get all amendments
    const { data: amendments, error: amendmentsError } = await supabase
      .from('lease_abstractions')
      .select('*')
      .eq('parent_id', originalId)
      .eq('type', 'amendment')
      .order('version', { ascending: true });

    if (amendmentsError) {
      console.error('Amendments fetch error:', amendmentsError);
      throw ProcessingError.database('Failed to fetch amendments', amendmentsError);
    }

    // Convert all versions to camelCase and sort by effective date and version
    const sortedVersions = [originalLease, ...(amendments || [])]
      .filter(Boolean)
      .map(convertToCamelCase)
      .sort((a, b) => {
        // Sort by version number first
        if (a.version !== b.version) return a.version - b.version;
        
        // If versions are equal, sort by effective date
        const dateA = a.effectiveDate ? new Date(a.effectiveDate).getTime() : 0;
        const dateB = b.effectiveDate ? new Date(b.effectiveDate).getTime() : 0;
        return dateA - dateB;
      });

    // Get the first version's commencement date and confidence
    const firstVersion = sortedVersions[0];
    const originalCommencementDate = firstVersion.commencementDate || firstVersion.rentStartDate;
    const originalCommencementConfidence = firstVersion.confidence?.commencementDate || 0;

    // Find the target version index
    const targetIndex = sortedVersions.findIndex(v => v.id === id);
    if (targetIndex === -1) {
      throw ProcessingError.database(`Selected version not found: ${id}`);
    }

    // Build cumulative state up to the target version
    const cumulativeState = sortedVersions
      .slice(0, targetIndex + 1)
      .reduce((acc, version) => {
        // Helper function to handle date fields specifically
        const handleDateField = (key: string, currentValue: any) => {
          // If the current version explicitly sets the date (even to null), use that
          if (currentValue !== undefined) {
            return {
              value: currentValue,
              source: version.id,
              confidence: version.confidence?.[key] || 0
            };
          }
          // Otherwise keep the accumulated value
          return acc[key] || {
            value: null,
            source: null,
            confidence: 0
          };
        };

        // Helper function to merge objects
        const mergeObjects = (base: any, update: any) => {
          if (!update) return base;
          return Object.entries(update).reduce((merged, [key, value]) => {
            // For clauses and additionalClauses, we want to preserve non-empty values
            if (key === 'clauses' || key === 'additionalClauses') {
              merged[key] = Object.entries(value || {}).reduce((clauses: any, [clauseKey, clauseValue]) => {
                // If the current version has a non-empty value, use it
                if (clauseValue && typeof clauseValue === 'string' && clauseValue.trim() !== '') {
                  clauses[clauseKey] = clauseValue;
                } else if (base?.[key]?.[clauseKey] && typeof base[key][clauseKey] === 'string' && base[key][clauseKey].trim() !== '') {
                  // Otherwise, keep the previous non-empty value
                  clauses[clauseKey] = base[key][clauseKey];
                } else {
                  // If both are empty, keep the empty value from the current version
                  clauses[clauseKey] = clauseValue || '';
                }
                return clauses;
              }, { ...base?.[key] });
            } else if (key === 'confidence') {
              // For confidence, keep the highest confidence score for each field
              merged[key] = {
                ...(base?.[key] || {}),
                ...(typeof value === 'object' ? value : {})
              };
            } else {
              // For all other fields, preserve non-empty values unless explicitly overwritten
              if (value !== undefined && value !== null && value !== '') {
                merged[key] = value;
              } else if (!(key in merged) && base?.[key] !== undefined && base?.[key] !== null && base?.[key] !== '') {
                // If key doesn't exist in merged yet and base has a non-empty value, use base value
                merged[key] = base[key];
              }
            }
            return merged;
          }, { ...base });
        };

        return {
          ...acc,
          // Version metadata (always from current version)
          id: version.id,
          type: version.type,
          version: version.version,
          status: version.status,
          isCurrentVersion: version.isCurrentVersion,
          parentId: version.parentId,
          userId: version.userId,
          documentPath: version.documentPath,
          documentChecksum: version.documentChecksum,
          documentContent: version.documentContent,
          changes: version.changes,
          createdAt: version.createdAt,
          updatedAt: version.updatedAt,

          // Handle dates with explicit tracking of source and confidence
          commencementDate: handleDateField('commencementDate', version.commencementDate),
          originalCommencementDate: handleDateField('originalCommencementDate', version.originalCommencementDate),
          expirationDate: handleDateField('expirationDate', version.expirationDate),
          rentStartDate: handleDateField('rentStartDate', version.rentStartDate),
          effectiveDate: handleDateField('effectiveDate', version.effectiveDate),

          // Handle all other fields - preserve non-empty values unless explicitly overwritten
          baseRent: version.baseRent !== undefined ? version.baseRent : acc.baseRent,
          annualRent: version.annualRent !== undefined ? version.annualRent : acc.annualRent,
          securityDeposit: version.securityDeposit !== undefined ? version.securityDeposit : acc.securityDeposit,
          rentableArea: version.rentableArea !== undefined ? version.rentableArea : acc.rentableArea,
          proRata: version.proRata !== undefined ? version.proRata : acc.proRata,
          buildingArea: version.buildingArea !== undefined ? version.buildingArea : acc.buildingArea,
          premises: version.premises || acc.premises,
          county: version.county || acc.county,
          tenantLegalName: version.tenantLegalName || acc.tenantLegalName,

          // Handle nested objects with proper inheritance
          landlord: mergeObjects(acc.landlord, version.landlord),
          tenant: mergeObjects(acc.tenant, version.tenant),
          clauses: mergeObjects(acc.clauses, version.clauses),
          additionalClauses: mergeObjects(acc.additionalClauses, version.additionalClauses),

          // Track which clauses are inherited vs. explicitly set in this version
          clauseInheritance: {
            ...acc.clauseInheritance,
            ...(version.clauses && Object.keys(version.clauses).reduce((inheritance: any, key) => {
              inheritance[key] = version.clauses[key]?.trim() !== '';
              return inheritance;
            }, {}))
          },

          // Merge confidence values
          confidence: mergeObjects(acc.confidence, version.confidence)
        };
      }, convertToCamelCase(originalLease));

    // Ensure field status is properly set in the final result
    const result: LeaseAbstractionWithVersions = {
      ...cumulativeState,
      versions: sortedVersions,
      // Extract actual date values from the tracked objects
      commencementDate: cumulativeState.commencementDate?.value,
      originalCommencementDate: cumulativeState.originalCommencementDate?.value,
      expirationDate: cumulativeState.expirationDate?.value,
      rentStartDate: cumulativeState.rentStartDate?.value,
      effectiveDate: cumulativeState.effectiveDate?.value,
      // Track confidence scores
      confidence: {
        ...cumulativeState.confidence,
        commencementDate: cumulativeState.commencementDate?.confidence || 0,
        originalCommencementDate: cumulativeState.originalCommencementDate?.confidence || 0,
        expirationDate: cumulativeState.expirationDate?.confidence || 0,
        rentStartDate: cumulativeState.rentStartDate?.confidence || 0,
        effectiveDate: cumulativeState.effectiveDate?.confidence || 0
      },
      // Track which version each date came from
      dateSourceVersions: {
        commencementDate: cumulativeState.commencementDate?.source,
        originalCommencementDate: cumulativeState.originalCommencementDate?.source,
        expirationDate: cumulativeState.expirationDate?.source,
        rentStartDate: cumulativeState.rentStartDate?.source,
        effectiveDate: cumulativeState.effectiveDate?.source
      },
      fieldStatus: {
        ...cumulativeState.fieldStatus,
        commencementDate: cumulativeState.commencementDate?.value ? 'not_missing' : 'missing',
        originalCommencementDate: cumulativeState.originalCommencementDate?.value ? 'not_missing' : 'missing',
        expirationDate: cumulativeState.expirationDate?.value ? 'not_missing' : 'missing',
        rentStartDate: cumulativeState.rentStartDate?.value ? 'not_missing' : 'missing',
        effectiveDate: cumulativeState.effectiveDate?.value ? 'not_missing' : 'missing'
      }
    };

    return {
      data: result,
      error: null
    };
  } catch (error) {
    console.error('Load abstraction with versions error:', error);

    if (error instanceof ProcessingError) {
      if (error.details) {
        // Format validation errors
        const validationErrors = error.details
          .map(err => `${err.field}: ${err.message}`)
          .join('\n');
        return { 
          data: null, 
          error: `Validation failed:\n${validationErrors}`
        };
      }
      return {
        data: null,
        error: error.message
      };
    }

    // Handle unexpected errors
    return { 
      data: null, 
      error: ProcessingError.database(
        'Failed to load abstraction versions',
        error as Error
      ).message
    };
  }
}
