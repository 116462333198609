import type { LLMResponse } from '../types';
import { withTimeout } from '../../../lib/utils/timeout';
import { ProcessingError } from './errors';
import { withRetry } from './retry';
import { 
  LEASE_ANALYSIS_PROMPT, 
  AMENDMENT_ANALYSIS_PROMPT, 
  BACKUP_ANALYSIS_PROMPT 
} from './prompts';
import { chatCompletion } from '../openrouter';

const BASE_TIMEOUT = 90000; // 1.5 minutes base timeout
const TIMEOUT_PER_CHAR = 0.05; // Additional ms per character (faster than Llama)
const MAX_TIMEOUT = 300000; // Maximum 5 minutes (faster than Llama)
const MAX_TOKENS = 8000;
const AVERAGE_CHARS_PER_TOKEN = 4;
const MAX_CHARS = MAX_TOKENS * AVERAGE_CHARS_PER_TOKEN;
const MIN_CHARS = 1000;

interface FinancialField {
  value: number | null;
  confidence?: number;
  source?: string;
}

function calculateTimeout(textLength: number): number {
  const calculatedTimeout = BASE_TIMEOUT + (textLength * TIMEOUT_PER_CHAR);
  return Math.min(calculatedTimeout, MAX_TIMEOUT);
}

function truncateText(text: string, attempt: number = 1): string {
  // Start with 40% reduction, increase by 15% each attempt
  const reductionFactor = Math.min(0.4 + (attempt - 1) * 0.15, 0.85);
  const maxLength = Math.floor(MAX_CHARS * (1 - reductionFactor));

  if (text.length <= maxLength) return text;
  
  // Try to find a good breakpoint at a paragraph
  const truncateIndex = text.lastIndexOf('\n\n', maxLength);
  if (truncateIndex > maxLength * 0.8) {
    console.log(`Truncating at paragraph break: ${truncateIndex} chars (attempt ${attempt})`);
    return text.slice(0, truncateIndex);
  }
  
  // Try to find a good breakpoint at a sentence
  const sentenceBreak = text.lastIndexOf('. ', maxLength);
  if (sentenceBreak > maxLength * 0.8) {
    console.log(`Truncating at sentence break: ${sentenceBreak} chars (attempt ${attempt})`);
    return text.slice(0, sentenceBreak + 1);
  }
  
  console.log(`Hard truncating at: ${maxLength} chars (attempt ${attempt})`);
  return text.slice(0, maxLength);
}

function validateResponse(data: any): void {
  if (!data) {
    throw ProcessingError.llm('Empty response from LSX-1');
  }

  if (typeof data !== 'object') {
    throw ProcessingError.llm('Invalid response format from LSX-1');
  }

  const requiredFields = ['commencementDate', 'expirationDate'];
  const missingFields = requiredFields.filter(field => !(field in data));
  
  if (missingFields.length > 0) {
    throw ProcessingError.llm(`Missing required fields in LSX-1 response: ${missingFields.join(', ')}`);
  }
}

function calculateConfidence(rawData: any): Record<string, number> {
  const confidence: Record<string, number> = {};
  
  try {
    Object.entries(rawData).forEach(([key, value]) => {
      if (key === 'missingFields') return;
      
      // Handle financial fields
      if (['baseRent', 'annualRent', 'securityDeposit'].includes(key)) {
        const financialField = value as FinancialField | undefined;
        if (!financialField || typeof financialField !== 'object') {
          confidence[key] = 0;
        } else {
          // Safely access confidence with a default of 0
          confidence[key] = typeof financialField.confidence === 'number' ? financialField.confidence : 0;
          // Update the raw data to use just the value
          rawData[key] = financialField.value;
        }
      }
      // Handle other fields
      else if (value === null || value === undefined) {
        confidence[key] = 0;
      } else if (typeof value === 'object') {
        const subFields = Object.values(value as Record<string, any>);
        const validFields = subFields.filter(v => v !== null && v !== undefined && v !== '');
        confidence[key] = validFields.length / subFields.length;
      } else {
        confidence[key] = value === '' ? 0 : 1;
      }
    });
    
    return confidence;
  } catch (error) {
    console.error('Error calculating confidence:', error);
    // Return empty confidence object rather than throwing
    return {};
  }
}

export async function processWithGPT4(
  text: string,
  isAmendment: boolean,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void,
  isBackup = false
): Promise<LLMResponse> {
  if (!text?.trim()) {
    throw ProcessingError.llm('No text provided for LSX-1 processing');
  }

  let currentAttempt = 1;
  let truncatedText = truncateText(text, currentAttempt);
  const truncatedPercent = Math.round((text.length - truncatedText.length) / text.length * 100);

  if (truncatedText.length < text.length) {
    addLog(`Warning: Document text was truncated by ${truncatedPercent}% to fit within LSX-1 context window`, 'info');
    console.log(`Original text length: ${text.length}, truncated to: ${truncatedText.length} (attempt ${currentAttempt})`);
  }

  if (truncatedText.length < MIN_CHARS) {
    throw ProcessingError.llm(`Document text truncated too much (${truncatedText.length} chars). Minimum required: ${MIN_CHARS} chars`);
  }

  // Calculate timeout based on text length
  const timeout = calculateTimeout(truncatedText.length);
  addLog(`Setting timeout to ${Math.round(timeout/1000)} seconds based on document length`, 'info');

  return withRetry(
    async () => {
      try {
        addLog(`Using ${isAmendment ? 'amendment' : 'lease'} analysis prompt...`);
        
        const completion = await withTimeout(
          chatCompletion([
            {
              role: "system",
              content: isAmendment ? AMENDMENT_ANALYSIS_PROMPT : 
                       isBackup ? BACKUP_ANALYSIS_PROMPT : 
                       LEASE_ANALYSIS_PROMPT
            },
            {
              role: "user",
              content: truncatedText
            }
          ], {
            maxTokens: 4000,
            response_format: { type: "json_object" }
          }),
          timeout,
          'LSX-1 processing timeout'
        );

        if (!completion.choices?.[0]?.message?.content) {
          throw ProcessingError.llm('Empty response from LSX-1');
        }

        let rawData;
        try {
          // Remove Markdown code block syntax if present
          const content = completion.choices[0].message.content
            .replace(/^```json\s*/, '')
            .replace(/\s*```$/, '');
          rawData = JSON.parse(content);
        } catch (error) {
          console.error('Raw response:', completion.choices[0].message.content);
          throw ProcessingError.llm('Failed to parse LSX-1 response as JSON', error as Error);
        }

        validateResponse(rawData);

        const confidence = calculateConfidence(rawData);

        // Log financial field confidence scores
        ['baseRent', 'annualRent', 'securityDeposit'].forEach(field => {
          const financialField = rawData[field] as FinancialField | undefined;
          if (financialField && typeof financialField.confidence === 'number') {
            addLog(`${field}: ${financialField.confidence.toFixed(2)} confidence${financialField.source ? ` (${financialField.source})` : ''}`, 'info');
          }
        });

        addLog('LSX-1 processing completed successfully', 'success');
        return {
          data: rawData,
          confidence,
          source: isBackup ? 'gpt4-backup' : 'gpt4'
        };
      } catch (error) {
        if (error instanceof ProcessingError) {
          throw error;
        }

        // Handle OpenRouter-specific errors
        if (error instanceof Error) {
          const errorMessage = error.message;
          
          if (errorMessage.includes('API key')) {
            throw ProcessingError.llm('Invalid LSX-1 API key. Please check your configuration.');
          }
          
          if (errorMessage.includes('Rate limit') || errorMessage.includes('429')) {
            throw ProcessingError.retryable(
              'Rate limit exceeded. Implementing exponential backoff...',
              error
            );
          }

          if (errorMessage.includes('timeout')) {
            currentAttempt++;
            truncatedText = truncateText(text, currentAttempt);
            const newTruncatedPercent = Math.round((text.length - truncatedText.length) / text.length * 100);
            
            if (truncatedText.length < MIN_CHARS) {
              throw ProcessingError.llm(`Document text truncated too much (${truncatedText.length} chars). Minimum required: ${MIN_CHARS} chars`);
            }

            addLog(`Reducing content size by ${newTruncatedPercent}% for next attempt`, 'info');
            throw ProcessingError.retryable(
              'LSX-1 processing timed out',
              error
            );
          }

          throw ProcessingError.retryable(
            `LSX-1 API error: ${errorMessage}`,
            error
          );
        }

        throw ProcessingError.llm(
          'LSX-1 processing failed',
          error as Error
        );
      }
    },
    {
      model: isBackup ? 'GPT-4 (Backup)' : 'GPT-4',
      addLog,
      maxRetries: 3,
      retryDelay: 1000,
      backoffFactor: 2
    }
  );
}
