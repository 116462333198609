import { supabase } from '../supabase';
import { ProcessLogger } from '../process-logger';

export interface FeedbackData {
  documentId: string;
  fieldName: string;
  originalValue: string | null;
  correctedValue: string | null;
  confidenceScore: number;
  feedbackType: 'correction' | 'confirmation' | 'rejection';
  promptUsed?: string;
  modelVersion?: string;
  contextWindow?: number;
  processingTime?: number;
}

export interface PromptEnhancement {
  fieldName: string;
  documentType?: string;
  basePrompt: string;
  examples: Array<{
    original: string;
    corrected: string;
    count: number;
  }>;
  warnings: string[];
}

export class FeedbackService {
  private processLogger: ProcessLogger;

  constructor(groupId?: string) {
    this.processLogger = new ProcessLogger(groupId || 'feedback-service');
  }

  /**
   * Update the process logger with a new instance
   */
  setLogger(logger: ProcessLogger) {
    this.processLogger = logger;
  }

  /**
   * Record user feedback for document processing
   */
  async recordFeedback(data: FeedbackData): Promise<string | null> {
    try {
      // Get current user session
      const { data: session } = await supabase.auth.getSession();
      if (!session?.session?.user) {
        await this.processLogger.log('User not authenticated, skipping feedback', 'info');
        return null;
      }

      // Validate confidence score
      if (typeof data.confidenceScore !== 'number' || isNaN(data.confidenceScore)) {
        await this.processLogger.log('Invalid confidence score, skipping feedback', 'info');
        return null;
      }

      const { data: result, error } = await supabase.rpc('record_document_feedback', {
        p_document_id: data.documentId,
        p_field_name: data.fieldName,
        p_original_value: data.originalValue,
        p_corrected_value: data.correctedValue,
        p_confidence_score: data.confidenceScore,
        p_feedback_type: data.feedbackType,
        p_prompt_used: data.promptUsed,
        p_model_version: data.modelVersion,
        p_context_window: data.contextWindow,
        p_processing_time: data.processingTime
      });

      if (error) {
        await this.processLogger.log(`Failed to record feedback: ${error.message}`, 'error');
        return null;
      }

      await this.processLogger.log(
        `Recorded ${data.feedbackType} feedback for ${data.fieldName}`,
        'success'
      );

      return result;
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error recording feedback';
      await this.processLogger.log(`Failed to record feedback: ${message}`, 'error');
      return null;
    }
  }

  /**
   * Get enhanced prompt for a specific field based on feedback history
   */
  async getEnhancedPrompt(fieldName: string, documentType?: string): Promise<PromptEnhancement> {
    try {
      // Get base prompt from database
      const { data: prompt, error } = await supabase.rpc('get_field_specific_prompt', {
        p_field_name: fieldName,
        p_document_type: documentType
      });

      if (error) throw error;

      // Get error patterns from feedback
      const { data: patterns, error: patternsError } = await supabase
        .from('document_feedback')
        .select('original_value, corrected_value')
        .eq('field_name', fieldName)
        .eq('feedback_type', 'correction')
        .order('created_at', { ascending: false })
        .limit(10);

      if (patternsError) throw patternsError;

      // Generate examples from patterns
      const examples = patterns.reduce((acc: any[], pattern) => {
        if (pattern.original_value && pattern.corrected_value) {
          const existing = acc.find(e => 
            e.original === pattern.original_value && 
            e.corrected === pattern.corrected_value
          );
          if (existing) {
            existing.count++;
          } else {
            acc.push({
              original: pattern.original_value,
              corrected: pattern.corrected_value,
              count: 1
            });
          }
        }
        return acc;
      }, []);

      // Generate warnings based on common errors
      const warnings = this.generateWarnings(patterns);

      return {
        fieldName,
        documentType,
        basePrompt: prompt,
        examples: examples.sort((a, b) => b.count - a.count).slice(0, 3),
        warnings
      };
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error getting enhanced prompt';
      await this.processLogger.log(`Failed to get enhanced prompt: ${message}`, 'error');
      throw error;
    }
  }

  /**
   * Get performance metrics for a specific field
   */
  async getFieldMetrics(fieldName: string) {
    try {
      const { data, error } = await supabase
        .from('feedback_analysis')
        .select('*')
        .eq('field_name', fieldName)
        .single();

      if (error) throw error;

      return {
        errorRate: data.error_rate,
        avgConfidence: data.avg_confidence,
        avgProcessingTime: data.avg_processing_time,
        totalFeedback: data.feedback_count
      };
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error getting field metrics';
      await this.processLogger.log(`Failed to get field metrics: ${message}`, 'error');
      throw error;
    }
  }

  /**
   * Generate fine-tuning dataset for OpenAI in JSONL format
   */
  async generateFineTuningData(fieldName: string) {
    try {
      interface DocumentFeedback {
        original_value: string;
        corrected_value: string;
        prompt_used: string | null;
        document_content: string;
      }

      const { data: feedback, error } = await supabase
        .from('document_feedback')
        .select(`
          original_value,
          corrected_value,
          prompt_used,
          document_content
        `)
        .eq('field_name', fieldName)
        .eq('feedback_type', 'correction')
        .order('created_at', { ascending: false })
        .limit(100);

      if (error) throw error;

      if (!feedback) return [];

      // Format data for OpenAI fine-tuning
      const trainingData = (feedback as DocumentFeedback[]).map(item => ({
        messages: [
          {
            role: "system",
            content: item.prompt_used || `Extract the ${fieldName} from the document.`
          },
          {
            role: "user",
            content: item.document_content
          },
          {
            role: "assistant",
            content: item.corrected_value
          }
        ]
      }));

      // Convert to JSONL format
      return trainingData.map(item => JSON.stringify(item)).join('\n');
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error generating fine-tuning data';
      await this.processLogger.log(`Failed to generate fine-tuning data: ${message}`, 'error');
      throw error;
    }
  }

  private generateWarnings(patterns: Array<{ original_value: string | null; corrected_value: string | null; }>): string[] {
    const warnings: string[] = [];

    // Analyze patterns for common issues
    const hasDateFormattingIssues = patterns.some(p => 
      p.original_value?.includes('/') && p.corrected_value?.includes('-')
    );
    if (hasDateFormattingIssues) {
      warnings.push('Pay attention to date formatting (use YYYY-MM-DD format)');
    }

    const hasCapitalizationIssues = patterns.some(p =>
      p.original_value?.toLowerCase() !== p.corrected_value?.toLowerCase()
    );
    if (hasCapitalizationIssues) {
      warnings.push('Check for proper capitalization in names and titles');
    }

    const hasNullValues = patterns.some(p => 
      p.original_value === null && p.corrected_value !== null
    );
    if (hasNullValues) {
      warnings.push('Look for implicit or contextual values that might be missed');
    }

    return warnings;
  }
}

export const feedbackService = new FeedbackService();
