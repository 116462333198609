// Default models to use (can be overridden)
export const DEFAULT_MODEL = 'openai/gpt-4o-2024-11-20';
export const DEFAULT_VISION_MODEL = 'x-ai/grok-2-vision-1212';
const MAX_RETRIES = 3;
const INITIAL_RETRY_DELAY = 2000; // 2 seconds

export type VisionContent = {
  type: 'text';
  text: string;
} | {
  type: 'image_url';
  image_url: {
    url: string;
  };
};

export interface ChatOptions {
  model?: string;
  systemMessage?: string;
  maxTokens?: number;
  response_format?: { type: string };
  isVision?: boolean;
}

async function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function makeRequest(url: string, options: RequestInit, retryCount = 0): Promise<Response> {
  try {
    const apiKey = import.meta.env.VITE_OPENROUTER_API_KEY;
    if (!apiKey) {
      throw new Error('OpenRouter API key not found. Please set VITE_OPENROUTER_API_KEY in your environment variables.');
    }

    const baseHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`,
      'HTTP-Referer': import.meta.env.VITE_APP_URL || 'http://localhost:3002',
      'X-Title': 'Lease Simplify AI'
    };

    const response = await fetch(url, {
      ...options,
      headers: {
        ...baseHeaders,
        ...(options.headers || {})
      }
    });

    if (response.status === 429) {
      if (retryCount < MAX_RETRIES) {
        // Exponential backoff with jitter
        const backoff = Math.min(INITIAL_RETRY_DELAY * Math.pow(2, retryCount), 10000);
        const jitter = Math.random() * 1000;
        await delay(backoff + jitter);
        return makeRequest(url, options, retryCount + 1);
      }
      throw new Error('Rate limit exceeded. Please try again later.');
    }

    if (!response.ok) {
      const errorText = await response.text();
      let errorMessage = `OpenRouter API error: ${response.statusText}`;
      try {
        const errorJson = JSON.parse(errorText);
        errorMessage = errorJson.error?.message || errorMessage;
      } catch {
        // If error text isn't JSON, use it directly
        errorMessage = errorText || errorMessage;
      }

      // Check for CORS errors
      if (response.type === 'opaque' || response.status === 0) {
        errorMessage = 'CORS error: Unable to access OpenRouter API. Please check your API key and domain settings.';
      }

      if (response.status >= 500 && retryCount < MAX_RETRIES) {
        await delay(INITIAL_RETRY_DELAY);
        return makeRequest(url, options, retryCount + 1);
      }
      throw new Error(errorMessage);
    }

    return response;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('Failed to connect to OpenRouter API');
  }
}

export interface VisionMessage {
  role: 'system' | 'user' | 'assistant';
  content: string | readonly VisionContent[];
}

export async function streamChat(
  messages: { role: 'system' | 'user' | 'assistant'; content: string }[],
  options: ChatOptions = {}
) {
  const {
    model = DEFAULT_MODEL,
    maxTokens = 2000,
  } = options;

  const response = await makeRequest(
    'https://openrouter.ai/api/v1/chat/completions',
    {
      method: 'POST',
      body: JSON.stringify({
        model,
        messages,
        stream: true,
        max_tokens: maxTokens,
        response_format: { type: "json_object" },
        mode: "json"
      })
    }
  );

  // Transform the response into a readable stream
  const stream = new ReadableStream({
    async start(controller) {
      const reader = response.body?.getReader();
      if (!reader) throw new Error('No response body');

      try {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const text = new TextDecoder().decode(value);
          const lines = text.split('\n');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const data = line.slice(6);
              if (data === '[DONE]') continue;

              try {
                const parsed = JSON.parse(data);
                const content = parsed.choices?.[0]?.delta?.content;
                if (content) {
                  controller.enqueue(new TextEncoder().encode(`data: ${JSON.stringify({ text: content })}\n\n`));
                }
              } catch (e) {
                console.warn('Error parsing streaming data:', e);
              }
            }
          }
        }

        controller.close();
      } catch (error) {
        controller.error(error);
        reader.cancel();
      }
    }
  });

  return new Response(stream, {
    headers: {
      'Content-Type': 'text/event-stream',
      'Cache-Control': 'no-cache',
      'Connection': 'keep-alive'
    }
  });
}

export async function chatCompletion(
  messages: VisionMessage[],
  options: ChatOptions = {}
) {
  const {
    model = options.isVision ? DEFAULT_VISION_MODEL : DEFAULT_MODEL,
    maxTokens = 2000,
    response_format = { type: "json_object" }
  } = options;

  const response = await makeRequest(
    'https://openrouter.ai/api/v1/chat/completions',
    {
      method: 'POST',
      body: JSON.stringify({
        model,
        messages,
        stream: false,
        max_tokens: maxTokens,
        response_format,
        mode: response_format.type === "json_object" ? "json" : undefined
      })
    }
  );

  const data = await response.json();
  
  if (response_format.type === "text") {
    const content = data.choices?.[0]?.message?.content;
    if (typeof content === 'string') {
      return {
        choices: [{
          message: {
            content: content.trim(),
            role: 'assistant'
          },
          finish_reason: data.choices?.[0]?.finish_reason || 'stop'
        }]
      };
    }
  }
  
  return data;
}

export async function visionChatCompletion(
  systemPrompt: string,
  imageData: string,
  userPrompt: string,
  options: Omit<ChatOptions, 'isVision'> = {}
) {
  const messages: VisionMessage[] = [
    {
      role: 'system',
      content: systemPrompt
    },
    {
      role: 'user',
      content: [
        {
          type: 'text',
          text: userPrompt
        },
        {
          type: 'image_url',
          image_url: {
            url: imageData
          }
        }
      ]
    }
  ];

  return chatCompletion(messages, { ...options, isVision: true });
}

// Helper function to convert File/Blob to base64
export async function fileToBase64(file: File | Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        // Remove data URL prefix if present
        const base64 = reader.result.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
        resolve(`data:image/png;base64,${base64}`);
      } else {
        reject(new Error('Failed to convert file to base64'));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

// Legacy method for backward compatibility
export async function chatCompletionWithPrompt(prompt: string, options: ChatOptions = {}) {
  return chatCompletion([{ role: 'user', content: prompt }], options);
}
