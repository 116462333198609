import { supabase } from '../supabase';
import { ProcessingError } from '../llm/processors/errors';
import { LoggerFunction } from '../../components/upload/types';
import { ProcessLogger } from '../process-logger';
import { processDocumentWithDualLLM } from '../llm/processors';
import { extractTextFromDocument } from '../llm/processors/text-extraction';
import { processDocumentImages } from '../llm/processors/image-extractor';
import { feedbackService } from './feedback-service';
import { DocumentType as AppDocumentType, ExtractedData } from '../../types/document';
import { LeaseAbstraction } from '../../types/lease';

// Database-level document type (only original_lease or amendment)
type DbDocumentType = 'original_lease' | 'amendment';

// Function to convert between document types
function mapDocumentType(type: AppDocumentType): DbDocumentType {
  switch (type) {
    case 'original_lease':
      return 'original_lease';
    case 'amendment':
    case 'option':
    case 'extension':
      return 'amendment';
    default:
      // This should never happen due to TypeScript, but just in case
      console.warn(`Unexpected document type: ${type}, defaulting to amendment`);
      return 'amendment';
  }
}

// Helper function to convert key terms to database format
function convertKeyTermsToDbFormat(keyTerms: DocumentClassification['keyTerms'] | undefined) {
  // Ensure we have valid arrays for each field
  const defaultKeyTerms = {
    original_lease_indicators: [],
    amendment_indicators: [],
    relationship_indicators: [],
    date_references: [],
    structural_indicators: []
  };

  if (!keyTerms) {
    return defaultKeyTerms;
  }

  // Filter out any null or undefined values and ensure arrays
  return {
    original_lease_indicators: Array.isArray(keyTerms.originalLeaseIndicators) 
      ? keyTerms.originalLeaseIndicators.filter(Boolean) 
      : defaultKeyTerms.original_lease_indicators,
    amendment_indicators: Array.isArray(keyTerms.amendmentIndicators)
      ? keyTerms.amendmentIndicators.filter(Boolean)
      : defaultKeyTerms.amendment_indicators,
    relationship_indicators: Array.isArray(keyTerms.relationshipIndicators)
      ? keyTerms.relationshipIndicators.filter(Boolean)
      : defaultKeyTerms.relationship_indicators,
    date_references: Array.isArray(keyTerms.dateReferences)
      ? keyTerms.dateReferences.filter(Boolean)
      : defaultKeyTerms.date_references,
    structural_indicators: Array.isArray(keyTerms.structuralIndicators)
      ? keyTerms.structuralIndicators.filter(Boolean)
      : defaultKeyTerms.structural_indicators
  };
}

// Helper function to validate classification data
function validateClassificationData(classification: DocumentClassification) {
  // Ensure required fields are present
  if (!classification.documentId) {
    throw new Error('Document ID is required');
  }
  if (!classification.type) {
    throw new Error('Document type is required');
  }
  if (typeof classification.confidenceScore !== 'number' || 
      classification.confidenceScore < 0 || 
      classification.confidenceScore > 1) {
    throw new Error('Confidence score must be a number between 0 and 1');
  }

  // Ensure type matches enum
  const type = mapDocumentType(classification.type);
  if (type !== 'original_lease' && type !== 'amendment') {
    throw new Error('Invalid document type');
  }

  // Format date to match database type (DATE)
  let effectiveDate: Date | null = null;
  if (classification.effectiveDate) {
    try {
      effectiveDate = new Date(classification.effectiveDate);
      // Validate date is not invalid
      if (isNaN(effectiveDate.getTime())) {
        throw new Error('Invalid date');
      }
    } catch (e) {
      throw new Error('Invalid effective date format');
    }
  }

  // Validate key terms structure
  const keyTerms = convertKeyTermsToDbFormat(classification.keyTerms);
  if (!keyTerms || typeof keyTerms !== 'object') {
    throw new Error('Invalid key terms structure');
  }

  // Return validated and properly formatted data
  return {
    p_document_id: classification.documentId,
    p_type: type,
    p_parent_document_id: classification.parentDocumentId || null,
    p_effective_date: effectiveDate,
    p_modification_summary: classification.modificationSummary || null,
    p_confidence_score: Math.max(0, Math.min(1, classification.confidenceScore)), // Ensure within bounds
    p_key_terms: keyTerms
  };
}

export interface DocumentClassification {
  id: string;
  documentId: string;
  type: AppDocumentType;
  parentDocumentId?: string;
  effectiveDate?: string;
  modificationSummary?: string;
  confidenceScore: number;
  keyTerms: {
    originalLeaseIndicators?: string[];
    amendmentIndicators?: string[];
    relationshipIndicators?: string[];
    dateReferences?: string[];
    structuralIndicators?: string[];
  };
  keyChanges?: Array<{
    field: string;
    oldValue: string | null;
    newValue: string;
  }>;
  expirationDate?: string;
  sequence?: number;
  parties?: {
    landlord?: string;
    tenant?: string;
  };
  premises?: string;
}

interface ClassificationResult {
  classification: DocumentClassification;
  error?: string;
}

/**
 * Analyzes document text to classify it and identify relationships using LlamaIndex
 */
export async function classifyDocument(
  documentId: string,
  file: File,
  fileName: string,
  groupId: string,
  addLog: LoggerFunction,
  processLogger: ProcessLogger
): Promise<ClassificationResult> {
  try {
    // Check if file is a floor plan
    const lowerFileName = fileName.toLowerCase();
    const isFloorPlan = lowerFileName.includes('floor plan') || 
                       lowerFileName.includes('floorplan') || 
                       lowerFileName.includes('floor-plan');

    if (isFloorPlan) {
      addLog('Detected floor plan document, processing with image extractor...', 'info');
      await processLogger.log('Detected floor plan document, processing with image extractor...', 'info');
      
      // Process with image extractor
      const imageResult = await processDocumentImages(file, addLog);
      if (imageResult?.rentableArea || imageResult?.buildingArea) {
        addLog(`Found area measurements in floor plan`, 'success');
        await processLogger.log(`Found area measurements in floor plan`, 'success');
        
        try {
          // Store all area measurements
          const { error: updateError } = await supabase
            .from('documents')
            .update({
              rentable_area: imageResult.rentableArea,
              building_area: imageResult.buildingArea,
              office_area: imageResult.officeArea,
              warehouse_area: imageResult.warehouseArea,
              common_area: imageResult.commonArea,
              usable_area: imageResult.usableArea,
              section_areas: imageResult.sectionAreas,
              area_source: 'floor_plan',
              area_confidence: imageResult.confidence
            })
            .eq('id', documentId);

          if (updateError) {
            addLog(`Failed to store floor plan measurements: ${updateError.message}`, 'error');
            throw updateError;
          }

          addLog('Successfully stored area measurements from floor plan', 'success');

          // Create key terms array with measurements
          const measurementTerms = [
            imageResult.rentableArea ? `Rentable Area: ${imageResult.rentableArea} sq ft` : null,
            imageResult.buildingArea ? `Building Area: ${imageResult.buildingArea} sq ft` : null,
            imageResult.officeArea ? `Office Area: ${imageResult.officeArea} sq ft` : null,
            imageResult.warehouseArea ? `Warehouse Area: ${imageResult.warehouseArea} sq ft` : null,
            imageResult.commonArea ? `Common Area: ${imageResult.commonArea} sq ft` : null,
            imageResult.usableArea ? `Usable Area: ${imageResult.usableArea} sq ft` : null
          ].filter(Boolean);

          // Create classification object
          const classification: DocumentClassification = {
            id: crypto.randomUUID(),
            documentId,
            type: 'original_lease',
            confidenceScore: Math.max(
              imageResult.confidence.rentableArea || 0,
              imageResult.confidence.buildingArea || 0,
              imageResult.confidence.officeArea || 0,
              imageResult.confidence.warehouseArea || 0,
              imageResult.confidence.commonArea || 0,
              imageResult.confidence.usableArea || 0
            ),
            keyTerms: {
              originalLeaseIndicators: measurementTerms,
              structuralIndicators: ['Floor Plan Document']
            }
          };

          // Insert classification with transaction
          try {
            // Validate classification data before inserting
            const validatedData = validateClassificationData(classification);
            
            // Check if classification exists
            const { data: existingClassification, error: checkError } = await supabase
              .from('document_classifications')
              .select('id')
              .eq('document_id', validatedData.p_document_id)
              .maybeSingle();

            if (checkError) {
              throw ProcessingError.database(
                'Failed to check existing document classification',
                checkError
              );
            }

            if (existingClassification) {
              // Update existing classification
              const { error: updateError } = await supabase
                .from('document_classifications')
                .update({
                  type: validatedData.p_type,
                  confidence_score: validatedData.p_confidence_score,
                  updated_at: new Date().toISOString()
                })
                .eq('id', existingClassification.id);

              if (updateError) {
                throw ProcessingError.database(
                  'Failed to update document classification',
                  updateError
                );
              }
            } else {
              // Insert new classification
              const { error: insertError } = await supabase
                .from('document_classifications')
                .insert([{
                  document_id: validatedData.p_document_id,
                  type: validatedData.p_type,
                  confidence_score: validatedData.p_confidence_score,
                  parent_document_id: validatedData.p_parent_document_id,
                  effective_date: validatedData.p_effective_date,
                  modification_summary: validatedData.p_modification_summary,
                  key_terms: validatedData.p_key_terms,
                  created_at: new Date().toISOString(),
                  updated_at: new Date().toISOString()
                }]);

              if (insertError) {
                throw ProcessingError.database(
                  'Failed to insert document classification',
                  insertError
                );
              }
            }

            const successMsg = `Document classified as ${classification.type} with ${Math.round(classification.confidenceScore * 100)}% confidence`;
            addLog(successMsg, 'success');
            await processLogger.log(successMsg, 'success');

            return { classification };
          } catch (error) {
            const errorMsg = error instanceof Error ? error.message : 'Unknown error';
            addLog(`Failed to process floor plan: ${errorMsg}`, 'error');
            throw ProcessingError.database('Failed to process floor plan', error as Error);
          }
        } catch (error) {
          const errorMsg = error instanceof Error ? error.message : 'Unknown error';
          addLog(`Failed to process floor plan: ${errorMsg}`, 'error');
          throw ProcessingError.database('Failed to process floor plan', error as Error);
        }
      }
    }

    addLog('Extracting text from document...', 'info');
    const text = await extractTextFromDocument(file);

    // Get enhanced prompts based on feedback
    const enhancedPrompt = await feedbackService.getEnhancedPrompt('documentType');

    addLog('Processing with dual LLM system...', 'info');
    const result = await processDocumentWithDualLLM(text, addLog, {
      confidenceThreshold: 0.7,
      isAmendment: false,
      file
    });

    // Calculate confidence score based on model results
    const confidenceScore = result.confidence ? 
      Math.min(1, Math.max(0,
        Object.values(result.confidence).reduce((acc, score) => 
          acc + (typeof score === 'number' ? score : 0), 0) / 
          Math.max(1, Object.keys(result.confidence).length)
      )) : 0.5; // Default to 0.5 if no confidence data

    // Create classification object
    const classification: DocumentClassification = {
      id: crypto.randomUUID(),
      documentId,
      type: result.data?.type?.toLowerCase() === 'original' ? 'original_lease' : 'amendment',
      effectiveDate: result.data?.effectiveDate?.toISOString?.() || undefined,
      modificationSummary: result.changes 
        ? (typeof result.changes === 'string' 
          ? result.changes 
          : Array.isArray(result.changes) 
            ? result.changes.map(c => `${c.field}: ${c.newValue}`).join(', ') 
            : '')
        : undefined,
      confidenceScore,
      keyTerms: {
        originalLeaseIndicators: [],
        amendmentIndicators: [],
        relationshipIndicators: [],
        dateReferences: [],
        structuralIndicators: []
      },
      parties: {
        landlord: result.data?.landlord?.name,
        tenant: result.data?.tenant?.name
      },
      premises: result.data?.premises
    };

    // Validate classification data
    const validatedData = validateClassificationData(classification);
    
    // Check if classification exists
    const { data: existingClassification, error: checkError } = await supabase
      .from('document_classifications')
      .select('id')
      .eq('document_id', validatedData.p_document_id)
      .maybeSingle();

    if (checkError) {
      throw ProcessingError.database(
        'Failed to check existing document classification',
        checkError
      );
    }

    if (existingClassification) {
      // Update existing classification
      const { error: updateError } = await supabase
        .from('document_classifications')
        .update({
          type: validatedData.p_type,
          confidence_score: validatedData.p_confidence_score,
          updated_at: new Date().toISOString()
        })
        .eq('id', existingClassification.id);

      if (updateError) {
        throw ProcessingError.database(
          'Failed to update document classification',
          updateError
        );
      }
    } else {
      // Insert new classification
      const { error: insertError } = await supabase
        .from('document_classifications')
        .insert([{
          document_id: validatedData.p_document_id,
          type: validatedData.p_type,
          confidence_score: validatedData.p_confidence_score,
          parent_document_id: validatedData.p_parent_document_id,
          effective_date: validatedData.p_effective_date,
          modification_summary: validatedData.p_modification_summary,
          key_terms: validatedData.p_key_terms,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }]);

      if (insertError) {
        throw ProcessingError.database(
          'Failed to insert document classification',
          insertError
        );
      }
    }

    return { classification };
  } catch (error) {
    const errorMsg = error instanceof Error ? error.message : 'Unknown error';
    addLog(`Classification failed: ${errorMsg}`, 'error');
    await processLogger.log(`Classification failed: ${errorMsg}`, 'error');
    
    return {
      classification: {
        id: crypto.randomUUID(),
        documentId,
        type: 'original_lease', // Default to original lease on error
        confidenceScore: 0,
        keyTerms: {}
      },
      error: errorMsg
    };
  }
}

/**
 * Gets the full hierarchy of related documents
 */
export async function getDocumentHierarchy(documentId: string): Promise<{
  documents: Array<{
    id: string;
    type: DbDocumentType;
    name: string;
    effectiveDate?: string;
    level: number;
  }>;
  error?: string;
}> {
  try {
    const { data, error } = await supabase.rpc(
      'get_document_hierarchy',
      { doc_id: documentId }
    );

    if (error) {
      throw error;
    }

    return {
      documents: data.map(doc => ({
        id: doc.document_id,
        type: doc.document_type as DbDocumentType,
        name: doc.document_name,
        effectiveDate: doc.effective_date,
        level: doc.level
      }))
    };
  } catch (error) {
    return {
      documents: [],
      error: error instanceof Error ? error.message : 'Failed to get document hierarchy'
    };
  }
}

/**
 * Gets all documents related to a given document
 */
export async function getRelatedDocuments(documentId: string): Promise<{
  originalLease?: {
    id: string;
    name: string;
    effectiveDate?: string;
  };
  amendments: Array<{
    id: string;
    name: string;
    effectiveDate?: string;
    modificationSummary?: string;
  }>;
  error?: string;
}> {
  try {
    const { data, error } = await supabase
      .from('document_relationships')
      .select('*')
      .or(`document_id.eq.${documentId},parent_document_id.eq.${documentId}`);

    if (error) {
      throw error;
    }

    const result: {
      originalLease?: {
        id: string;
        name: string;
        effectiveDate?: string;
      };
      amendments: Array<{
        id: string;
        name: string;
        effectiveDate?: string;
        modificationSummary?: string;
      }>;
    } = {
      amendments: []
    };

    data.forEach(doc => {
      if (doc.type === 'original_lease') {
        result.originalLease = {
          id: doc.document_id,
          name: doc.document_name,
          effectiveDate: doc.effective_date
        };
      } else {
        result.amendments.push({
          id: doc.document_id,
          name: doc.document_name,
          effectiveDate: doc.effective_date,
          modificationSummary: doc.modification_summary
        });
      }
    });

    return result;
  } catch (error) {
    return {
      amendments: [],
      error: error instanceof Error ? error.message : 'Failed to get related documents'
    };
  }
}
