import { ProcessingError } from './errors';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { OpenAIRetry } from '../../utils/openai-retry';
import { createWorker } from 'tesseract.js';
import { LoggerFunction } from '../../../components/upload/types';
import { DEFAULT_VISION_MODEL } from '../openrouter';
import type { VisionMessage } from '../openrouter';

// Configure PDF.js worker
GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

export interface ExtractedDates {
  commencementDate: string | null;
  originalCommencementDate: string | null;
  expirationDate: string | null;
  rentStartDate: string | null;
  confidence: {
    commencementDate: number;
    originalCommencementDate: number;
    expirationDate: number;
    rentStartDate: number;
  };
}

interface ImageAnalysisResult {
  rentableArea?: number;
  buildingArea?: number;
  officeArea?: number;
  warehouseArea?: number;
  commonArea?: number;
  usableArea?: number;
  squareFootage?: number;
  isFloorPlan?: boolean;
  sectionAreas?: { name: string; area: number; }[];
  confidence: {
    rentableArea: number;
    buildingArea: number;
    officeArea: number;
    warehouseArea: number;
    commonArea: number;
    usableArea: number;
    sectionAreas: number;
  };
}

interface ImageExtractionResult {
  rentableArea?: number;
  buildingArea?: number;
  officeArea?: number;
  warehouseArea?: number;
  commonArea?: number;
  usableArea?: number;
  squareFootage?: number;
  isFloorPlan?: boolean;
  sectionAreas?: { name: string; area: number; }[];
  confidence: {
    rentableArea: number;
    buildingArea: number;
    officeArea: number;
    warehouseArea: number;
    commonArea: number;
    usableArea: number;
    sectionAreas: number;
  };
}

const IMAGE_EXTRACTION_PROMPT = `
You are a specialized system for extracting numerical information from building floor plans and diagrams.
Focus on finding all area measurements including:
1. Rentable Area (Total leasable space)
2. Building Area (Total structure)
3. Office Area (Dedicated office space)
4. Warehouse Area (Storage/industrial space)
5. Common Area (Shared spaces, corridors, etc.)
6. Usable Area (Actual occupiable space)
7. Individual Section Areas (Named rooms/areas with measurements)

Guidelines:
1. Look for explicit area measurements in square feet
2. Check for area tables or legends
3. Sum up individual room areas if necessary
4. For each measurement, provide a confidence score (0-1)
5. Pay attention to labels like "OFFICE AREA", "RENTABLE AREA", "COMMON AREA", "SQ FT", etc.
6. Consider both text and numerical measurements in the diagram
7. Identify and measure distinct sections/rooms when labeled

Return the results in this exact JSON format:
{
  "rentableArea": number | null,
  "buildingArea": number | null,
  "officeArea": number | null,
  "warehouseArea": number | null,
  "commonArea": number | null,
  "usableArea": number | null,
  "sectionAreas": [{ "name": string, "area": number }] | null,
  "confidence": {
    "rentableArea": number,
    "buildingArea": number,
    "officeArea": number,
    "warehouseArea": number,
    "commonArea": number,
    "usableArea": number,
    "sectionAreas": number
  }
}`;

async function extractImagesFromPDF(
  pdfBytes: Uint8Array,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void
): Promise<Blob[]> {
  try {
    // Load the PDF document
    const loadingTask = getDocument({
      data: pdfBytes,
      disableFontFace: true,
      isEvalSupported: false,
      useSystemFonts: true,
      cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.4.120/cmaps/',
      cMapPacked: true,
      disableRange: true,
      disableStream: true
    } as any);
    const pdf = await loadingTask.promise;
    
    const images: Blob[] = [];
    const totalPages = pdf.numPages;
    addLog(`Processing ${totalPages} pages from PDF...`, 'info');

    // Process each page
    for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
      try {
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.5 }); // Increased scale for better quality
        
        // Create a canvas to render the page
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
          throw new Error('Failed to get canvas context');
        }
        
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        
        // Render the page to canvas
        await page.render({
          canvasContext: context,
          viewport: viewport
        }).promise;
        
        // Convert canvas to blob
        const blob = await new Promise<Blob>((resolve) => {
          canvas.toBlob((blob) => {
            resolve(blob!);
          }, 'image/png', 1.0); // Max quality
        });

        images.push(blob);
        addLog(`Extracted image from page ${pageNum}`, 'success');
      } catch (error) {
        addLog(`Failed to process page ${pageNum}: ${error}`, 'error');
      }
    }

    if (images.length === 0) {
      throw new Error('No images could be extracted from PDF');
    }

    addLog(`Successfully extracted ${images.length} images from PDF`, 'success');
    return images;
  } catch (error) {
    const message = error instanceof Error ? error.message : 'Unknown error';
    addLog(`Failed to extract images from PDF: ${message}`, 'error');
    throw error;
  }
}

async function isFloorPlan(
  image: string,
  openAIRetry: OpenAIRetry,
  logger: LoggerFunction
): Promise<boolean> {
  try {
    logger('Checking if image is a floor plan...', 'info');
    const response = await openAIRetry.createChatCompletion({
      messages: [
        {
          role: "system",
          content: "You are a specialized image classifier. Analyze this image and determine if it's a floor plan or building layout. Respond with ONLY a JSON object containing a boolean 'is_floor_plan' field. Example: {\"is_floor_plan\": true}"
        },
        {
          role: "user",
          content: [
            {
              type: "image_url",
              image_url: { url: image }
            }
          ]
        }
      ],
      model: DEFAULT_VISION_MODEL,
      maxTokens: 50,
      response_format: { type: "json_object" },
      isVision: true
    }, 'floor-plan-detection');

    // Add safety checks for response structure
    if (!response?.choices?.[0]?.message?.content) {
      logger('Invalid response structure from vision model', 'error');
      return false;
    }

    try {
      const result = JSON.parse(response.choices[0].message.content);
      return !!result.is_floor_plan;
    } catch (parseError) {
      logger(`Failed to parse floor plan response: ${parseError}`, 'error');
      return false;
    }
  } catch (error) {
    logger(`Error checking floor plan: ${error}`, 'error');
    return false; // Default to false on error
  }
}

async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        // Remove data URL prefix
        const base64 = reader.result.split(',')[1];
        resolve('data:image/png;base64,' + base64);
      } else {
        reject(new Error('Failed to convert blob to base64'));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

async function analyzeImage(
  image: Blob | string,
  openAIRetry: OpenAIRetry,
  logger: LoggerFunction,
  skipFloorPlanCheck: boolean = false
): Promise<ImageAnalysisResult> {
  try {
    logger('Starting image analysis...', 'info');
    
    // Convert Blob to base64 string if needed
    const imageData = typeof image === 'string' ? image : await blobToBase64(image);

    // Skip floor plan check if requested
    const isFloorPlanImage = skipFloorPlanCheck ? false : await isFloorPlan(imageData, openAIRetry, logger);
    
    // If it's not a floor plan or we're skipping the check, return minimal result
    if (!isFloorPlanImage) {
      return {
        isFloorPlan: false,
        confidence: {
          rentableArea: 0,
          buildingArea: 0,
          officeArea: 0,
          warehouseArea: 0,
          commonArea: 0,
          usableArea: 0,
          sectionAreas: 0
        }
      };
    }

    const messages = [
      {
        role: 'system' as const,
        content: IMAGE_EXTRACTION_PROMPT
      },
      {
        role: 'user' as const,
        content: [
          {
            type: 'text' as const,
            text: 'Analyze this floor plan and extract all area measurements.'
          },
          {
            type: 'image_url' as const,
            image_url: {
              url: imageData
            }
          }
        ] as const
      }
    ] as const;

    const response = await openAIRetry.createChatCompletion({
      messages,
      model: DEFAULT_VISION_MODEL,
      maxTokens: 500,
      response_format: { type: "json_object" },
      isVision: true
    }, 'floor-plan-analysis');

    try {
      const result = JSON.parse(response.choices[0].message.content);
      return {
        isFloorPlan: true,
        squareFootage: result.square_footage || null,
        rentableArea: result.rentableArea,
        buildingArea: result.buildingArea,
        officeArea: result.officeArea,
        warehouseArea: result.warehouseArea,
        commonArea: result.commonArea,
        usableArea: result.usableArea,
        sectionAreas: result.sectionAreas,
        confidence: {
          rentableArea: result.confidence?.rentableArea || 0,
          buildingArea: result.confidence?.buildingArea || 0,
          officeArea: result.confidence?.officeArea || 0,
          warehouseArea: result.confidence?.warehouseArea || 0,
          commonArea: result.confidence?.commonArea || 0,
          usableArea: result.confidence?.usableArea || 0,
          sectionAreas: result.confidence?.sectionAreas || 0
        }
      };
    } catch (parseError) {
      console.error('Failed to parse image analysis response:', parseError);
      return {
        isFloorPlan: true,
        squareFootage: null,
        confidence: {
          rentableArea: 0,
          buildingArea: 0,
          officeArea: 0,
          warehouseArea: 0,
          commonArea: 0,
          usableArea: 0,
          sectionAreas: 0
        }
      };
    }
  } catch (error) {
    logger(`Error analyzing image: ${error}`, 'error');
    return {
      isFloorPlan: false,
      squareFootage: null,
      confidence: {
        rentableArea: 0,
        buildingArea: 0,
        officeArea: 0,
        warehouseArea: 0,
        commonArea: 0,
        usableArea: 0,
        sectionAreas: 0
      }
    };
  }
}

export async function extractFromImage(
  image: Blob,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void
): Promise<ImageExtractionResult> {
  const openaiRetry = new OpenAIRetry('image-extractor');
  const result = await analyzeImage(image, openaiRetry, addLog);
  
  if (!result) {
    return {
      confidence: {
        rentableArea: 0,
        buildingArea: 0,
        officeArea: 0,
        warehouseArea: 0,
        commonArea: 0,
        usableArea: 0,
        sectionAreas: 0
      }
    };
  }

  return result;
}

export async function processDocumentImages(
  file: File,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void,
  skipFloorPlanCheck: boolean = false
): Promise<ImageExtractionResult | null> {
  // If floor plan checking is disabled, return null immediately
  if (skipFloorPlanCheck) {
    addLog('Skipping floor plan check since vision checking for floor plans is disabled', 'info');
    return null;
  }

  try {
    // Convert File to ArrayBuffer
    const arrayBuffer = await file.arrayBuffer();
    const pdfBytes = new Uint8Array(arrayBuffer);

    // Extract all images from PDF
    const images = await extractImagesFromPDF(pdfBytes, addLog);
    if (images.length === 0) {
      addLog('No images could be extracted from document', 'info');
      return null;
    }

    const openaiRetry = new OpenAIRetry('image-extractor');
    let bestResult: ImageExtractionResult | null = null;
    let highestConfidence = 0;

    // Analyze each image and keep the result with highest confidence
    for (let i = 0; i < images.length; i++) {
      addLog(`Analyzing image ${i + 1} of ${images.length}...`, 'info');
      const result = await analyzeImage(images[i], openaiRetry, addLog, skipFloorPlanCheck);
      
      if (result) {
        const confidence = Math.max(
          result.confidence.rentableArea || 0,
          result.confidence.buildingArea || 0,
          result.confidence.officeArea || 0,
          result.confidence.warehouseArea || 0,
          result.confidence.commonArea || 0,
          result.confidence.usableArea || 0,
          result.confidence.sectionAreas || 0
        );
        
        if (confidence > highestConfidence) {
          highestConfidence = confidence;
          bestResult = result;
        }
      }
    }

    if (!bestResult) {
      addLog('No floor plans found in any of the pages', 'info');
      return null;
    }

    return bestResult;
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    addLog('Error processing document images: ' + errorMessage, 'error');
    return null;
  }
}
