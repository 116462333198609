import { useState } from 'react';
import { format } from 'date-fns';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { History } from 'lucide-react';
import type { LeaseAbstraction } from '@/types/lease';

interface VersionHistoryProps {
  versions: LeaseAbstraction[];
  currentVersionId: string | null;
  onVersionSelect: (versionId: string) => void;
}

export function VersionHistory({
  versions,
  currentVersionId,
  onVersionSelect
}: VersionHistoryProps) {
  const [isOpen, setIsOpen] = useState(false);

  // Sort versions from most recent to oldest
  const sortedVersions = [...versions].sort((a, b) => {
    // First by version number (descending)
    if (a.version !== b.version) return b.version - a.version;
    // Then by creation date if versions are equal
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">
          <History className="mr-2 h-4 w-4" />
          Version History ({versions.length})
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[600px] p-0">
        <DialogHeader className="p-6 border-b">
          <DialogTitle className="text-xl">Version History</DialogTitle>
        </DialogHeader>
        <div className="p-6 max-h-[calc(100vh-200px)] overflow-y-auto">
          <div className="space-y-4">
            {sortedVersions.map((version) => (
              <div
                key={version.id}
                className={`p-4 rounded-lg border transition-colors cursor-pointer hover:border-primary ${
                  version.id === currentVersionId ? 'bg-muted border-primary' : ''
                }`}
                onClick={() => {
                  onVersionSelect(version.id);
                  setIsOpen(false);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="space-y-1">
                    <div className="flex items-center gap-2">
                      <h3 className="text-lg font-medium">
                        {version.type === 'original' ? 'Original Lease' : `Amendment ${version.version - 1}`}
                      </h3>
                      <Badge variant={version.type === 'original' ? 'secondary' : 'outline'}>
                        {version.type === 'original' ? 'Original' : 'Amendment'}
                      </Badge>
                      {version.id === currentVersionId && (
                        <Badge variant="default">Current</Badge>
                      )}
                    </div>
                    <p className="text-sm text-muted-foreground">
                      Created {format(new Date(version.createdAt), 'PPp')}
                    </p>
                    {version.effectiveDate && (
                      <p className="text-sm text-muted-foreground">
                        Effective: {format(new Date(version.effectiveDate), 'PPP')}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}