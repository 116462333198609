import OpenAI from 'openai';
import { supabase } from '../supabase';
import type { LeaseAbstraction } from '../../types/lease';

// OpenAI key specifically for fine-tuning operations
const OPENAI_FINE_TUNING_KEY = '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';

const openai = new OpenAI({
  apiKey: OPENAI_FINE_TUNING_KEY,
  baseURL: 'https://api.openai.com/v1', // Use OpenAI API directly for fine-tuning
  dangerouslyAllowBrowser: true
});

interface TrainingExample {
  messages: {
    role: 'system' | 'user' | 'assistant';
    content: string;
  }[];
}

function formatAbstractionForTraining(abstraction: LeaseAbstraction): string {
  // Format dates consistently
  const formatDate = (date: Date | null) => date ? date.toISOString().split('T')[0] : null;

  return JSON.stringify({
    premises: abstraction.premises,
    county: abstraction.county,
    tenantLegalName: abstraction.tenantLegalName,
    commencementDate: formatDate(abstraction.commencementDate),
    originalCommencementDate: formatDate(abstraction.originalCommencementDate),
    expirationDate: formatDate(abstraction.expirationDate),
    rentStartDate: formatDate(abstraction.rentStartDate),
    rentableArea: abstraction.rentableArea,
    proRata: abstraction.proRata,
    buildingArea: abstraction.buildingArea,
    baseRent: abstraction.baseRent,
    annualRent: abstraction.annualRent,
    securityDeposit: abstraction.securityDeposit,
    landlord: abstraction.landlord,
    tenant: abstraction.tenant,
    clauses: abstraction.clauses,
    additionalClauses: abstraction.additionalClauses
  });
}

export async function pushToOpenAI(minAccuracy = 0.8) {
  try {
    // Get all reviewed documents with high accuracy
    const { data: documents, error } = await supabase
      .from('training_documents')
      .select('*')
      .eq('status', 'reviewed')
      .gte('metrics->fieldAccuracy', minAccuracy);

    if (error) throw error;
    if (!documents?.length) {
      throw new Error('No suitable training documents found');
    }

    // Split into training (80%) and validation (20%) sets
    const splitIndex = Math.floor(documents.length * 0.8);
    const trainingDocs = documents.slice(0, splitIndex);
    const validationDocs = documents.slice(splitIndex);

    // Prepare JSONL content
    const trainingJSONL = prepareJSONL(trainingDocs.map(doc => doc.reviewed_abstraction));
    const validationJSONL = prepareJSONL(validationDocs.map(doc => doc.reviewed_abstraction));

    // Create File objects with required properties
    const trainingFile = new File(
      [trainingJSONL],
      'training.jsonl',
      { type: 'application/jsonl', lastModified: Date.now() }
    );

    const validationFile = new File(
      [validationJSONL],
      'validation.jsonl',
      { type: 'application/jsonl', lastModified: Date.now() }
    );

    // Upload files to OpenAI
    const trainingFileUpload = await openai.files.create({
      file: trainingFile,
      purpose: 'fine-tune'
    });

    const validationFileUpload = await openai.files.create({
      file: validationFile,
      purpose: 'fine-tune'
    });

    // Create fine-tuning job
    const fineTuningJob = await openai.fineTuning.jobs.create({
      model: 'gpt-4',
      training_file: trainingFileUpload.id,
      validation_file: validationFileUpload.id,
      hyperparameters: {
        n_epochs: 3
      }
    });

    // Log the fine-tuning job
    const { error: logError } = await supabase
      .from('model_versions')
      .insert({
        version: `ft-${Date.now()}`,
        status: 'training',
        job_id: fineTuningJob.id,
        training_data_count: documents.length,
        metrics: {
          accuracy: 0,
          processingTime: 0
        },
        created_at: new Date().toISOString()
      });

    if (logError) throw logError;

    return { 
      data: {
        jobId: fineTuningJob.id,
        trainingCount: trainingDocs.length,
        validationCount: validationDocs.length
      }, 
      error: null 
    };
  } catch (error) {
    console.error('Push to OpenAI error:', error);
    return { 
      data: null, 
      error: error instanceof Error ? error.message : 'Failed to push to OpenAI'
    };
  }
}

export function prepareJSONL(abstractions: LeaseAbstraction[]): string {
  return abstractions
    .map(abstraction => {
      const example = {
        messages: [
          {
            role: 'system',
            content: 'You are a lease abstraction expert. Analyze the lease document and extract key information in a structured format.'
          },
          {
            role: 'user',
            content: abstraction.documentContent || 'Document content not available'
          },
          {
            role: 'assistant',
            content: formatAbstractionForTraining(abstraction)
          }
        ]
      };
      return JSON.stringify(example);
    })
    .join('\n');
}

export async function getFineTuningStatus(jobId: string) {
  try {
    const job = await openai.fineTuning.jobs.retrieve(jobId);
    
    // Update model version status if job is completed
    if (job.status === 'succeeded') {
      await supabase
        .from('model_versions')
        .update({
          status: 'active',
          metrics: {
            accuracy: job.trained_tokens / job.trained_tokens, // Fixed: using trained_tokens instead of training_tokens
            processingTime: (new Date(job.finished_at).getTime() - new Date(job.created_at).getTime()) / 1000
          }
        })
        .eq('job_id', jobId);
    }

    return { data: job, error: null };
  } catch (error) {
    console.error('Get fine-tuning status error:', error);
    return {
      data: null,
      error: error instanceof Error ? error.message : 'Failed to get fine-tuning status'
    };
  }
}

// Add button to ModelVersions.tsx to trigger fine-tuning
export async function startFineTuning(minAccuracy = 0.8) {
  try {
    const { data, error } = await pushToOpenAI(minAccuracy);
    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Start fine-tuning error:', error);
    return {
      data: null,
      error: error instanceof Error ? error.message : 'Failed to start fine-tuning'
    };
  }
}
