import { useEffect, useRef } from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { LogEntry, UploadTerminalProps } from './types';

export function UploadTerminal({ logs }: UploadTerminalProps) {
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [logs]);

  return (
    <div className="mt-4 bg-zinc-950 rounded-lg border border-zinc-800">
      <div className="flex items-center gap-2 px-4 py-2 border-b border-zinc-800">
        <div className="flex gap-1.5">
          <div className="w-3 h-3 rounded-full bg-red-500" />
          <div className="w-3 h-3 rounded-full bg-yellow-500" />
          <div className="w-3 h-3 rounded-full bg-green-500" />
        </div>
        <p className="text-xs text-zinc-400">Upload Process Log</p>
      </div>
      
      <ScrollArea className="h-[300px] p-4">
        <div className="font-mono text-sm">
          {logs.map((log, index) => (
            <div
              key={index}
              className={`mb-1 ${
                log.type === 'error'
                  ? 'text-red-400'
                  : log.type === 'success'
                  ? 'text-green-400'
                  : 'text-zinc-300'
              }`}
            >
              <span className="text-zinc-500">
                [{log.timestamp.toLocaleTimeString()}]
              </span>{' '}
              {log.message}
            </div>
          ))}
          <div ref={bottomRef} />
        </div>
      </ScrollArea>
    </div>
  );
}