import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Button } from './ui/button';
import { Logo } from './Logo';
import {
  ScrollText,
  Settings,
  LogOut,
  Plus,
  LayoutDashboard,
  Brain,
  Shield,
  Sparkles,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { cn } from '../lib/utils';

interface NavigationProps {
  isCollapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
}

export function Navigation({ isCollapsed, onCollapse }: NavigationProps) {
  const { user, signOut, requiresTOTP } = useAuth();

  // Don't show navigation if user isn't authenticated or needs 2FA
  if (!user || requiresTOTP) {
    return null;
  }

  const NavLink = ({ to, icon: Icon, children }: { to: string; icon: any; children: React.ReactNode }) => (
    <Link to={to}>
      <Button variant="ghost" className={cn(
        "w-full justify-start gap-2 py-6",
        isCollapsed && "justify-center px-2"
      )}>
        <Icon className="h-4 w-4 flex-shrink-0" />
        {!isCollapsed && <span>{children}</span>}
      </Button>
    </Link>
  );

  return (
    <aside className={cn(
      "fixed left-0 top-0 z-30 h-screen border-r bg-background transition-all duration-300",
      isCollapsed ? "w-16" : "w-64"
    )}>
      <div className="flex h-full flex-col">
        {/* Logo and collapse button section */}
        <div className="flex items-center justify-between border-b p-4">
          {!isCollapsed && <Logo />}
          <Button
            variant="ghost"
            size="sm"
            className="h-8 w-8 p-0"
            onClick={() => onCollapse(!isCollapsed)}
          >
            {isCollapsed ? (
              <ChevronRight className="h-4 w-4" />
            ) : (
              <ChevronLeft className="h-4 w-4" />
            )}
          </Button>
        </div>
        
        {/* Navigation links */}
        <nav className="flex-1 space-y-6 p-3">
          <div className="mb-4">
            <Link to="/upload">
              <Button 
                variant="default" 
                className={cn(
                  "w-full gap-2",
                  isCollapsed ? "justify-center px-2" : "justify-start"
                )}
              >
                <Plus className="h-4 w-4 flex-shrink-0" />
                {!isCollapsed && <span>New Abstraction</span>}
              </Button>
            </Link>
          </div>
          
          <div className="space-y-6">
            <NavLink to="/" icon={LayoutDashboard}>Dashboard</NavLink>
            <NavLink to="/abstractions" icon={ScrollText}>Abstractions</NavLink>
            <NavLink to="/training" icon={Brain}>Training</NavLink>
            <NavLink to="/admin" icon={Shield}>Admin</NavLink>
          </div>
        </nav>

        {/* Bottom section with settings and logout */}
        <div className="border-t p-3 space-y-0">
          <NavLink to="/settings" icon={Settings}>Settings</NavLink>
          <Button
            variant="ghost"
            onClick={() => signOut()}
            className={cn(
              "w-full gap-2 text-red-500 hover:text-red-600 hover:bg-red-50",
              isCollapsed ? "justify-center px-2" : "justify-start"
            )}
          >
            <LogOut className="h-4 w-4 flex-shrink-0" />
            {!isCollapsed && <span>Logout</span>}
          </Button>
        </div>
      </div>
    </aside>
  );
}
