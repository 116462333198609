import { ProcessingError } from '../errors';
import { parseDate } from './date-utils';
import { getPreviousVersion } from './version-utils';
import { deepMergeObjects } from './merge-utils';
import { detectChanges } from '../changes';
import type { LeaseAbstraction, FieldStatus } from '../../../../types/lease';

const DATE_FIELDS = [
  'commencementDate',
  'originalCommencementDate',
  'expirationDate',
  'rentStartDate'
] as const;
type DateField = typeof DATE_FIELDS[number];

/**
 * Processes amendment data, merging with previous version
 */
export async function processAmendment(
  mergedResponse: any,
  extractedDates: any,
  abstractionId: string,
  confidenceThreshold: number,
  addLog: (message: string, type?: 'info' | 'error' | 'success') => void
): Promise<any> {
  try {
    addLog('Fetching previous version for amendment processing...');
    const previousVersion = await getPreviousVersion(abstractionId) as LeaseAbstraction;
    
    if (!previousVersion) {
      throw ProcessingError.document(`Previous version not found for abstraction ID: ${abstractionId}`);
    }

    // STEP 1: Handle dates first, before any other processing
    addLog('Processing amendment dates...', 'info');
    const amendmentDates = {
      commencementDate: extractedDates.commencementDate ? parseDate(extractedDates.commencementDate) : null,
      expirationDate: extractedDates.expirationDate ? parseDate(extractedDates.expirationDate) : null,
      rentStartDate: null, // Will be set to match commencementDate
      originalCommencementDate: previousVersion.originalCommencementDate || previousVersion.commencementDate
    };

    // Validate and log date processing
    if (amendmentDates.commencementDate) {
      addLog(`Amendment commencement date found: ${amendmentDates.commencementDate.toISOString()}`, 'info');
      // Always set rentStartDate to match commencementDate
      amendmentDates.rentStartDate = amendmentDates.commencementDate;
    } else {
      addLog('No new commencement date in amendment, preserving previous version date', 'info');
      amendmentDates.commencementDate = previousVersion.commencementDate;
      amendmentDates.rentStartDate = previousVersion.commencementDate;
    }

    if (amendmentDates.expirationDate) {
      addLog(`Amendment expiration date found: ${amendmentDates.expirationDate.toISOString()}`, 'info');
    } else {
      addLog('No new expiration date in amendment, preserving previous version date', 'info');
      amendmentDates.expirationDate = previousVersion.expirationDate;
    }

    // STEP 2: Create initial merged data with dates
    const initialMergedData = {
      ...previousVersion,
      ...amendmentDates
    };

    addLog('Detecting changes from previous version...');
    const amendmentData = mergedResponse.data || mergedResponse;
    const changes = detectChanges(amendmentData, previousVersion);
    addLog(`Detected ${Object.keys(changes).length} changes`, 'info');

    // STEP 3: Merge other data while preserving our processed dates
    const preservedData: Partial<LeaseAbstraction> = {
      // Use our explicitly processed dates
      ...amendmentDates,
      // Preserve other fields unless explicitly changed
      landlord: previousVersion.landlord,
      tenant: previousVersion.tenant,
      premises: previousVersion.premises,
      rentableArea: previousVersion.rentableArea,
      buildingArea: previousVersion.buildingArea,
      proRata: previousVersion.proRata,
      clauses: previousVersion.clauses,
      additionalClauses: previousVersion.additionalClauses
    };

    // Initialize field status
    const fieldStatus: Record<string, FieldStatus> = { ...previousVersion.fieldStatus };
    
    // Update field status for dates first
    fieldStatus.commencementDate = amendmentDates.commencementDate ? 'not_missing' : 'missing';
    fieldStatus.originalCommencementDate = amendmentDates.originalCommencementDate ? 'not_missing' : 'missing';
    fieldStatus.expirationDate = amendmentDates.expirationDate ? 'not_missing' : 'missing';
    fieldStatus.rentStartDate = amendmentDates.rentStartDate ? 'not_missing' : 'missing';

    // Update field status for other changed fields
    Object.keys(changes).forEach(field => {
      if (!DATE_FIELDS.includes(field as DateField)) {
        const value = amendmentData[field];
        if (value === null || value === undefined || value === '') {
          fieldStatus[field] = 'missing';
        } else {
          fieldStatus[field] = 'not_missing';
        }
      }
    });

    // STEP 4: Final merge while ensuring dates are preserved
    const mergedData = {
      ...deepMergeObjects(initialMergedData, amendmentData, '', true),
      ...amendmentDates, // Ensure dates are not overwritten
      fieldStatus
    };

    addLog('Amendment processing completed successfully', 'success');
    return { 
      mergedData,
      changes,
      effectiveDate: amendmentDates.commencementDate
    };
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    addLog(`Amendment processing failed: ${errorMessage}`, 'error');
    throw ProcessingError.document('Failed to merge amendment data', error as Error);
  }
}
