import { uploadFile } from './upload-service';
import { supabase } from '../../../lib/supabase';
import { processMainLease, processAmendment } from './lease-service';
import { checkDocumentExists, trackDocument } from '../../../lib/db/documents/tracking';
import { groupRelatedFiles } from '../utils/file-grouping';
import { validateFileTypes } from '../utils/file-detection';
import { ProcessingError } from '../../../lib/llm/processors/errors';
import { classifyDocument } from '../../../lib/services/document-classification';
import { createErrorHandler, withErrorHandling } from '../../../lib/utils/error-handler';
import { ProcessLogger } from '../../../lib/process-logger';
import { calculateFileChecksum } from '../../../lib/utils/checksum';
import type { LoggerFunction } from '../types';
import type { DocumentType, FileWithType } from '../../../types/document';

interface ProcessFilesResult {
  abstractionId?: string;
  error?: string;
}

interface ValidationContext {
  mainLease?: {
    id: string;
    checksum: string;
    date?: string;
    landlord?: string;
    tenant?: string;
    premises?: string;
    keyTerms?: {
      originalLeaseIndicators?: string[];
      structuralIndicators?: string[];
    };
  };
  amendments: Array<{
    id: string;
    checksum: string;
    date?: string;
    landlord?: string;
    tenant?: string;
    premises?: string;
    relationshipIndicators?: string[];
    modificationSummary?: string;
    parentDocument?: string;
  }>;
}

// Create error handler for this service
const errorHandler = createErrorHandler('FileProcessingService');

/**
 * Map document types to processing types
 */
function mapToProcessingType(type: DocumentType): 'original_lease' | 'amendment' {
  switch (type) {
    case 'original_lease':
      return 'original_lease';
    case 'amendment':
    case 'option':
    case 'extension':
      return 'amendment';
    default:
      return 'amendment';
  }
}

/**
 * Processes files that have already been classified and confirmed by the user
 */
export async function processConfirmedFiles(
  filesWithTypes: FileWithType[],
  userId: string,
  addLog: LoggerFunction,
  processLogger: ProcessLogger,
  tenant?: string,
  premises?: string
): Promise<ProcessFilesResult> {
  return withErrorHandling('processConfirmedFiles', async () => {
    // Log start of processing
    addLog('Starting document processing pipeline...', 'info');
    await processLogger.log('Starting document processing pipeline...', 'info');

    // First check if any of the files already exist
    addLog('Checking for existing documents...', 'info');
    await processLogger.log('Checking for existing documents...', 'info');

    for (const fileWithType of filesWithTypes) {
      const { exists, abstractionId: existingId, error: checkError } = 
        await checkDocumentExists(fileWithType.file, userId);
      
      if (checkError) {
        throw ProcessingError.database(`Failed to check document: ${checkError}`);
      }

      if (exists && existingId) {
        const existsMsg = `Document ${fileWithType.file.name} already exists in abstraction ${existingId}`;
        addLog(existsMsg, 'info');
        await processLogger.log(existsMsg, 'info');
        return { abstractionId: existingId };
      }
    }

    // Initialize validation context
    addLog('Initializing validation context...', 'info');
    await processLogger.log('Initializing validation context...', 'info');
    const validationContext: ValidationContext = {
      amendments: []
    };

    // Map document types to processing types
    addLog('Mapping document types...', 'info');
    await processLogger.log('Mapping document types...', 'info');
    const processableFiles = filesWithTypes.map(f => ({
      file: f.file,
      type: mapToProcessingType(f.type),
      originalType: f.type // Keep original type for logging
    }));

    // First pass: Calculate checksums and classify all documents
    addLog(`Beginning document processing for ${processableFiles.length} file(s)...`, 'info');
    await processLogger.log(`Beginning document processing for ${processableFiles.length} file(s)...`, 'info');

    for (const fileWithType of processableFiles) {
      // Log document processing start
      addLog(`Creating database record for ${fileWithType.file.name}...`, 'info');
      await processLogger.log(`Creating database record for ${fileWithType.file.name}...`, 'info');

      // Create document record first
      const { data: document, error: documentError } = await supabase
        .from('documents')
        .insert({
          name: fileWithType.file.name,
          file_path: fileWithType.file.name, // Temporary path, will be updated after upload
          user_id: userId,
          tenant: tenant,
          premises: premises
        })
        .select()
        .single();

      if (documentError) {
        throw new Error(`Failed to create document record: ${documentError.message}`);
      }

      // Calculate checksum
      addLog(`Calculating checksum for ${fileWithType.file.name}...`, 'info');
      await processLogger.log(`Calculating checksum for ${fileWithType.file.name}...`, 'info');
      const checksum = await calculateFileChecksum(fileWithType.file);
      if (!checksum) {
        throw new Error(`Failed to calculate checksum for: ${fileWithType.file.name}`);
      }

      // Use LlamaParse to analyze the document
      addLog(`Starting document analysis for ${fileWithType.file.name}...`, 'info');
      await processLogger.log(`Starting document analysis for ${fileWithType.file.name}...`, 'info');
      const { classification, error: classificationError } = await classifyDocument(
        document.id,
        fileWithType.file,
        fileWithType.file.name,
        'processing',
        addLog,
        processLogger
      );

      if (classificationError) {
        throw new Error(`Failed to analyze ${fileWithType.file.name}: ${classificationError}`);
      }

      // Store validation info with document ID
      if (fileWithType.type === 'original_lease') {
        validationContext.mainLease = {
          id: document.id,
          checksum,
          date: classification.effectiveDate,
          landlord: classification.keyTerms?.originalLeaseIndicators?.join(', '),
          tenant: classification.keyTerms?.originalLeaseIndicators?.join(', '),
          premises: classification.keyTerms?.structuralIndicators?.join(', '),
          keyTerms: {
            originalLeaseIndicators: classification.keyTerms?.originalLeaseIndicators,
            structuralIndicators: classification.keyTerms?.structuralIndicators
          }
        };
      } else {
        validationContext.amendments.push({
          id: document.id,
          checksum,
          date: classification.effectiveDate,
          landlord: classification.keyTerms?.originalLeaseIndicators?.join(', '),
          tenant: classification.keyTerms?.originalLeaseIndicators?.join(', '),
          premises: classification.keyTerms?.structuralIndicators?.join(', '),
          relationshipIndicators: classification.keyTerms?.relationshipIndicators,
          modificationSummary: classification.modificationSummary,
          parentDocument: classification.parentDocumentId
        });
      }
    }

    // Find the main lease if it exists
    const mainLeaseFile = processableFiles.find(f => f.type === 'original_lease');

    // Get amendments and sort by date
    addLog('Processing amendments...', 'info');
    await processLogger.log('Processing amendments...', 'info');
    
    const amendments = processableFiles.filter(f => f.type === 'amendment');
    addLog(`Found ${amendments.length} amendment(s)`, 'info');
    await processLogger.log(`Found ${amendments.length} amendment(s)`, 'info');
    
    addLog('Sorting amendments by date...', 'info');
    await processLogger.log('Sorting amendments by date...', 'info');
    const amendmentDates = await Promise.all(
      amendments.map(async amendment => {
        const checksum = await calculateFileChecksum(amendment.file);
        const context = validationContext.amendments.find(a => a.checksum === checksum);
        return {
          amendment,
          date: context?.date ? new Date(context.date).getTime() : 0
        };
      })
    );

    // Sort amendments by date
    const sortedAmendments = amendments.sort((a, b) => {
      const aDate = amendmentDates.find(d => d.amendment.file.name === a.file.name)?.date || 0;
      const bDate = amendmentDates.find(d => d.amendment.file.name === b.file.name)?.date || 0;
      return aDate - bDate;
    });

    // Process main lease if it exists
    let currentAbstractionId: string | undefined;
    if (mainLeaseFile) {
      const mainLeaseMsg = `Processing main lease: ${mainLeaseFile.file.name} (${validationContext.mainLease?.keyTerms?.originalLeaseIndicators?.length || 0} lease indicators found)`;
      addLog(mainLeaseMsg);
      await processLogger.log(mainLeaseMsg);

      // Check if main lease already exists
      const { exists, abstractionId: existingId, error: checkError } = 
        await checkDocumentExists(mainLeaseFile.file, userId);
      
      if (checkError) {
        throw ProcessingError.database(`Failed to check document: ${checkError}`);
      }

      if (exists && existingId) {
        const existsMsg = 'Lease already exists, redirecting to existing abstraction...';
        addLog(existsMsg, 'info');
        await processLogger.log(existsMsg, 'info');
        return { abstractionId: existingId };
      }

      // Upload and process main lease
      addLog(`Uploading main lease: ${mainLeaseFile.file.name}...`, 'info');
      await processLogger.log(`Uploading main lease: ${mainLeaseFile.file.name}...`, 'info');
      const { path: mainLeasePath, error: uploadError } = await uploadFile(mainLeaseFile.file, userId);
      if (uploadError) {
        throw ProcessingError.document(`Failed to upload lease: ${uploadError}`);
      }

      // Track the document
      addLog(`Tracking main lease in database...`, 'info');
      await processLogger.log(`Tracking main lease in database...`, 'info');
      const { error: trackError } = await trackDocument(mainLeaseFile.file, userId);
      if (trackError) {
        throw ProcessingError.database(`Failed to track document: ${trackError}`);
      }

      const abstraction = await processMainLease(mainLeaseFile.file, mainLeasePath, userId, addLog, processLogger);
      currentAbstractionId = abstraction.id;
    }

    // Process amendments if they exist
    if (sortedAmendments.length > 0) {
      if (!currentAbstractionId && sortedAmendments.length > 0) {
        // Create a new abstraction for amendments only
        const firstAmendment = sortedAmendments[0];
        addLog(`Uploading first amendment: ${firstAmendment.file.name}...`, 'info');
        await processLogger.log(`Uploading first amendment: ${firstAmendment.file.name}...`, 'info');
        const { path: amendmentPath, error: uploadError } = await uploadFile(firstAmendment.file, userId);
        if (uploadError) {
          throw ProcessingError.document(`Failed to upload amendment: ${uploadError}`);
        }

        // Track the document
        addLog(`Tracking first amendment in database...`, 'info');
        await processLogger.log(`Tracking first amendment in database...`, 'info');
        const { error: trackError } = await trackDocument(firstAmendment.file, userId);
        if (trackError) {
          throw ProcessingError.database(`Failed to track document: ${trackError}`);
        }

        // Process first amendment as the base document
        const firstAmendmentChecksum = await calculateFileChecksum(firstAmendment.file);
        const firstAmendmentContext = validationContext.amendments.find(a => a.checksum === firstAmendmentChecksum);

        const baseMsg = `Using first amendment as base document: ${firstAmendment.file.name}${
          firstAmendmentContext?.modificationSummary ? ` (${firstAmendmentContext.modificationSummary})` : ''
        }`;
        addLog(baseMsg);
        await processLogger.log(baseMsg);

        const abstraction = await processMainLease(firstAmendment.file, amendmentPath, userId, addLog, processLogger);
        currentAbstractionId = abstraction.id;

        // Process remaining amendments in chronological order
        for (let i = 1; i < sortedAmendments.length; i++) {
          const amendment = sortedAmendments[i];
          const amendmentChecksum = await calculateFileChecksum(amendment.file);
          const amendmentContext = validationContext.amendments.find(a => a.checksum === amendmentChecksum);
          
          const amendmentMsg = `Processing amendment ${i + 1}/${sortedAmendments.length}: ${amendment.file.name}${
            amendmentContext?.modificationSummary ? ` (${amendmentContext.modificationSummary})` : ''
          }`;
          addLog(amendmentMsg);
          await processLogger.log(amendmentMsg);

          addLog(`Uploading amendment: ${amendment.file.name}...`, 'info');
          await processLogger.log(`Uploading amendment: ${amendment.file.name}...`, 'info');
          const { path: path, error: error } = await uploadFile(amendment.file, userId);
          if (error) {
            throw ProcessingError.document(`Failed to upload amendment: ${error}`);
          }

          // Track the document
          addLog(`Tracking amendment in database...`, 'info');
          await processLogger.log(`Tracking amendment in database...`, 'info');
          const { error: trackError } = await trackDocument(amendment.file, userId, currentAbstractionId);
          if (trackError) {
            throw ProcessingError.database(`Failed to track document: ${trackError}`);
          }

          await processAmendment(
            amendment.file,
            path,
            currentAbstractionId,
            userId,
            addLog,
            processLogger
          );
        }
      } else {
        // Process all amendments against the main lease in chronological order
        for (const amendment of sortedAmendments) {
          const amendmentChecksum = await calculateFileChecksum(amendment.file);
          const amendmentContext = validationContext.amendments.find(a => a.checksum === amendmentChecksum);
          
          const amendmentMsg = `Processing amendment: ${amendment.file.name}${
            amendmentContext?.modificationSummary ? ` (${amendmentContext.modificationSummary})` : ''
          }`;
          addLog(amendmentMsg);
          await processLogger.log(amendmentMsg);

          addLog(`Uploading amendment: ${amendment.file.name}...`, 'info');
          await processLogger.log(`Uploading amendment: ${amendment.file.name}...`, 'info');
          const { path: path, error: error } = await uploadFile(amendment.file, userId);
          if (error) {
            throw ProcessingError.document(`Failed to upload amendment: ${error}`);
          }

          // Track the document
          addLog(`Tracking amendment in database...`, 'info');
          await processLogger.log(`Tracking amendment in database...`, 'info');
          const { error: trackError } = await trackDocument(amendment.file, userId, currentAbstractionId);
          if (trackError) {
            throw ProcessingError.database(`Failed to track document: ${trackError}`);
          }

          await processAmendment(
            amendment.file,
            path,
            currentAbstractionId!,
            userId,
            addLog,
            processLogger
          );
        }
      }
    }

    if (!currentAbstractionId) {
      throw ProcessingError.document('No abstraction ID was created');
    }

    return { abstractionId: currentAbstractionId };
  });
}

/**
 * Legacy method for backward compatibility
 */
export async function processFiles(
  files: File[],
  userId: string,
  addLog: LoggerFunction,
  processLogger: ProcessLogger
): Promise<ProcessFilesResult> {
  // Convert Files to FileWithType using default classification
  const filesWithTypes: FileWithType[] = files.map(file => ({
    file,
    type: 'original_lease' // Default to original lease for backward compatibility
  }));

  return processConfirmedFiles(filesWithTypes, userId, addLog, processLogger);
}
