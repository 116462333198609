import { useState, useCallback } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { processConfirmedFiles } from '../services/file-processing-service';
import { ProcessingError } from '../../../lib/llm/processors/errors';
import { ProcessLogger } from '../../../lib/process-logger';
import type { LoggerFunction } from '../types';
import type { FileWithType } from '../../../types/document';
import { supabase } from '../../../lib/supabase';
import { useNavigate } from 'react-router-dom';

interface ProcessingState {
  isProcessing: boolean;
  progress: number;
  logs: Array<{
    message: string;
    type?: 'info' | 'error' | 'success';
    timestamp?: number;
  }>;
  error?: string;
}

interface ProcessFilesResult {
  abstractionId?: string;
  error?: string;
}

const PROCESSING_STEPS = {
  START: 0,
  CLASSIFICATION: 10,
  VALIDATION: 20,
  TEXT_EXTRACTION: 30,
  RELATIONSHIP_ANALYSIS: 40,
  LLM_PROCESSING: 50,
  DATABASE: 60,
  COMPLETE: 100,
  ERROR: -1
} as const;

export function useFileProcessing() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [state, setState] = useState<ProcessingState>({
    isProcessing: false,
    progress: 0,
    logs: []
  });

  const updateProgress = useCallback((step: keyof typeof PROCESSING_STEPS) => {
    setState(prev => ({
      ...prev,
      progress: PROCESSING_STEPS[step]
    }));
  }, []);

  const addLog: LoggerFunction = useCallback((message: string, type?: 'info' | 'error' | 'success') => {
    setState(prev => ({
      ...prev,
      logs: [...prev.logs, { 
        message, 
        type,
        timestamp: Date.now()
      }]
    }));

    // Update progress based on log messages
    if (message.includes('Analyzing document')) {
      updateProgress('CLASSIFICATION');
    } else if (message.includes('validation')) {
      updateProgress('VALIDATION');
    } else if (message.includes('extraction')) {
      updateProgress('TEXT_EXTRACTION');
    } else if (message.includes('relationship') || message.includes('references')) {
      updateProgress('RELATIONSHIP_ANALYSIS');
    } else if (message.includes('LLM processing')) {
      updateProgress('LLM_PROCESSING');
    } else if (message.includes('database')) {
      updateProgress('DATABASE');
    } else if (message.includes('completed successfully')) {
      updateProgress('COMPLETE');
    }

    // Log confidence scores
    if (message.includes('confidence')) {
      const confidenceMatch = message.match(/(\d+)% confidence/);
      if (confidenceMatch) {
        const confidence = parseInt(confidenceMatch[1]);
        setState(prev => ({
          ...prev,
          logs: [...prev.logs, {
            message: `Classification confidence: ${confidence}%`,
            type: confidence >= 80 ? 'success' : confidence >= 60 ? 'info' : 'error',
            timestamp: Date.now()
          }]
        }));
      }
    }
  }, [updateProgress]);

  const processFiles = useCallback(async (
    filesWithTypes: FileWithType[], 
    tenant?: string, 
    premises?: string
  ): Promise<ProcessFilesResult> => {
    let processLogger: ProcessLogger | undefined;
    
    try {
      // Validate user authentication
      if (!user?.id) {
        throw ProcessingError.validation([{
          field: 'auth',
          message: 'Please log in to upload documents'
        }]);
      }

      // Verify session is active
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError || !session) {
        throw ProcessingError.validation([{
          field: 'auth',
          message: 'Your session has expired. Please log in again.'
        }]);
      }

      // Reset state and start logging immediately
      setState({
        isProcessing: true,
        progress: PROCESSING_STEPS.START,
        logs: [{
          message: 'Initializing file processing...',
          type: 'info',
          timestamp: Date.now()
        }]
      });

      // Create process logger instance with verified session user ID
      processLogger = new ProcessLogger(session.user.id);
      
      // Log initial processing details
      addLog('Starting file processing pipeline...', 'info');
      addLog(`Processing ${filesWithTypes.length} document(s)`, 'info');
      
      // Log file details
      filesWithTypes.forEach(fileWithType => {
        addLog(`File: ${fileWithType.file.name} (${(fileWithType.file.size / 1024 / 1024).toFixed(2)} MB)`, 'info');
      });

      // Log tenant and premises if available
      if (tenant) {
        addLog(`Tenant: ${tenant}`, 'info');
      }
      if (premises) {
        addLog(`Premises: ${premises}`, 'info');
      }

      // Process files with progress updates using verified session user ID
      const result = await processConfirmedFiles(
        filesWithTypes,
        session.user.id,
        addLog,
        processLogger,
        tenant,
        premises
      );

      if (result.error) {
        throw ProcessingError.document(result.error);
      }

      if (result.abstractionId) {
        // Log success message
        const successMsg = filesWithTypes.length > 1 
          ? 'All documents processed successfully'
          : 'Document processed successfully';
        addLog(successMsg, 'success');
        await processLogger.log(successMsg, 'success');

        // Update state with success
        setState(prev => ({
          ...prev,
          isProcessing: false,
          progress: PROCESSING_STEPS.COMPLETE,
          logs: [...prev.logs, {
            message: 'Redirecting to abstraction page...',
            type: 'info',
            timestamp: Date.now()
          }]
        }));

        // Return the result
        return result;
      } else {
        throw new Error('No abstraction ID returned from processing');
      }
    } catch (error) {
      // Handle errors
      const errorMessage = error instanceof ProcessingError 
        ? error.message 
        : error instanceof Error 
          ? error.message 
          : 'Unknown error occurred';

      // Update state with error
      setState(prev => ({
        ...prev,
        isProcessing: false,
        progress: PROCESSING_STEPS.ERROR,
        logs: [...prev.logs, {
          message: errorMessage,
          type: 'error',
          timestamp: Date.now()
        }]
      }));

      // Return error result
      return { error: errorMessage };
    }
  }, [user, supabase, addLog, setState]);

  const clearLogs = useCallback(() => {
    setState(prev => ({
      ...prev,
      logs: [],
      error: undefined
    }));
  }, []);

  return {
    isProcessing: state.isProcessing,
    progress: state.progress,
    logs: state.logs,
    error: state.error,
    processFiles,
    clearLogs
  };
}
